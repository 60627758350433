import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form-tabs';
import ListEmpty from '../../components/List/empty';
import InputClient from '../../components/Form/client';
import InputText from '../../components/Form/input-text';
import InputDatetime from '../../components/Form/datetime';
import InputToggle from '../../components/Form/toggle';
import Select from '../../components/Form/select';
import InputDuration from '../../components/Form/duration';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import ModalClients from '../../components/Modals/clients';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewSessionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      client: null,
      preselected: false,
      dur: 30,
      preview: true,
      notes: '',
      name: '',
      desc: '',
      back: ROUTES.CALENDAR,
      header: 'tabs',
      date: Moment(global.currentDate, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      hour: global.currentTime,
      mins: '00',
      cdur: 75,
      error: [false, false, false],
      trainerId: global.uidUser,
      trainerName: global.userName,
      location: global.loc,
      link: '',
      showMore: false,
      staff: global.userStaff,
      trainers: [],
      locations: [],
      showModalClients: false,
      editing: false,
      timeEdit: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('1:1 Session - PT Mate')
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('publicRequestsLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      var id = ''
      var preselected = false
      var selected = null
      var header = 'tabs'
      var back = ROUTES.CALENDAR

      if(arr[arr.length-2] !== 'calendar') {
        id = arr[arr.length-2]
      }

      back = global.currentPage
      if(global.linkCli !== '') {
        back = global.linkCli
      }

      if(id !== '') {
        for(var client of global.clients) {
          if(client.id === id) {
            global.currentClient = client.id
            selected = client
            preselected = true
            header = 'basic'
          }
        }
      }

      var tmp3 = []
      var tmp22 = []
      var tmp23 = []
      for(var item3 of global.userStaff) {
        if((item3.data.role === 'assistant' || item3.data.role === 'assistant2' || item3.data.role === 'trainer') && item3.data.status === 'active') {
          tmp3.push(item3)
        }
      }
      if(tmp3.length > 0) {
        tmp22 = [{value: '', name: '- '+lang.t('calendar:form.notassigned')+' -'}, {value: global.uidUser, name: lang.t('common:label.yourself')}]
        if(global.userRole === 'manager') {
          tmp22.push({value: global.uid, name: global.spaceOwner})
        }
        for(var t of tmp3) {
          tmp22.push({value: t.id, name: t.data.name})
        }
      }
      if(global.locations.length > 0) {
        tmp23 = [{value: '', name: '- '+lang.t('calendar:form.notassigned')+' -'}]
        for(var l of global.locations) {
          tmp23.push({value: l.id, name: l.data.name})
        }
      }
      
      this.setState({
        client: selected,
        preselected: preselected,
        header: header,
        back: back,
        staff: tmp3,
        trainers: tmp22,
        locations: tmp23,
      })
      if(this.props.location.pathname.indexOf('/edit-session/') !== -1) {
        this.configureEditing()
      }
    }
  }


  configureEditing() {
    var arr = this.props.location.pathname.split('/')
    var back = ROUTES.CALENDAR

    var id = arr[arr.length-1]
    var item = null
    var cl = null
    var dur = 30
    var notes = ''
    var name = ''
    var desc = ''
    var day = ''
    var hrs = ''
    var min = ''
    var preview = true
    var tid = global.uidUser
    var tname = global.userName
    var link = ''
    var location = ''

    back = '/calendar/session/'+id

    if(id !== '') {
      // Sessions
      for(var session of global.sessions) {
        if(session.id === id) {
          item = session
          dur = session.data.duration
          notes = session.data.notes
          day = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
          hrs = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('HH')
          min = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('mm')
          if(!session.data.preview && session.data.preview !== undefined) {
            preview = false
          }
          for(var client1 of global.clients) {
            if(client1.id === session.data.client) {
              cl = client1
            }
          }
          for(var client2 of global.clientsInactive) {
            if(client2.id === session.data.client) {
              cl = client2
            }
          }
          if(session.data.trainerId !== undefined) {
            tid = session.data.trainerId
            tname = session.data.trainerName
          }
          if(session.data.location !== undefined) {
            location = session.data.location
          }
          if(session.data.link !== undefined) {
            link = session.data.link
          }
          if(session.data.desc !== undefined) {
            desc = session.data.desc
          }
          if(session.data.name !== undefined) {
            name = session.data.name
          }
        }
      }
    }

    var cdur = 75
    if(dur !== 30 && dur !== 40 && dur !== 45 && dur !== 60 && dur !== 90) {
      cdur = dur
      dur = 99
    }
    if(cl !== null) {
      if(cl.data.uid !== '') {
        Connector.getClientToken(cl.data.uid)
      }
    }
   
    if(cl === null) {
      cl = global.clients[0]
    }

    this.setState({
      clients: global.clients,
      client: cl,
      item: item,
      id: id,
      back: back,
      dur: dur,
      cdur: cdur,
      notes: notes,
      name: name,
      desc: desc,
      date: Moment(day, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      hour: hrs,
      mins: min,
      preview: preview,
      trainerId: tid,
      trainerName: tname,
      location: location,
      link: link,
      preselected: true,
      editing: true,
      header: '',
    })
  }



  // Interactive stuff ------------------------------------------------------------



  setTrainer(item) {
    var name = 'Trainer'
    for(var trainer of global.userStaff) {
      if(trainer.id === item) {
        name = trainer.data.name
      }
    }
    if(item === global.uidUser) {
      name = global.userName
    }
    this.setState({
      trainerId: item,
      trainerName: name
    })
  }


  checkCreateSession() {
    var date = this.state.date+' '+this.state.hour+':'+this.state.mins
    var later = true
    /*if(parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')) < parseInt(Moment().format('X'))) {
      later = false
    }*/
    var passed = true
    var tmp = [false, false, false]
    this.setState({error: tmp})
    if(this.state.client === null) {
      tmp[0] = true
      passed = false
    }
    this.setState({error: tmp})
    if(passed && later) {
      this.createSession()
    }
  }


  createSession() {
    global.badgeLocked = true
    var date = this.state.date+' '+this.state.hour+':'+this.state.mins
    var plan = false
    for(var sub of this.state.client.subscriptions) {
      if(!sub.data.group) {
        plan = true
      }
    }
    if(global.userStripeBank == null) {
      plan = true
    }
    var duration = this.state.dur
    if(this.state.dur === 99) {
      duration = parseInt(this.state.cdur)
    }
    var location = null
    var locationName = null
    if(global.locations.length > 0) {
      location = this.state.location
      locationName = ""
      for(var loc of global.locations) {
        if(loc.id === this.state.location) {
          locationName = loc.data.name
        }
      }
    }
    var token = this.state.client.data.pushToken
    var client = this.state.client
    var obj = Firebase.database().ref('/sessions/'+global.uid).push()
    obj.update({
      attendance: 2,
      client: this.state.client.id,
      plan: plan,
      group: false,
      notes: this.state.notes,
      name: this.state.name,
      desc: this.state.desc,
      duration: duration,
      preview: this.state.preview,
      date: date,
      timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')),
      uid: global.uid,
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
    }).then(() => {
      global.message = lang.t('messaging:message.sessioncreated')
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'session');
      }, 1000);
      let text = lang.t('messaging:push.bookedin.text5')+(this.state.name === '' ? lang.t('messaging:push.ptsession') : this.state.name)+' '+Cal.getPushDate(date)
      var scheduled = Moment(date, 'DD/MM/YYYY HH:mm').add(-global.userSessionReminder, 'hours').format('X')
      if(token !== undefined && token !== '') {
        Connector.sendPushNotification(token, global.userName, text, 'session', obj.key)
        Firebase.database().ref('/schedule/'+obj.key+'-push').update({
          title: lang.t('messaging:push.sessionreminder.title'),
          desc: lang.t('messaging:push.bookedin.text4')+(this.state.name === '' ? lang.t('messaging:push.ptsession') : this.state.name)+' '+this.getSpecialDate(date, scheduled)+".",
          type: "",
          timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').add(-global.userSessionReminder, 'hours').format('X')),
          tokens: [token],
          message: "push",
          uid: global.uid,
          iid: obj.key
        })
      }
      if(client.data.email !== '' && global.spaceEmailReminder && (client.data.emailReminder || client.data.emailReminder === undefined)) {
        // Email confirmation
        var callFunction = Firebase.functions().httpsCallable('sendReminderV2');
        callFunction({type: 'booked', email: client.data.email, name: global.userBusiness, id: global.uid, session: (this.state.name === '' ? lang.t('messaging:push.ptsession') : this.state.name), time: Moment(date, 'DD/MM/YYYY HH:mm').format('llll')}).then(function(result) {});
        // Email reminder
        Firebase.database().ref('/schedule/'+obj.key+'-email').update({
          title: global.userBusiness,
          desc: (this.state.name === '' ? lang.t('messaging:push.ptsession') : this.state.name),
          type: this.getSpecialDate(date, scheduled),
          timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').add(-global.userSessionReminder, 'hours').format('X')),
          tokens: [client.data.email],
          message: "sessionemail",
          uid: global.uid,
          iid: global.uid
        })
      }
      this.props.history.push(this.state.back)
      
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  getSpecialDate(date1, date2) {
    var label = Moment(date1, 'DD/MM/YYYY HH:mm').locale(lang.language).format('llll')
    if(Moment(date1, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY') === Moment(date2, 'X').format('DD/MM/YYYY')) {
      label = 'today '+Moment(date1, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    if(Moment(date1, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY') === Moment(date2, 'X').add(1, 'days').format('DD/MM/YYYY')) {
      label = 'tomorrow '+Moment(date1, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    return label
  }


  checkUpdateSession() {
    var passed = true
    var tmp = [false, false]
    var date = this.state.date+' '+this.state.hour+':'+this.state.mins
    var later = true
    if(parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')) < parseInt(Moment().format('X'))) {
      later = false
    }
    this.setState({error: tmp})
    if(this.state.client === null) {
      tmp[0] = true
      passed = false
    }
    this.setState({error: tmp})
    if(passed) {
      this.updateSession()
    }
  }


  updateSession() {
    var date = this.state.date+' '+this.state.hour+':'+this.state.mins
    var duration = this.state.dur
    var token = this.state.client.data.pushToken
    if(this.state.dur === 99) {
      duration = parseInt(this.state.cdur)
    }
    var location = null
    var locationName = null
    if(global.locations.length > 0) {
      location = this.state.location
      locationName = ""
      for(var loc of global.locations) {
        if(loc.id === this.state.location) {
          locationName = loc.data.name
        }
      }
    }
    this.setState({
      editing: false,
    })
    var scheduled = Moment(date, 'DD/MM/YYYY HH:mm').add(-global.userSessionReminder, 'hours').format('X')
    for(var sitem of global.schedule) {
      if(sitem.id === this.state.item.id+'-push') {
        Firebase.database().ref('/schedule/'+sitem.id).update({
          timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').add(-global.userSessionReminder, 'hours').format('X')),
          desc: lang.t('messaging:push.bookedin.text4')+(this.state.name === '' ? lang.t('messaging:push.ptsession') : this.state.name)+' '+this.getSpecialDate(date, scheduled)+".",
        })
      }
      if(sitem.id === this.state.item.id+'-email') {
        Firebase.database().ref('/schedule/'+sitem.id).update({
          timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').add(-global.userSessionReminder, 'hours').format('X')),
          type: this.getSpecialDate(date, scheduled),
        })
      }
    }
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).update({
      notes: this.state.notes,
      name: this.state.name,
      desc: this.state.desc,
      duration: duration,
      preview: this.state.preview,
      date: date,
      timestamp: parseInt(Moment(date, 'DD/MM/YYYY HH:mm').format('X')),
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
      template: '',
    }).then(() => {
      global.message = lang.t('messaging:message.sessionupdated')
      if(token !== '' && token!== undefined && this.state.timeEdit) {
        let text = (this.state.name === '' ? lang.t('messaging:push.ptsession') : this.state.name)+lang.t('messaging:push.sessionupdated.text4')+Cal.getPushDate(this.state.item.data.date)
        Connector.sendPushNotification(token, global.userName, text, 'session', this.state.item.id)
      }
      this.props.history.push(this.state.back)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalClients: false,
    })
  }


  modalClick(item) {
    this.hideModals()
    this.setState({
      client: item,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderLocation() {
    if(global.locations.length > 0 && HelperBusiness.getRoleIncl('trainer,admin')) {
      return <Select label={lang.t('calendar:form.location')} value={this.state.location} values={this.state.locations} onChange={(event) => this.setState({location: event})}/>
    }
  }


  renderTrainer() {
    if(this.state.staff.length > 0 && HelperBusiness.getRoleIncl('')) {
      return <Select label={lang.t('calendar:form.trainer')} value={this.state.trainerId} values={this.state.trainers} onChange={(event) => this.setTrainer(event)}/>
    }
  }


  renderMoreOptions() {
    if(this.state.showMore) {
      return (
        <div>
          <button className="btn tertiary small width-12 sub" onClick={() => this.setState({showMore: false})}>{lang.t('calendar:button.hidemoreoptions')}</button>
          <div className="sv-20"></div>
          <InputText label={lang.t('calendar:form.sessionname2')} value={this.state.name} onChange={(event) => this.setState({name: event})}/>
          <InputText label={lang.t('calendar:form.sessiondescription')} value={this.state.desc} onChange={(event) => this.setState({desc: event})}/>
          <InputText label={lang.t('calendar:form.notes')} value={this.state.notes} onChange={(event) => this.setState({notes: event})}/>
          <InputToggle label={lang.t('calendar:form.preview.label')} value={this.state.preview} onChange={() => this.setState({preview: !this.state.preview})} text={lang.t('calendar:form.preview.text')}/>
        </div>
      )
    } else {
      return (
        <button className="btn tertiary small width-12 add" onClick={() => this.setState({showMore: true})}>{lang.t('calendar:button.showmoreoptions')}</button>
      )
    }
  }


  renderButton() {
    if(this.state.editing) {
      return (
        <div>
          <button className="btn primary mb-20" onClick={() => this.checkUpdateSession()}>{lang.t('common:button.savechanges')}</button>
          <Link to={this.state.back} className="btn tertiary close width-12">{lang.t('common:button.cancel')}</Link>
        </div>
      )
    } else {
      return <button className="btn primary" onClick={() => this.checkCreateSession()}>{lang.t('calendar:button.createsession')}</button>
    }
  }


  renderTabs() {
    if(!this.state.editing && !this.state.preselected) {
      return (
        <div className="radios tabs">
          <button className="radio col-6 active">{lang.t('calendar:form.11session')}</button>
          <button className="radio col-6" onClick={() => this.props.history.push(ROUTES.NEWAVAILABILITY)}>{lang.t('calendar:form.11availability')}</button>
        </div>
      )
    }
  }


  renderHeader() {
    if(this.state.editing) {
      return <Header title={lang.t('calendar:title.edit11session')} link={this.state.back} type='simple'/>
    } else {
      if(this.state.header === 'tabs') {
        return (
          <Header title={lang.t('calendar:title.newsession')} link={this.state.back} type='sessions' active='11'/>
        )
      } else {
        return (
          <Header title={lang.t('calendar:title.newsession')} link={this.state.back} type='simple'/>
        )
      }
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className={this.state.header === 'tabs' ? 'content form-sidebar withtabs' : 'content form-sidebar'}>
            <div className="col-6 mt-20">
              <div className="box withtabs">
                {this.renderTabs()}
                <div className="inner">
                  <div className="col-12">
                    <InputClient client={this.state.client} error={this.state.error[1]} errormsg={lang.t('calendar:error.client')} id='session' presel={this.state.preselected} clickElement={() => this.setState({showModalClients: true})}/>
                  </div>
                  <div className="clear sv-20"></div>
                  <InputDatetime hour={this.state.hour} min={this.state.mins} date={this.state.date} setDay={(event) => this.setState({date: event, timeEdit: true})} setHour={(event) => this.setState({hour: event, timeEdit: true})} setMin={(event) => this.setState({mins: event, timeEdit: true})}/>
                  <div className="col-12">
                    <InputDuration duration={this.state.dur} custom={this.state.cdur} setDuration={(event) => this.setState({dur: event})} setCustom={(event) => this.setState({cdur: event})}/>
                    <InputText label={lang.t('calendar:form.virtual')} placeholder='Zoom, Hangouts, Skype...' value={this.state.link} onChange={(event) => this.setState({link: event})}/>
                    {this.renderTrainer()}
                    {this.renderLocation()}
                    <div className="sv-10"></div>
                    {this.renderMoreOptions()}
                  </div>
                  <div className="clear sv-30"></div>
                  {this.renderButton()}
                </div>
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id={this.state.editing ? 'cal11edit' : 'cal11'}/>
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          {this.renderHeader()}
          <div className="btn-news" onClick={() => window.Intercom('startTour', '317959')}>{lang.t('common:label.showmearound')}</div>
          <Navigation active='calendar' />
          <ModalClients show={this.state.showModalClients} onHide={() => this.hideModals()} clickElement={(event) => this.modalClick(event)}/>
        </div>
        
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='calendar-locked' type='simple'/>
          </div>
          <Header title={lang.t('calendar:title.newsession')} link={ROUTES.CALENDAR} type='simple'/>
          <Navigation active='calendar' />
        </div>
      )
    }
  }


  render() {  
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common','messaging'])(withRouter(withAuthorization(condition)(NewSessionPage)));