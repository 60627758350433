import Moment from 'moment';
import Firebase from 'firebase';
import Cal from '../helper/Calendar';
import lang from 'i18next';



class HelperBilling {


  // Avatar image

  getIncome(payments, client, range) {
    var count = 0
    var minDate = parseInt(Cal.getDateRange())

    var list = []
    if(global.loc === '') {
      for(var cl of global.clients) {
        list.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        list.push(cl2.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
      }
    }

    for(var item of payments) {
      var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X'))
      if(item.data.timestamp !== undefined) {
        date = item.data.timestamp
      }
      if((item.data.client === client || client === '') && date > minDate && list.indexOf(item.data.client) !== -1 && item.data.refund === undefined) {
        if(item.data.fee === undefined || item.data.fee !== 0) {
          count += (item.data.amount/100)*0.9775-0.30
        } else {
          count += item.data.amount/100
        }
      }
    }
    return count.toFixed(2)
  }


  // Income difference

  getIncomeDifference(client, range) {
    if(global.userStripeConnect === '') {
      var label = "Billing not enabled"
      if(lang.language === 'es') {
        label = "Facturación no habilitada"
      }
      return label
    } else {
      var minDate1 = parseInt(Cal.getDateRangeDiff()[0])
      var minDate2 = parseInt(Cal.getDateRangeDiff()[1])
      var prevDate = parseInt(Cal.getDateRangeDiff()[2])

      var count2 = 0

      var list = []
      if(global.loc === '') {
        for(var cl of global.clients) {
          list.push(cl.id)
        }
        for(var cl2 of global.clientsInactive) {
          list.push(cl2.id)
        }
      } else {
        for(var loc of global.locations) {
          if(loc.id === global.loc && loc.data.clients !== undefined) {
            list = loc.data.clients
          }
        }
      }

      // Payments
      for(var item of global.payments) {
        if(list.indexOf(item.data.client) !== -1 && item.data.refund === undefined) {
          var num = (item.data.amount/100)*0.9775-0.30
          if(item.data.fee === undefined || item.data.fee !== 0) {
            num = (item.data.amount/100)*0.9775-0.30
          } else {
            num = item.data.amount/100
          }
          var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X'))
          if(item.data.timestamp !== undefined) {
            date = parseInt(Moment(item.data.timestamp, 'X').format('X'))
          }
          if(client === '' || client === null) {
            if(date > minDate1) {
              //count1 += num
            } else if(date > prevDate && date < minDate2) {
              count2 += num
            }
          } else {
            if(date > minDate1 && item.data.client === client) {
              //count1 += num
            } else if(date > prevDate && date < minDate2 && item.data.client === client) {
              count2 += num
            }
          }
        }
      }
      
      return count2.toFixed(2)
    }
  }


  getIncomeDebitDifference(range) {
    if(global.userStripeConnect === '') {
      var label = "Billing not enabled"
      if(lang.language === 'es') {
        label = "Facturación no habilitada"
      }
      return label
    } else {
      var minDate1 = parseInt(Cal.getDateRangeDiff()[0])
      var minDate2 = parseInt(Cal.getDateRangeDiff()[1])
      var prevDate = parseInt(Cal.getDateRangeDiff()[2])

      var count2 = 0
      var list = []
      if(global.loc === '') {
        for(var cl of global.clients) {
          list.push(cl.id)
        }
        for(var cl2 of global.clientsInactive) {
          list.push(cl2.id)
        }
      } else {
        for(var loc of global.locations) {
          if(loc.id === global.loc && loc.data.clients !== undefined) {
            list = loc.data.clients
          }
        }
      }

      // Payments
      for(var item of global.payments) {
        if(list.indexOf(item.data.client) !== -1 && item.data.refund === undefined) {
          var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X'))
          if(item.data.timestamp !== undefined) {
            date = parseInt(Moment(item.data.timestamp, 'X').format('X'))
          }
          if(date > minDate1 && (item.data.name.indexOf('Invoice ') !== -1 || item.data.name.indexOf('Subscription ') !== -1)) {
            //count1 += (item.data.amount/100)*0.9775-0.30
          } else if(date > prevDate && date < minDate2 && (item.data.name.indexOf('Invoice ') !== -1 || item.data.name.indexOf('Subscription ') !== -1)) {
            count2 += (item.data.amount/100)*0.9775-0.30
          }
        }
      }

      return count2.toFixed(2)
    }
  }


  getPaymentsDifference(range) {
    var minDate1 = Cal.getDateRangeDiff()[0]
    var minDate2 = Cal.getDateRangeDiff()[1]
    var prevDate = Cal.getDateRangeDiff()[2]

    var count2 = 0
    var list = []
    if(global.loc === '') {
      for(var cl of global.clients) {
        list.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        list.push(cl2.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
      }
    }

    for(var item of global.payments) {
      if(list.indexOf(item.data.client) !== -1 && item.data.refund === undefined) {
        var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
        if(item.data.timestamp !== undefined) {
          date = Moment(item.data.timestamp, 'X').format('X')
        }
        if(date > minDate1) {
          //count1++
        } else if(date > prevDate && date < minDate2) {
          count2++
        }
      }
    }

    return count2
  }


  getPaymentsDebitDifference(range) {
    var minDate1 = Cal.getDateRangeDiff()[0]
    var minDate2 = Cal.getDateRangeDiff()[1]
    var prevDate = Cal.getDateRangeDiff()[2]

    var count2 = 0
    var list = []
    if(global.loc === '') {
      for(var cl of global.clients) {
        list.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        list.push(cl2.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
      }
    }

    for(var item of global.payments) {
      if(list.indexOf(item.data.client) !== -1 && item.data.refund === undefined) {
        var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
        if(item.data.timestamp !== undefined) {
          date = Moment(item.data.timestamp, 'X').format('X')
        }
        if(date > minDate1 && item.data.subscription !== '' && item.data.subscription !== undefined) {
          //count1++
        } else if(date > prevDate && date < minDate2 && item.data.subscription !== '' && item.data.subscription !== undefined) {
          count2++
        }
      }
    }

    return count2
  }


  getClientSessionDebit(item, group, id, strict, prods) {
    var add = 0
    var family = false
    var products = []
    if(prods !== undefined && prods !== null) {
      products = prods
    }

    var client = item
    if(item.data.parent !== undefined && item.data.parent !== '') {
      family = true
      for(var cl of global.clients) {
        if(cl.id === item.data.parent) {
          client = cl
        }
      }
    }
    if(item.data.linked !== undefined) {
      if(item.data.linked.length > 0) {
        family = true
      }
    }

    var valid = ["", id, undefined]
    if(family && strict) {
      if(id === "") {
        valid = ["", undefined]
      } else {
        valid = [id]
      }
    }

    for(var sub of client.subscriptions) {
      if(products.length === 0 || products.indexOf(sub.id) !== -1) {
        var current = Moment(sub.data.next, 'X').add(-7, 'days')
        if(sub.data.billing === 'fortnight') {
          current = Moment(sub.data.next, 'X').add(-14, 'days')
        }
        if(sub.data.interval !== undefined && sub.data.interval !== 1) {
          current = Moment(sub.data.next, 'X').add(-(7*sub.data.interval), 'days')
        }
        if(sub.data.billing === 'month') {
          current = Moment(sub.data.next, 'X').add(-1, 'months')
          if(sub.data.interval !== undefined && sub.data.interval !== 1) {
            current = Moment(sub.data.next, 'X').add(-sub.data.interval, 'months')
          }
        }
        if(sub.data.group === group && current < Moment() && Moment(sub.data.next, 'X') > Moment() && sub.data.status !== 'trialing' && (sub.data.pause === undefined || sub.data.pause === '') && valid.indexOf(sub.data.account) !== -1) {
          var d1 = 0
          if(sub.data.done !== undefined) {
            d1 = sub.data.done
          }
          add += (sub.data.sessions-d1)
        }
        if(client.data.name === 'Toyah rogers ') {
          console.log("add "+add+" "+group)
          }
        if(sub.data.is11 && !group && current < Moment() && Moment(sub.data.next, 'X') > Moment() && sub.data.status !== 'trialing' && (sub.data.pause === undefined || sub.data.pause === '') && valid.indexOf(sub.data.account) !== -1) {
          var d2 = 0
          if(sub.data.done11 !== undefined) {
            d2 = sub.data.done11
          }
          add += (sub.data.sessions11-d2)
        }
      }
    }

    if(add < 0) {
      add = 0
    }
    return add
  }


  chargeClientSessionDebit(client, group, id, prods) {
    var subid = ''
    var sub11 = ''
    var amount = 0
    var products = []
    if(prods !== undefined && prods !== null) {
      products = prods
    }

    for(var sub of client.subscriptions) {
      if(products.length === 0 || products.indexOf(sub.id) !== -1) {
        var current = Moment(sub.data.next, 'X').add(-7, 'days')
        if(sub.data.interval !== undefined && sub.data.interval !== 1) {
          current = Moment(sub.data.next, 'X').add(-(7*sub.data.interval), 'days')
        }
        if(sub.data.billing === 'fortnight') {
          current = Moment(sub.data.next, 'X').add(-14, 'days')
        }
        if(sub.data.billing === 'month') {
          current = Moment(sub.data.next, 'X').add(-1, 'months')
          if(sub.data.interval !== undefined && sub.data.interval !== 1) {
            current = Moment(sub.data.next, 'X').add(-sub.data.interval, 'months')
          }
        }
        if(sub.data.group === group && current < Moment() && Moment(sub.data.next, 'X') > Moment() && sub.data.status !== 'trialing' && (sub.data.pause === undefined || sub.data.pause === '') && sub.data.account === id) {
          var d1 = 0
          if(sub.data.done !== undefined) {
            d1 = sub.data.done
          }
          if(d1 < sub.data.sessions) {
            subid = sub.id
            amount = d1
          }
        }
        if(sub.data.is11 && !group && subid === '' && current < Moment() && Moment(sub.data.next, 'X') > Moment() && sub.data.status !== 'trialing' && (sub.data.pause === undefined || sub.data.pause === '') && sub.data.account === id) {
          var d2 = 0
          if(sub.data.done11 !== undefined) {
            d2 = sub.data.done11
          }
          if(d2 < sub.data.sessions11) {
            sub11 = sub.id
            amount = d2
          }
        }
      }
    }

    if(subid === '' && sub11 === '') {
      for(var sub2 of client.subscriptions) {
        if(products.length === 0 || products.indexOf(sub2.id) !== -1) {
          var current2 = Moment(sub2.data.next, 'X').add(-7, 'days')
          if(sub2.data.interval !== undefined && sub2.data.interval !== 1) {
            current2 = Moment(sub2.data.next, 'X').add(-(7*sub2.data.interval), 'days')
          }
          if(sub2.data.billing === 'fortnight') {
            current2 = Moment(sub2.data.next, 'X').add(-14, 'days')
          }
          if(sub2.data.billing === 'month') {
            current2 = Moment(sub2.data.next, 'X').add(-1, 'months')
            if(sub2.data.interval !== undefined && sub2.data.interval !== 1) {
              current2 = Moment(sub2.data.next, 'X').add(-sub2.data.interval, 'months')
            }
          }
          if(sub2.data.group === group && current2 < Moment() && Moment(sub2.data.next, 'X') > Moment() && sub2.data.status !== 'trialing' && (sub2.data.pause === undefined || sub2.data.pause === '') && (sub2.data.account === undefined || sub2.data.account === '')) {
            var d11 = 0
            if(sub2.data.done !== undefined) {
              d11 = sub2.data.done
            }
            if(d11 < sub2.data.sessions) {
              subid = sub2.id
              amount = d11
            }
          }
          if(sub2.data.is11 && !group && subid === '' && current2 < Moment() && Moment(sub2.data.next, 'X') > Moment() && sub2.data.status !== 'trialing' && (sub2.data.pause === undefined || sub2.data.pause === '') && (sub2.data.account === undefined || sub2.data.account === '')) {
            var d12 = 0
            if(sub2.data.done11 !== undefined) {
              d12 = sub2.data.done11
            }
            if(d12 < sub2.data.sessions11) {
              sub11 = sub.id
              amount = d12
            }
          }
        }
      }
    }

    if(subid !== '') {
      Firebase.database().ref('/clients/'+global.uid+'/'+client.id+'/subscriptions/'+subid).update({
        done: amount+1
      })
    } else if(sub11 !== '') {
      Firebase.database().ref('/clients/'+global.uid+'/'+client.id+'/subscriptions/'+sub11).update({
        done11: amount+1
      })
    }
  }


  // backup
  getClientSessionDebit2(item, group) {
    var add = 0
    var addp = 0
    var current = Moment()
    var next = Moment()
    var billing = ''
    var family = false
    var linked = []

    var client = item
    if(item.data.parent !== undefined && item.data.parent !== '') {
      family = true
      for(var cl of global.clients) {
        if(cl.id === item.data.parent) {
          client = cl
          if(client.data.linked !== undefined) {
            for(var lk of client.data.linked) {
              linked.push(lk)
            }
          }
        }
      }
    }
    if(item.data.linked !== undefined) {
      if(item.data.linked.length > 0) {
        family = true
        linked = []
        for(var lka of client.data.linked) {
          linked.push(lka)
        }
      }
    }

    for(var sub of client.subscriptions) {
      if(sub.data.group === group && Moment(sub.data.next, 'X') > Moment() && sub.data.status !== 'trialing' && (sub.data.account === undefined || sub.data.account === '' || sub.data.account === item.id)) {
        if(sub.data.account === item.id && family) {
          addp += sub.data.sessions
        } else {
          add += sub.data.sessions
        }
        billing = sub.data.billing
        next = Moment(sub.data.next, 'X').startOf('day')
        if(billing === 'month') {
          current = Moment(sub.data.next, 'X').add(-1, 'month').startOf('day')
          if(sub.data.interval !== undefined && sub.data.interval !== 1) {
            current = Moment(sub.data.next, 'X').add(-sub.data.interval, 'months').startOf('day')
          }
        } else if(sub.data.billing === 'week') {
          current = Moment(sub.data.next, 'X').add(-7, 'days').startOf('day')
          if(sub.data.interval !== undefined && sub.data.interval !== 1) {
            current = Moment(sub.data.next, 'X').add(-(7*sub.data.interval), 'days').startOf('day')
          }
        } else if(sub.data.billing === 'fortnight') {
          current = Moment(sub.data.next, 'X').add(-14, 'days').startOf('day')
        }
      }
      if(sub.data.is11 && !group && Moment(sub.data.next, 'X') > Moment() && sub.data.status !== 'trialing' && (sub.data.account === undefined || sub.data.account === '' || sub.data.account === item.id)) {
        if(sub.data.account === item.id && family) {
          addp += sub.data.sessions11
        } else {
          add += sub.data.sessions11
        }
        billing = sub.data.billing
        next = Moment(sub.data.next, 'X').startOf('day')
        if(billing === 'month') {
          current = Moment(sub.data.next, 'X').add(-1, 'month').startOf('day')
          if(sub.data.interval !== undefined && sub.data.interval !== 1) {
            current = Moment(sub.data.next, 'X').add(-sub.data.interval, 'months').startOf('day')
          }
        } else if(sub.data.billing === 'week') {
          current = Moment(sub.data.next, 'X').add(-7, 'days').startOf('day')
          if(sub.data.interval !== undefined && sub.data.interval !== 1) {
            current = Moment(sub.data.next, 'X').add(-sub.data.interval, 'days').startOf('day')
          }
        } else if(sub.data.billing === 'fortnight') {
          current = Moment(sub.data.next, 'X').add(-14, 'days').startOf('day')
        }
      }
    }

    if(group && (add > 0 || addp > 0)) {
      for(var session of global.sessions) {
        var sd1 = Moment(session.data.date, 'DD/MM/YYYY HH:mm')
        if(sd1 > current && sd1 < next) {
          if((session.data.attendance === 3 || session.data.attendance === 5) && session.data.confirmed !== undefined) {
            if(session.data.confirmed.indexOf(item.id) !== -1) {
              if(addp > 0) {
                addp--
              } else {
                add--
              }
            }
            if(family) {
              if(session.data.confirmed.indexOf(client.id) !== -1 && client.id !== item.id) {
                add--
              }
              for(var lk1 of linked) {
                if(session.data.confirmed !== undefined) {
                  if(session.data.confirmed.indexOf(lk1) !== -1 && lk1 !== item.id) {
                    add--
                  }
                }
              }
            }
          }
        }
      }
    }
    if(!group && (add > 0 || addp > 0)) {
      for(var session2 of global.sessions) {
        var sd2 = Moment(session2.data.date, 'DD/MM/YYYY HH:mm')
        if(sd2 > current && sd2 < next) {
          if((session2.data.attendance === 3 || session2.data.attendance === 5) && session2.data.client === item.id) {
            if(addp > 0) {
              addp--
            } else {
              add--
            }
          }
          if(family) {
            if(session2.data.client === client.id && client.id !== item.id) {
              add--
            }
            for(var lk2 of linked) {
              if((session2.data.attendance === 3 || session2.data.attendance === 5) && session2.data.client === lk2 && lk2 !== item.id) {
                add--
              }
            }
          }
        }
      }
    }

    if(add < 0) {
      add = 0
    }
    if(addp < 0) {
      addp = 0
    }
    var final = add+addp
    return final
  }

}


const b = new HelperBilling();
export default b;