import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import Firebase from 'firebase';
import Connector from '../../data/Connector';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import OverviewPage from '../Overview';
import EventEmitter from '../../helper/Emitter';
import Message from '../../components/Message';



class PasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      type: 'login',
      reset: '',
      password: '',
      sent: false,
      link1: ROUTES.LANDING,
      link2: ROUTES.SIGN_UP
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-2]
    if(id === 'member') {
      this.setState({
        link1: ROUTES.MEMBERLOGIN,
        LINK2: ROUTES.MEMBERSIGNUP
      })
    }
  }


  onChangeReset = event => {
    this.setState({reset: event.target.value});
  };


  resetPassword() {
    Firebase.auth().sendPasswordResetEmail(this.state.reset).then(() => {
      EventEmitter.dispatch('showMessage', 'Email sent successfully');
      this.setState({
        sent: true
      })
    }).catch(function(error) {
      EventEmitter.dispatch('showMessageError', error.message);
    });
  }



  // Display stuff ------------------------------------------------------------



  renderForm() {
    return (
      <div className="login content">
        <div className="box login static">
          <div style={{textAlign: 'center'}}>
            <img src="/img/logo.svg" alt="PT Mate Business Portal"/>
          </div>
          <h1 className="mt-20 mb-30">{!this.state.sent ? lang.t('auth:password.title') : lang.t('auth:password.title.sent')}</h1>
          <p className="clear mb-30" style={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:password.text')}</p>
          <label>{lang.t('auth:login.email')}</label>
          <input type="text" placeholder={lang.t('auth:login.email.placeholder')} value={this.state.reset} onChange={this.onChangeReset} name="email" />
          <div className="sv-30"></div>
          <button className="btn primary" onClick={() => this.resetPassword()}>{lang.t('auth:button.sendresetemail')}</button>
          <div className="sv-30"></div>
          <Link to={this.state.link1} className="btn tertiary width-12 link">{lang.t('common:button.cancel')}</Link>
        </div>
        <Message/>
      </div>
    )
  }


  renderConfirm() {
    return (
      <div className="login">
        <div className="content">
          <div className="box signin">
            <div className="center">
              <img src="/img/logo.svg" alt="PT Mate Business Portal"/>
            </div>
            <h1 className="mb-30">{lang.t('auth:password.title.sent')}</h1>
            <p className="clear">{lang.t('auth:password.sent.text1')}</p>
            <p>{lang.t('auth:password.sent.text2')}</p>
            <div className="space-20"></div>
            <Link to={this.state.link1} className="btn primary">{lang.t('auth:button.gotologin')}</Link>
          </div>
        </div>
        <Message/>
      </div>
    )
  }


  renderContent() {
    if(!this.state.sent) {
      return (
        <p className="clear mb-30" style={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:password.text')}</p>
      )
    } else {
      return (
        <p className="clear mb-30">{lang.t('auth:password.sent.text1')} {lang.t('auth:password.sent.text2')}</p>
      )
    }
  }


  renderBottom() {
    if(!this.state.sent) {
      return (
        <div>
          <label>{lang.t('auth:login.email')}</label>
          <input type="text" placeholder={lang.t('auth:login.email.placeholder')} value={this.state.reset} onChange={this.onChangeReset} name="email" />
          <div className="sv-30"></div>
          <button className="btn primary" onClick={() => this.resetPassword()}>{lang.t('auth:button.sendresetemail')}</button>
          <div className="sv-20"></div>
          <Link to={this.state.link1} className="btn width-12 tertiary">{lang.t('common:button.cancel')}</Link>
          <div className="sv-30"></div>
          <div className="signup">
            <Link to={this.state.link2} className='btn tertiary width-12'>{lang.t('auth:button.noaccountyet')}</Link>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="sv-20"></div>
          <Link to={this.state.link1} className="btn primary">{lang.t('auth:button.gotologin')}</Link>
        </div>
      )
    }
  }


  render() {
    if(Firebase.auth().currentUser != null) {
      if(Firebase.auth().currentUser.displayName !== null) {
        if(Firebase.auth().currentUser.displayName !== 'client') {
          Connector.setUser()
          return (
            <OverviewPage/>
          )
        } else {
          this.props.history.push(ROUTES.MEMBERLOADING);
        }
      } else {
        this.props.history.push(ROUTES.SIGN_UP2);
      }
      return (
        <div/>
      )
    } else {
      return (
        <div className="content login static">
          <div className="box login static">
            <div style={{textAlign: 'center'}}>
              <img src="/img/logo.svg" alt="PT Mate Business Portal"/>
            </div>
            <h1 className="mt-20 mb-30">{!this.state.sent ? lang.t('auth:password.title') : lang.t('auth:password.title.sent')}</h1>
            {this.renderContent()}
            {this.renderBottom()}
          </div>
        <Message/>
        </div>
      )
    }
  }
}


class PasswordFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null,
    };
  }
}


const PasswordFormHome = compose(
  withRouter,
  withFirebase,
)(PasswordFormBase);


export default withTranslation(['auth','common'])(PasswordPage);

export { PasswordFormHome };