import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import 'firebase/functions';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import ListEmpty from '../../components/List/empty';
import ModalForm from '../../components/Modals/form';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';


const p50users = ['syNDa8AYjlO1P7LvYh1ZpJ5bdXf2', '8iI4IDgiflQE5r5HT5iH5IRVnnt2']


class AccountAffiliatesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spaces: [],
      valueReferral: '',
      showModalReferral: false,
    };
  }


  componentDidMount() {
    document.title = 'Affiliates - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)
    Connector.setUser()
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainersLoaded', (event) => this.configureData())
    this.configureData()
    if(global.spaces.length === 0) {
      Connector.loadTrainers()
    }
  }


  configureData() { 
    var tmp = []
    for(var item of global.spaces) {
      if(global.affiliates.indexOf(item.id) !== -1) {
        tmp.push(item)
      }
    }
    console.log(global.affiliates)
    this.setState({
      spaces: tmp
    })
  }


  getPaying() {
    var num = 0
    for(var item of this.state.spaces) {
      if(item.data.subStatus === 'active' && item.data.subPlanId !== 'price_1NV5UQAd6uNQtfqaVnBkZ86A') {
        num++
      }
    }
    return num
  }


  getRevenue() {
    var num = 0
    for(var item of this.state.spaces) {
      if(item.data.subStatus === 'active' && item.data.subPlanId !== 'price_1NV5UQAd6uNQtfqaVnBkZ86A') {
        if(p50users.indexOf(item.id) !== -1) {
          var label = ''
          for(var i=0; i<global.stripePlans.length; i++) {
            if(item.data.subPlanId === global.stripePlans[i].id) {
              label = global.prices[i-1]
            }
          }
          var pr = label.split('$')
          num += pr[1]/2
        } else {
          num += 5
        }
      }
    }
    return num
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalReferral: false,
    })
  }


  sendReferral() {
    if(this.state.valueReferral !== '') {
      this.hideModals()
      var callFunction1 = Firebase.functions().httpsCallable('sendReferralEmailV2');
      callFunction1({email: this.state.valueReferral, name: global.userName, business: global.userBusiness, link: 'https://ptmate.app/affiliate/'+global.uid}).then(function(result) {})
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.emailinvitesent'));
    }
  }



  // Display stuff ------------------------------------------------------------



  getStatus(item) {
    if(item.data.subStatus === 'active' && item.data.subPlanId !== 'price_1NV5UQAd6uNQtfqaVnBkZ86A') {
      return <span className="pill green">{lang.t('account:affiliate.subscribed')}</span>
    } else {
      return <span className="pill">{lang.t('account:affiliate.notsubscribed')}</span>
    }
  }


  getMoney(item) {
    if(item.data.subStatus === 'active' && item.data.subPlanId !== 'price_1NV5UQAd6uNQtfqaVnBkZ86A') {
      if(p50users.indexOf(item.id) !== -1) {
        var label = ''
        for(var i=0; i<global.stripePlans.length; i++) {
          if(item.data.subPlanId === global.stripePlans[i].id) {
            label = global.prices[i-1]
          }
        }
        var pr = label.split('$')
        label = pr[1]/2
        return global.curSym+label
      } else {
        return "$5.00"
      }
      
    }
  }


  renderAffiliates() {
    if(this.state.spaces.length === 0) {
      return (
        <ListEmpty id='affiliates' type='simple'/>
      )
    } else {
      var list = this.state.spaces
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map(item => (
            <div className="box list simple highlight mb-10">
              <div className="col-4">
                <p className="lft"><strong>{item.data.name}</strong></p>
              </div>
              <div className="col-4">
                <p>{item.data.business}</p>
              </div>
              <div className="col-2">
                <p>{this.getStatus(item)}</p>
              </div>
              <div className="col-2">
                <p className="rgt">{this.getMoney(item)}</p>
              </div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          <div className="infobox primary large mb-10">
            <img src="/img/affiliates.svg" className="lft mr-20" alt="Become an ambassador"/>
            <div className="lft">
              <h2 className="mb-10" style={{color: '#ffffff'}}>{lang.t('account:affiliate.title')}</h2>
              <p>{lang.t('account:affiliate.text1')}<br/>{lang.t('account:affiliate.text2')} <strong>{global.curSym}5 {lang.t('account:affiliate.text3')}</strong> {lang.t('account:affiliate.text4')}</p>
            </div>
            <div className="clear"></div>
          </div>
          
          <div className="infobox large mb-50">
            <p><strong>{lang.t('account:affiliate.link')}:</strong> https://ptmate.app/affiliate/{global.uid}</p>
          </div>
          <div className='info large col-4'>
            <label>{lang.t('account:affiliate.youraffiliates')}</label>
            <p>{this.state.spaces.length}</p>
          </div>
          <div className='info large col-4'>
            <label>{lang.t('account:affiliate.subscribed')}</label>
            <p>{this.getPaying()}</p>
          </div>
          <div className='info large col-4'>
            <label>{lang.t('account:affiliate.revenue')}</label>
            <p>{global.curSym}{this.getRevenue().toFixed(2)}</p>
          </div>
          <div className="sv-30 clear"></div>
          <h3 className="mb-20">{lang.t('account:affiliate.list')}</h3>
          {this.renderAffiliates()}
          <div className="sv-20"></div>
          <button className="btn tertiary width-12" onClick={() => this.setState({showModalReferral: true})}>{lang.t('account:button.sendemailinvite')}</button>
          <div className="sv-30"></div>
        </div>
        <Header type='account' active='affiliates'/>
        <Navigation active='' />
        <ModalForm type={'referral'} show={this.state.showModalReferral} onHide={() => this.hideModals()} value1={this.state.valueReferral} updateValue1={(event) => this.setState({valueReferral: event})} clickMainButton={() => this.sendReferral()}/>
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['account','common','messaging'])(withRouter(withAuthorization(condition)(AccountAffiliatesPage)));