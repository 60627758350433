import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import ListClient from '../List/client';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class ModalBookings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      activity: props.activity ?? [],
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      activity: props.activity ?? [],
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      this.setState({
        hidden: '',
      });
    }, 100);
  }


  hide() {
    this.setState({
      hidden: 'hidden',
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  renderPill(item) {
    if(item.title === 'booking-member' || item.title === 'booking-member2') {
      return <span className="pill green">{lang.t('modals:bookings.bookedin')}</span>
    } else if(item.title === 'booking-trainer') {
      return <span className="pill green">{lang.t('modals:bookings.bookedinbytrainer')}</span>
    } else if(item.title === 'cancellation-member' || item.title === 'cancellation-member2') {
      return <span className="pill red">{lang.t('modals:bookings.canceled')}</span>
    } else if(item.title === 'cancellation-trainer') {
      return <span className="pill red">{lang.t('modals:bookings.canceledbytrainer')}</span>
    } else if(item.title === 'recurring-member') {
      return <span className="pill primary">{lang.t('modals:bookings.recurring')}</span>
    } else if(item.title === 'recurring-trainer') {
      return <span className="pill primary">{lang.t('modals:bookings.recurringtrainer')}</span>
    } else if(item.title === 'booking-waiting') {
      return <span className="pill primary">{lang.t('modals:bookings.bumpedup')}</span>
    } else if(item.title === 'waiting-member' || item.title === 'waiting-member2') {
      return <span className="pill primary">Entered the waiting list</span>
    } else if(item.title === 'waitingcancellation-member' || item.title === 'waitingcancellation-member2') {
      return <span className="pill primary">Left the waiting list</span>
    }
  }


  renderContent() {
    if(this.state.activity.length === 0) {
      return (
        <div className="scroll">
          <div className="empty small mt-30">
            <h4>{lang.t('modals:bookings.empty')}</h4>
          </div>
        </div>
      )
    } else {
      var list = this.state.activity
      list.sort((a,b) => Moment(b.date, 'DD/MM/YYYY HH:mm') - Moment(a.date, 'DD/MM/YYYY HH:mm'));
      return (
        <div className="scroll extended">
          {list.map(item => (
            <ListClient client={item.client} line1={Moment(item.date).locale(lang.language).format('ddd, D MMMM - LT')} line2={this.renderPill(item)} type='static'/>
          ))}
        </div>
      )
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box clients">
            <h2 className="mb-20 lft">{lang.t('modals:bookings.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
            <div className="clear"></div>
            {this.renderContent()}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default withTranslation(['modals','common'])(ModalBookings);