import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import lang from 'i18next';
import React from 'react';



class Cal {


  // Stats amount

  getAmount(sessions, archive, type, range) {
    var minDate = Moment(this.getDateRange(), 'X')
    var count = 0
    for(var item of sessions) {
      if(global.loc === '' || item.data.location === global.loc) {
        if((!item.data.group && type === 'sessions') || (item.data.group && type === 'group')) {
          if(range === '7days' || range === '30days' || range === '60days') {
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > minDate && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
              count++
            }
          } else if(range === 'month') {
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
              count++
            }
          } else if(range === 'week') {
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(1, 'days').startOf('day')) {
              count++
            }
          } else {
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') < Moment().format('X')) {
              count++
            }
          }
        }
      }
    }
    return count
  }



  // Stats difference badges

  getDifference(sessions, group, range, type) {
    var arr = ['', '+0']
    var today = Moment().format('X')
    var minDate = parseInt(this.getDateRangeDiff()[0])
    var minDate2 = parseInt(this.getDateRangeDiff()[1])
    var prevDate = parseInt(this.getDateRangeDiff()[2])

    var count1 = 0
    var count2 = 0
    for(var item of sessions) {
      var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
      if(item.data.timestamp !== undefined) {
        idate = Moment(item.data.timestamp, 'X')
      }
      if(global.loc === '' || item.data.location === global.loc) {
        if(item.data.group === group) {
          var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
          if(range === '7days' || range === '30days' || range === '60days') {
            if(idate > minDate && idate < today) {
              count1++
            }
            if(idate > prevDate && idate < minDate2) {
              count2++
            }
          } else if(range === 'month') {
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
              count1++
            }
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().add(-1, 'month').format('MM/YYYY')) {
              count2++
            }
          } else if(range === 'week') {
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(1, 'days').startOf('day')) {
              count1++
            }
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week').add(-7, 'days') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().startOf('week')) {
              count2++
            }
          }
        }
      }
    }

    arr[1] = String(count1-count2)
    if(count2 < count1) {
      arr[0] = 'green'
      arr[1] = '+'+arr[1]
    } else if(count2 > count1) {
      arr[0] = 'red'
    }

    if(type === 'badge') {
      if(range !== '60days') {
        return (
          <div className={'badge '+arr[0]} title='Change compared to previous period'>{arr[1]}</div>
        )
      } else {
        return (
          <div className={'badge'} title='Change compared to previous period'>N/A</div>
        )
      }
    } else {
      return count2
    }
    
  }



  // Stats difference badges

  getDifferenceTotal(sessions, range, type) {
    var arr = ['', '+0', '0']
    var today = Moment().format('X')
    var minDate = parseInt(this.getDateRangeDiff()[0])
    var minDate2 = parseInt(this.getDateRangeDiff()[1])
    var prevDate = parseInt(this.getDateRangeDiff()[2])

    var count1 = 0
    var count2 = 0
    for(var item of sessions) {
      if(global.loc === '' || item.data.location === global.loc) {
        var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
        if(item.data.timestamp !== undefined) {
          idate = Moment(item.data.timestamp, 'X')
        }
        if(range === '7days' || range === '30days' || range === '60days') {
          if(idate > minDate && idate < today) {
            count1++
          }
          if(idate > prevDate && idate < minDate2) {
            count2++
          }
        } else if(range === 'month') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
            count1++
          }
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().add(-1, 'month').format('MM/YYYY')) {
            count2++
          }
        } else if(range === 'week') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(1, 'days').startOf('day')) {
            count1++
          }
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week').add(-7, 'days') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().startOf('week')) {
            count2++
          }
        }
      }
    }

    arr[1] = String(count1-count2)
    if(count2 < count1) {
      arr[0] = 'green'
      arr[1] = '+'+arr[1]
    } else if(count2 > count1) {
      arr[0] = 'red'
    }
    arr[2] = count2

    if(type === 'badge') {
      if(range !== '60days') {
        return (
          <div className={'badge '+arr[0]} title='Change compared to previous period'>{arr[1]}</div>
        )
      } else {
        return (
          <div className={'badge'} title='Change compared to previous period'>N/A</div>
        )
      }
    } else {
      return count2
    }
  }



  // Stats amount per client

  getAmountClient(sessions, archive, type, range, client) {
    var minDate = this.getDateRange()
    var count = 0
    for(var item of sessions) {
      if(global.loc === '' || item.data.location === global.loc) {
        var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
        if(item.data.timestamp !== undefined) {
          idate = Moment(item.data.timestamp, 'X')
        }
        if((!item.data.group && type === 'sessions') || (item.data.group && type === 'group')) {
          var isGroup = false
          if(item.data.clients !== undefined && type === 'group') {
            if(item.data.clients.indexOf(client) !== -1) { isGroup = true }
          }
          if(range === '7days' || range === '30days' || range === '60days') {
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') > minDate && Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') < Moment().format('X') && (item.data.client === client || item.data.uid === client || isGroup)) {
              count++
            }
          } else if(range === 'month') {
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') < Moment().format('X') && (item.data.client === client || item.data.uid === client || isGroup)) {
              count++
            }
          } else if(range === 'week') {
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(1, 'days').startOf('day') && (item.data.client === client || item.data.uid === client || isGroup)) {
              count++
            }
          } else {
            if((item.data.client === client || item.data.uid === client || isGroup) && Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') < Moment().format('X')) {
              count++
            }
          }
        }
      }
    }
    return count
  }



  // Stats difference badges per client

  getDifferenceClient(sessions, group, range, client, type) {
    var arr = ['', '+0']
    var today = Moment().format('X')
    var minDate = parseInt(this.getDateRangeDiff()[0])
    var minDate2 = parseInt(this.getDateRangeDiff()[1])
    var prevDate = parseInt(this.getDateRangeDiff()[2])

    var count1 = 0
    var count2 = 0
    for(var item of sessions) {
      if(global.loc === '' || item.data.location === global.loc) {
        var isGroup = false
        var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
        if(item.data.timestamp !== undefined) {
          idate = Moment(item.data.timestamp, 'X')
        }
        if(item.data.clients !== undefined) {
          if(item.data.clients.indexOf(client) !== -1) { isGroup = true }
        }
        if(item.data.group === group) {
          var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
          if(range === '7days' || range === '30days' || range === '60days') {
            if(idate > minDate && idate < today && (item.data.client === client || isGroup)) {
              count1++
            }
            if(idate > prevDate && idate < minDate2 && (item.data.client === client || isGroup)) {
              count2++
            }
          } else if(range === 'month') {
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment() && (item.data.client === client || isGroup)) {
              count1++
            }
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().add(-1, 'month').format('MM/YYYY') && (item.data.client === client || isGroup)) {
              count2++
            }
          } else if(range === 'week') {
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(1, 'days').startOf('day') && (item.data.client === client || isGroup)) {
              count1++
            }
            if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week').add(-7, 'days') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().startOf('week') && (item.data.client === client || isGroup)) {
              count2++
            }
          }
        }
      }
    }

    arr[1] = String(count1-count2)
    if(count2 < count1) {
      arr[0] = 'green'
      arr[1] = '+'+arr[1]
    } else if(count2 > count1) {
      arr[0] = 'red'
    }

    if(type === 'badge') {
      if(range !== '60days') {
        return (
          <div className={'badge '+arr[0]} title='Change compared to previous period'>{arr[1]}</div>
        )
      } else {
        return (
          <div className={'badge'} title='Change compared to previous period'>N/A</div>
        )
      }
    } else if(type === 'num') {
      return count2
    } else {
      if(count2 === count1) {
        arr[1] = '+0'
      }
      return (
        <span className={'change '+arr[0]}> {arr[1]}</span>
      )
    }
    
  }



  // Date range

  getDateRange() {
    var ds1 = Moment().add(-30, 'days').format('DD/MM/YYYY')
    var minDate = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    if(global.dateRange === '7days') {
      ds1 = Moment().add(-7, 'days').format('DD/MM/YYYY')
      minDate = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    } else if(global.dateRange === 'month') {
      ds1 = Moment().format('MM/YYYY')
      var ds2 = '01/'+ds1+'00:00'
      minDate = Moment(ds2, 'DD/MM/YYYY HH:mm').format('X')
    } else if(global.dateRange === 'week') {
      ds1 = Moment().startOf('week').format('DD/MM/YYYY')
      minDate = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    } else if(global.dateRange === '60days') {
      ds1 = Moment().add(-90, 'days').format('DD/MM/YYYY')
      minDate = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    }
    return minDate
  }



  // Date range

  getDateRangeDiff() {
    var ds1 = Moment().add(-30, 'days').format('DD/MM/YYYY')
    var ds2 = Moment().add(-31, 'days').format('DD/MM/YYYY')
    var dsp = Moment().add(-60, 'days').format('DD/MM/YYYY')
    var minDate1 = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    var minDate2 = Moment(ds2+' 23:59', 'DD/MM/YYYY HH:mm').format('X')
    var prevDate = Moment(dsp+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    if(global.dateRange === '7days') {
      ds1 = Moment().add(-7, 'days').format('DD/MM/YYYY')
      ds2 = Moment().add(-8, 'days').format('DD/MM/YYYY')
      dsp = Moment().add(-14, 'days').format('DD/MM/YYYY')
      minDate1 = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
      minDate2 = Moment(ds2+' 23:59', 'DD/MM/YYYY HH:mm').format('X')
      prevDate = Moment(dsp+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    } else if(global.dateRange === '60days') {
      ds1 = Moment().add(-60, 'days').format('DD/MM/YYYY')
      ds2 = Moment().add(-61, 'days').format('DD/MM/YYYY')
      dsp = Moment().add(-120, 'days').format('DD/MM/YYYY')
      minDate1 = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
      minDate2 = Moment(ds2+' 23:59', 'DD/MM/YYYY HH:mm').format('X')
      prevDate = Moment(dsp+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    } else if(global.dateRange === 'month') {
      ds1 = Moment().format('MM/YYYY')
      ds2 = Moment().format('DD/MM/YYYY HH:mm')
      var ds11 = '01/'+ds1+'00:00'
      minDate1 = Moment(ds11+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
      minDate2 = Moment(ds2, 'DD/MM/YYYY HH:mm').format('X')
      prevDate = Moment(ds11+' 00:00', 'DD/MM/YYYY HH:mm').add(-1, 'months').format('X')
    } else if(global.dateRange === 'week') {
      ds1 = Moment().startOf('week').format('DD/MM/YYYY')
      ds2 = Moment().format('DD/MM/YYYY HH:mm')
      minDate1 = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
      minDate2 = Moment(ds2, 'DD/MM/YYYY HH:mm').add(-7, 'days').format('X')
      prevDate = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').add(-7, 'days').format('X')
    } else if(global.dateRange === 'all') {
      minDate1 = Moment().add(-30, 'years').format('X')
    }
    return [minDate1, minDate2, prevDate]
  }



  // Session duration

  getDuration(num) {
    var label = num+' min'
    let hrs = parseInt(num/60)
    let min = num-hrs*60
    if(hrs === 0) {
      label = num+' min'
    }
    if(hrs !== 0 && min === 0) {
      label = hrs+' h'
    }
    if(hrs !== 0 && min !== 0) {
      label = hrs+' h '+min+' min'
    }
    return label
  }


  // Duration in minutes

  getDurationMin(num) {
    var sec = " sec"
    if(lang.language === 'es') {
      sec = " seg"
    }

    var label = num+sec
    let hrs = parseInt(num/60)
    let min = num-hrs*60
    if(hrs === 0) {
      label = num+sec
    }
    if(hrs !== 0 && min === 0) {
      label = hrs+' min'
    }
    if(hrs !== 0 && min !== 0) {
      label = hrs+' min '+min+sec
    }
    return label
  }


  // Duration in minutes short

  getDurationMinAlt(num) {
    var label = ''
    let hrs = parseInt(num/60)
    let min = num-hrs*60
    label = hrs+':'+min.toLocaleString('en-US', {minimumIntegerDigits: 2})+' min'
    return label
  }


  // Format date

  getSpecialDate(item, short) {
    var today = "Today "
    var tomorrow = "Tomorrow "
    var yesterday = "Yesterday "
    if(lang.language === 'es') {
      today = "Hoy "
      tomorrow = "Mañana "
      yesterday = "Ayer"
    }

    var dt = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
    var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
    if(item.data.timestamp !== undefined) {
      dt = Moment(item.data.timestamp, 'X').format('DD/MM/YYYY')
      date = Moment(item.data.timestamp, 'X').format('X')
    }
    var tdy = Moment().format('DD/MM/YYYY')
    var tmr = Moment().add(1, 'day').format('DD/MM/YYYY')
    var ytd = Moment().add(-1, 'day').format('DD/MM/YYYY')
    if(dt === tdy) {
      //return lang.t('calendar:label.today')+' '+Moment(date, 'X').locale(lang.language).format('LT')
      return today+Moment(date, 'X').locale(lang.language).format('LT')
    } else if(dt === tmr) {
      //return lang.t('calendar:label.tomorrow')+' '+Moment(date, 'X').locale(lang.language).format('LT')
      return tomorrow+Moment(date, 'X').locale(lang.language).format('LT')
    } else if(dt === ytd) {
      //return lang.t('calendar:label.yesterday')+' '+Moment(date, 'X').locale(lang.language).format('LT')
      return yesterday+Moment(date, 'X').locale(lang.language).format('LT')
    } else {
      if(short) {
        return Moment(date, 'X').locale(lang.language).format('D MMM')+' - '+Moment(date, 'X').locale(lang.language).format('LT')
      } else {
        return Moment(date, 'X').locale(lang.language).format('ddd, D MMM YYYY')+' - '+Moment(date, 'X').locale(lang.language).format('LT')
      }
    }
  }


  // Special date for push notification

  getSpecialDatePush(date1, date2) {
    var label = Moment(date1, 'X').locale(lang.language).format('llll')
    if(Moment(date1, 'X').format('DD/MM/YYYY') === Moment(date2, 'X').format('DD/MM/YYYY')) {
      label = 'today '+Moment(date1, 'X').locale(lang.language).format('LT')
    }
    if(Moment(date1, 'X').format('DD/MM/YYYY') === Moment(date2, 'X').add(1, 'days').format('DD/MM/YYYY')) {
      label = 'tomorrow '+Moment(date1, 'X').locale(lang.language).format('LT')
    }
    return label
  }


  // Next session // DEPRECATED

  getNextSession(client) {
    var today = "Next: Today "
    var tomorrow = "Next: Tomorrow "
    var no = "No upcoming session"
    var next = "Next: "
    if(lang.language === 'es') {
      today = "Próxima: Hoy "
      tomorrow = "Próxima: Mañana "
      next = "Próxima: "
      no = "No hay próximas sesiones"
    }

    var label = no
    var today = parseInt(Moment().format('X'))
    var tdy = Moment().format('DD/MM/YYYY')
    var tmr = Moment().add(1, 'days').format('DD/MM/YYYY')
    var base = parseInt(Moment().add(120, 'days').format('X'))
    for(var item of global.sessions) {
      var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X'))
      var dt = Moment(item.data.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
      if(item.data.client === client && date > today && date < base) {
        label = next+Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM - LT')
        if(dt === tdy) {
          label = today+Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
        } else if(dt === tmr) {
          label = tomorrow+Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
        }
      }
    }
    return label
  }


  // Session color

  getColor(dur) {
    var color = 'primary'
    if(dur > 30 && dur < 46) {
      color = 'secondary'
    } else if(dur > 45 && dur < 61) {
      color = 'tertiary'
    } else if(dur > 60 && dur < 76) {
      color = 'quarternary'
    } else if(dur > 75) {
      color = 'red'
    }
    if(dur === 0) {
      color = ''
    }
    return color
  }


  // Program time

  calcBlockTime(ex, type, rounds, emom, time, time2, block) {
    var num = 0
    if(type === 0 || type === 5) {
      num = rounds
    } else if(type === 1) {
      var work = 60
      if(ex.length > 0) {
        work = parseInt(ex[0].wmin)*60+parseInt(ex[0].wsec)
        num = rounds*ex.length*work
      }
      if(emom) {
        num = rounds*work
      }
    } else if(type === 2) {
      var tmp = 0
      if(emom) {
        tmp += time
        tmp += time2
      } else {
        for(let exx of ex) {
          var work2 = parseInt(exx.wmin)*60+parseInt(exx.wsec)
          var rest = parseInt(exx.rmin)*60+parseInt(exx.rsec)
          tmp += work2+rest
        }
      }
      num = rounds*tmp
    } else if(type === 3) {
      num = rounds*240
    } else if(type === 4) {
      for(var exr of ex) {
        if(exr.tool !== 6 && exr.tool !== 7 && exr.tool !== 9) {
          var reps = 0
          var test = String(exr.reps)
          if(exr.reps !== '') {
            reps = parseInt(exr.reps)
          }
          if(test.indexOf('-') !== -1) {
            reps = 0
            var ar = exr.reps.split('-')
            for(var a of ar) {
              if(a !== '') {
                reps += parseInt(a)
              }
            }
          }
          num += (reps*3)*rounds
          if(reps === 0 || reps === undefined) {
            num += 60*rounds
          }
        } else {
          if(reps === 0 || reps === undefined) {
            num += 60*rounds
          } else {
            num += (reps/10)*rounds
          }
        }
      }
      if(time !== 0) {
        num += time*rounds
      }
      //num = 1
    }
    if(isNaN(num)) {
      num = 300
    }
    return parseInt(num)
  }


  // Date for push notification

  getPushDate(date) {
    var today = "today "
    var tomorrow = "tomorrow "
    if(lang.language === 'es') {
      today = "hoy "
      tomorrow = "mañana "
    }

    var label = lang.t('calendar:label.on')+' '+Moment(date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('llll')
    var d1 = Moment().format('DD/MM/YYYY')
    var d2 = Moment().add(1, 'days').format('DD/MM/YYYY')
    var ds = Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
    if(ds === d1) {
      label = today+Moment(date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    if(ds === d2) {
      label = tomorrow+Moment(date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    return label
  }


  // Check for scheduled items

  checkScheduled() {
    global.checkSchedule = false
    for(var item of global.sessions) {
      if(item.data.template !== undefined && item.data.template !== '' && Moment() < Moment(item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours')) {
        var found1 = false
        var found2 = false
        for(var sch of global.schedule) {
          if(sch.id === item.id+'-push') {
            if(!item.data.group) {
              found1 = true
            } else {
              if(item.data.clients !== undefined) {
                if(sch.data.tokens.length === item.data.clients.length) {
                  found1 = true
                }
              } else {
                found1 = true
              }
            }
          }
          if(sch.id === item.id+'-push') {
            found2 = true
          }
        }
        if(!found1) {
          this.createScheduled(item, 'push')
        }
        if(!found2) {
          this.createScheduled(item, 'email')
        }
      }
    }
  }


  createScheduled(item, type) {
    var name = ""
    var date = item.data.timestamp
    var scheduled = Moment(item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')
    if(type === 'push') {
      if(item.data.group && item.data.clients !== undefined) {
        // Group push
        name = lang.t('messaging:push.groupsession')
        var tokens = []
        if(item.data.client !== '') {
          name = item.data.client
        }
        if(item.data.availability) {
          name = lang.t('messaging:push.available')
        }
        for(var cl1 of global.clients) {
          if(item.data.clients.indexOf(cl1.id) !== -1 && cl1.data.pushToken !== '' && cl1.data.pushToken !== undefined) {
            tokens.push(cl1.data.pushToken)
          }
        }
        if(tokens.length > 0) {
          Firebase.database().ref('/schedule/'+item.id+'-push').update({
            title: lang.t('messaging:push.sessionreminder.title'),
            desc: lang.t('messaging:push.bookedin.text4')+name+" "+this.getSpecialDatePush(date, scheduled)+".",
            type: "",
            timestamp: parseInt(Moment(item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')),
            tokens: tokens,
            message: "push",
            uid: global.uid,
            iid: item.id
          })
        }
      } else if(!item.data.group) {
        // 1:1 push
        var token = ""
        for(var cl2 of global.clients) {
          if(cl2.id === item.data.client) {
            token = cl2.data.pushToken
            if(item.data.name !== undefined) {
              name = item.data.name
            }
          }
        }
        if(token !== '' && token !== undefined) {
          Firebase.database().ref('/schedule/'+item.id+'-push').update({
            title: lang.t('messaging:push.sessionreminder.title'),
            desc: lang.t('messaging:push.bookedin.text4')+(name === '' ? lang.t('messaging:push.ptsession') : name)+' '+this.getSpecialDatePush(date, scheduled)+".",
            type: "",
            timestamp: parseInt(Moment(item.data.timestamp, 'X').add(-global.userSessionReminder, 'hours').format('X')),
            tokens: [token],
            message: "push",
            uid: global.uid,
            iid: item.id
          })
        }
      }
      
    } else {
      if(item.data.group && item.data.clients !== undefined) {
        // Group email
        name = lang.t('messaging:push.groupsession')
        var emails = []
        if(item.data.client !== '') {
          name = item.data.client
        }
        if(item.data.availability) {
          name = lang.t('messaging:push.available')
        }
        for(var cl3 of global.clients) {
          if(item.data.clients.indexOf(cl3.id) !== -1 && cl3.data.email !== '' && cl3.data.email !== undefined) {
            if(cl3.data.email.indexOf("@") !== -1 && cl3.data.email.indexOf(".") !== -1 && global.spaceEmailReminder && (cl3.data.emailReminder === undefined || cl3.data.emailReminder)) {
              emails.push(cl3.data.email)
            }
          }
        }
        if(emails.length > 0) {
          Firebase.database().ref('/schedule/'+item.id+'-email').update({
            title: global.userBusiness,
            desc: name,
            type: this.getSpecialDatePush(date, scheduled),
            timestamp: parseInt(Moment(date, 'X').add(-global.userSessionReminder, 'hours').format('X')),
            tokens: emails,
            message: "sessionemail",
            uid: global.uid,
            iid: global.uid
          })
        }
      } else if(!item.data.group) {
        // 1:1 email
        var email = ""
        var reminder = false
        for(var cl4 of global.clients) {
          if(cl4.id === item.data.client) {
            email = cl4.data.email
            reminder = cl4.data.emailReminder
            if(item.data.name !== undefined) {
              name = item.data.name
            }
          }
        }
        if(email !== '' && email !== undefined && global.spaceEmailReminder && (reminder === undefined || reminder)) {
          Firebase.database().ref('/schedule/'+item.id+'-email').update({
            title: global.userBusiness,
            desc: (name === '' ? lang.t('messaging:push.ptsession') : name),
            type: this.getSpecialDatePush(date, scheduled),
            timestamp: parseInt(Moment(date, 'X').add(-global.userSessionReminder, 'hours').format('X')),
            tokens: [email],
            message: "sessionemail",
            uid: global.uid,
            iid: global.uid
          })
        }
      }
    }
  }

}


const b = new Cal();
export default b;