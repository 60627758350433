import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import InputDate from '../../components/Form/input-date';
import Select from '../../components/Form/select';
import Loader from '../../components/Form/loader';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class AdminNewStaffPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: ['', '', '', '01/01/1990'],
      role: 'assistant',
      file: null,
      fileDisplay: null,
      error: false,
      error2: false,
      email: '',
      errorEmail: false,
      user: '',
      margin: 0,
      valid: false,
      uid: global.uid,
      loading: false,
      roles: [
        {name: lang.t('admin:staff.assistanttrainer')+' ('+lang.t('admin:form.limitedaccess')+')', value: 'assistant'},
        {name: lang.t('admin:staff.trainer'), value: 'trainer'},
        {name: lang.t('admin:staff.admin'), value: 'admin'},
        {name: lang.t('admin:staff.finance'), value: 'finance'},
        {name: lang.t('admin:staff.cs'), value: 'cs'},
        {name: lang.t('admin:staff.manager'), value: 'manager'},
      ]
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('New Staff Member - PT Mate')
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('logLoaded', (event) => this.configureLog())
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      this.setState({
        uid: global.uid
      })
    }
  }


  configureLog() {
    if(this._isMounted) {
      var cid = ''
      var error = ''
      for(var log of global.userLog) {
        if(log.data.type === 'staff' && log.data.message === this.state.values[1]) {
          cid = log.data.title
          Firebase.database().ref('/log/'+global.uid+'/'+log.id).remove()
        }
        if(log.data.type === 'stafferror' && log.data.message === this.state.values[1]) {
          error = log.data.title
          Firebase.database().ref('/log/'+global.uid+'/'+log.id).remove()
        }
      }

      if(cid !== '' && this.state.loading && error === '') {
        var birth = '01/01/1900'
        if(this.state.values[3] !== '' && this.state.values[3] !== 'Invalid Date' && this.state.values[3] !== undefined) {
          birth = this.state.values[3]
        }

        // send welcome email

        var phone = this.state.values[2].replace('.' ,'').replace('-' ,'').replace(' ' ,'').replace('e' ,'')
        Firebase.database().ref('/usersStaff/'+cid).set({
          name: this.state.values[0],
          email: this.state.values[1],
          phone: phone,
          birth: birth,
          uid: cid,
          image: '',
          imageDate: Moment().format('DD/MM/YYYY HH:mm'),
          role: this.state.role,
          pushToken: '',
          space: global.uid,
          country: 'au',
          status: 'active'
        }).then(() => {
          Firebase.database().ref('/spaces/'+global.uid+'/staff/'+cid).update({
            id: cid,
            role: this.state.role,
            name: this.state.values[0],
            status: 'active',
          })
          global.message = lang.t('messaging:message.staffmembercreated')

          var link = 'https://ptmate.app/staff/setup/'+this.state.values[1]
          var invite = Firebase.functions().httpsCallable('sendStaffJoinV2');
          invite({type: 'creation', email: this.state.values[1], name: global.userBusiness, user: global.userName, staffname: this.state.values[0], link: link}).then(function(result) {
            // nothing
          })

          this.props.history.push(ROUTES.ADMINSTAFF)
        }).catch((error)=>{
          this.setState({
            loading: false
          })
          EventEmitter.dispatch('showMessageError', error.message);
        })
        if(this.state.file !== null) {
          const sr = Firebase.storage().ref()
          const fr = sr.child('images/users/'+cid+'.jpg')
          fr.put(this.state.file).then(() => {
            Firebase.database().ref('/usersStaff/'+cid).update({
              image: 'images/users/'+cid+'.jpg',
              imageDate: Moment().format('DD/MM/YYYY HH:mm')
            })
          }).catch((error)=>{
            EventEmitter.dispatch('showMessageError', error.message);
          })
        }
      } else if(error !== '' && this.state.loading) {
        this.setState({
          loading: false
        })
        EventEmitter.dispatch('showMessageError', error);
      }
    }
  }



  // Client update stuff ------------------------------------------------------------



  updateValues(val, pos) {
    var tmp = this.state.values
    tmp[pos] = val
    this.setState({
      values: tmp,
    });
  }


  setImage(file) {
    this.setState({
      file: file,
      fileDisplay:  URL.createObjectURL(file)
    })
  }


  displayImage() {
    if(this.state.file === null) {
      return (
        <div className="avatar"><p>✎</p></div>
      )
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.fileDisplay+')'}}> </div>
      )
    }
  }


  checkCreate() {
    this.setState({error: false})
    var tmp = false
    var tmp2 = false
    var tmp3 = false
    if(this.state.values[0] === '') {
      tmp = true
    }
    if(this.state.values[1] === '') {
      tmp2 = true
    }
    if(global.userSubPlan === 'businessmonthly' || global.userSubPlan === 'businessyearly' || global.userSubStatus === 'trialing') {
      // role passed
    } else {
      this.setState({
        role: 'assistant'
      })
    }
    this.setState({error: tmp, error2: tmp2})
    if(!tmp && !tmp2 && !this.state.loading) {
      this.createStaff()
    }
  }


  createStaff() {
    this.setState({
      loading: true
    })
    var callFunction1 = Firebase.functions().httpsCallable('createStaffV2');
    callFunction1({email: this.state.values[1], password: 'password', uid: global.uid}).then(function(result) {})
  }



  // Display stuff ------------------------------------------------------------



  renderButton() {
    var allowed = true
    if(global.userSubPlan !== 'businessmonthly' && global.userSubPlan !== 'businessyearly' && global.userSubStatus !== 'trialing' && this.state.role !== 'assistant') {
      allowed = false
    }
    if(allowed) {
      return (
        <button className="btn primary" onClick={() => this.checkCreate()}>{lang.t('admin:button.addstaffmember')}</button>
      )
    } else {
      return (
        <div className="info-small warning">
          <p>{lang.t('admin:label.upgradesubscription')}<br/><Link to={ROUTES.SUBSCRIPTION}>{lang.t('admin:button.managesubscription')}</Link></p>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('admin')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
              <div className="box">
                <div className="avatar-container">
                  {this.displayImage()}
                </div>
                <div className="form-item" style={{textAlign: 'center'}}>
                  <label>{lang.t('admin:form.image')}</label>
                  <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0]);}}/>
                </div>
                <InputText label={lang.t('admin:form.name')+"*"} value={this.state.values[0]} error={this.state.error} errormsg={lang.t('admin:error.name')} onChange={(event) => this.updateValues(event, 0)}/>
                <InputText label={lang.t('admin:form.email')+"*"} value={this.state.values[1]} error={this.state.error2} errormsg={lang.t('admin:error.email')} onChange={(event) => this.updateValues(event, 1)}/>
                <InputNumber label={lang.t('admin:form.mobile')} value={this.state.values[2]} onChange={(event) => this.updateValues(event, 2)}/>
                <InputDate label={lang.t('admin:form.dob')} value={Moment(this.state.values[3], 'DD/MM/YYYY').format('YYYY-MM-DD')} format='YYYY-MM-DD' onChange={(event) => this.updateValues(Moment(event).format('DD/MM/YYYY'), 3)}/>
                <Select label={lang.t('admin:form.role')} value={this.state.role} values={this.state.roles} onChange={(event) => this.setState({role: event})}/>
                <div className="clear sv-30"></div>
                {this.renderButton()}
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id='addstaff'/>
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          <Header title={lang.t('admin:title.newstaffmember')} link={ROUTES.ADMINSTAFF}/>
          <Navigation active='admin' />
          <Loader show={this.state.loading} label={lang.t('admin:label.creatingstaffmember')}/>
        </div> 
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='staff-locked' type='simple'/>
          </div>
          <Header title={lang.t('admin:title.newstaffmember')} link={ROUTES.ADMINSTAFF}/>
          <Navigation active='admin' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['admin','common','header','messaging'])(withRouter(withAuthorization(condition)(AdminNewStaffPage)));