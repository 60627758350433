import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import InputRadio from '../../components/Form/radio';
import InfoData from '../../components/Form/info';
import FormCard from '../../components/Form/card';
import Loader from '../../components/Form/loader';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class TrialPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: global.userSubPlan,
      planId: global.userSubPlanId,
      status: global.userSubStatus,
      end: global.userSubEnd,
      card: global.userCard,
      show: 'confirm',
      selType: '',
      selName: '',
      selBilling: '',
      selCard: false,
      values: ['', '', '', '', ''],
      error: [false, false, false, false, false],
      message: '',
      log: global.userLog,
      loading: false,
      // user data
      userStripe: global.userStripe,
      userSubId: global.userSubId,
      userSubPlanId: global.userSubPlanId,
      selected: 'price_1OOCAEAd6uNQtfqaqhBopmgh',
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Subscribe to Pro or Business - PT Mate'
    window.Intercom("update");
    window.scrollTo(0, 0)

    global.currentClient = '';
    EventEmitter.subscribe('userLoaded', (event) => this.checkSub())
    EventEmitter.subscribe('logLoaded', (event) => {
      this.setState({
        log: global.userLog
      }, () => {
        this.checkLog()
      })
      
    })
    if(global.userPhone !== '') {
      this.checkSub()
    }
    this.configureData()
    this.checkLog()
  }


  checkSub() {
    if(global.userStripe === '') {
      Connector.loadSubscriptions((result) => {
        var existing = ''
        var sub = ''
        for(var item of global.allSubs) {
          if(item.id === global.uidUser) {
            existing = item.stripe
            sub = item.sub
          }
        }
        if(existing === '') {
          var dt1 = Moment(global.userSubEnd, 'X')
          var dt2 = Moment()
          var days = parseInt(dt1.diff(dt2, 'days'))
          if(days < 0) {
            days = 0
          }
          var createStripe = Firebase.functions().httpsCallable('createStripeCustomerV2');
          createStripe({email: global.userEmail, name: global.userName, days: days, user: Firebase.auth().currentUser.uid, currency: global.userCurrency}).then(function(result) {
            //done
          })
        } else {
          var callFunction = Firebase.functions().httpsCallable('retrieveSubscriptionV2');
          callFunction({uid: global.uidUser, sub: sub, customer: existing}).then(function(result) {});
        }
      })
    }
    this.configureData()
  }


  configureData() {
    var tmp = false
    if(global.userCard === undefined || global.userCard === '') {
      tmp = true
    }
    this.setState({
      plan: global.userSubPlan,
      planId: global.userSubPlanId,
      status: global.userSubStatus,
      end: global.userSubEnd,
      card: global.userCard,
      selCard: tmp,
      show: 'confirm',
      selType: '',
      selName: '',
      selBilling: '',
      values: ['', '', '', '', ''],
      log: global.userLog,
      // user data
      userStripe: global.userStripe,
      userSubId: global.userSubId,
      userSubPlanId: global.userSubPlanId,
      coupon: global.coupon,
    })
  }


  checkLog() {
    for(var item of this.state.log) {
      if(item.data.title === 'subupdateerror' || item.data.title === 'subbillingerror' || item.data.title === 'subcancelerror') {
        EventEmitter.dispatch('showMessageError', item.data.message);
        this.setState({loading: false})
        Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
      }
      if(item.data.title === 'subupdate' || item.data.title === 'subbilling' || item.data.title === 'subupdate' || item.data.title === 'subcancel') {
        this.setState({
          loading: false,
          show: 'success'
        })
        EventEmitter.dispatch('showMessage]', this.state.message);
        Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
      }
    }
  }



  // Manage plan ------------------------------------------------------------
  


  renderCycle(type) {
    if(type === 'simple') {
      if(this.state.plan.indexOf('monthly') !== -1) {
        return lang.t('account:sub.monthly')
      } else {
        return lang.t('account:sub.yearly2')
      }
    } else {
      var label = global.prices[4]+' '+lang.t('account:sub.permonth-s')
      return label
    }
  }



  // Confirmation ------------------------------------------------------------
  


  configurePayment() {
    var plan = this.state.selected
    this.payCard(plan)
  }


  payCard(plan) {
    this.setState({error: [false, false, false, false, false]})
    var tmp = [false, false, false, false, false]
    var passed = true
    if(this.state.values[0] === '') {
      tmp[0] = true
      passed = false
    }
    if(this.state.values[1] === '') {
      tmp[1] = true
      passed = false;
    }
    if(this.state.values[2] === '') {
      tmp[2] = true
      passed = false;
    }
    if(this.state.values[3] === '') {
      tmp[3] = true
      passed = false;
    }
    if(this.state.values[4] === '') {
      tmp[4] = true
      passed = false;
    }
    this.setState({error: tmp})
    if(passed) {
      // Execute payment
      this.setState({
        loading: true,
        message:  lang.t('messaging:message.subscriptionupdated')
      })
      var callFunction = Firebase.functions().httpsCallable('createCardV2');
      callFunction({customer: this.state.userStripe, name: this.state.values[0], number: this.state.values[1], month: this.state.values[2], year: this.state.values[3], cvc: this.state.values[4], subscription: this.state.userSubId, plan: plan, subid: global.userSubItemId, coupon: '3VOMd9hx', uid: global.uidUser}).then(function(result) {
        //var sanitizedMessage = result.data.text;
      });
    }
  }


  showConfirm(type) {
    var name = lang.t('account:sub.plan.pro')
    if(this.state.selected === 'price_1OOCAEAd6uNQtfqaqhBopmgh') {
      name = lang.t('account:sub.plan.business')
    }
    this.setState({
      selType: type,
      selName: name,
      selBilling: 'monthly',
      show: 'confirm'
    })
  }



  // Display stuff ------------------------------------------------------------



  renderPrice() { 
    var label = global.prices[4]+' '+lang.t('account:sub.permonth-s')
    if(this.state.selected === 'price_1OOCAEAd6uNQtfqaqhBopmgh') {
      label = global.prices[6]+' '+lang.t('account:sub.permonth-s')
    }
    return label
  }


  renderSavedCard() {
    var label = ''
    for(var item of global.cards) {
      if(item.id === this.state.card) {
        label = '('+item.data.brand+' ending '+item.data.last4+')'
      }
    }
    return label
  }


  renderPayment() {
    return (
      <div className="clear pt-20">
        <FormCard values={this.state.values} error={this.state.error} onChange={(event) => this.setState({values: event})}/>
        <div className="clear sv-30"></div>
      </div>
    )
  }


  renderComments() {
    if(this.state.selected === 'price_1NV8IcAd6uNQtfqaM6TkUcLP') {
      return (
        <div className="col-6 sidebar">
          <img src={"/img/sub-pro.png"} alt="Pro" style={{width: '100%', margin: '0 auto'}}/>
          <p className="mb-30">{lang.t('account:trial.info.pro1')}</p>
          <p>{lang.t('account:trial.info.pro2')}</p>
        </div>
      )
    } else {
      return (
        <div className="col-6 sidebar">
          <img src={"/img/sub-business.png"} alt="Business" style={{width: '100%', margin: '0 auto'}}/>
          <p className="mb-30">{lang.t('account:trial.info.business')}</p>
        </div>
      )
    }
  }


  renderConfirm() {
    if(this.state.show === 'confirm') {
      return (
        <div className="content form-sidebar mt-20">
          <div className="col-6">
            <div className="box">
              <div className="infobox green large mb-20">
                <p>{lang.t('account:trial.limited1')} {Moment(global.userSubEnd, 'X').locale(lang.language).format('D MMMM')}</p>
              </div>
              <h4 className="mb-20">{lang.t('account:trial.limited2')}</h4>
              <InputRadio
                label='Selected plan'
                value={this.state.selected}
                clickElement={(event) => this.setState({selected: event})}
                values={[{name: lang.t('account:sub.plan.pro'), value: 'price_1NV8IcAd6uNQtfqaM6TkUcLP'}, {name: lang.t('account:sub.plan.business'), value: 'price_1OOCAEAd6uNQtfqaqhBopmgh'}]}
              />
              <InfoData label='Price' value={this.renderPrice()}/>
              {this.renderPayment()}
              <div className="infobox mb-40">
                <p>{lang.t('account:trial.limited3')} {Moment(global.userSubEnd, 'X').add(1, 'months').locale(lang.language).format('D MMMM YYYY')}. {lang.t('account:trial.limited4')}</p>
              </div>
              <button className="btn primary" onClick={() => this.configurePayment()}>{lang.t('account:button.subscribenow')}</button>
            </div>
          </div>
          {this.renderComments()}
          <div className="clear sv-40"></div>
        </div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  renderSuccess() {
    if(this.state.show === "success") {
      return (
        <div className="content form-container mt-20">
          <div className="box">
            <div className="infobox green large mb-30">
              <p>{lang.t('account:trial.congratulations')}</p>
            </div>
            <h2 className="mb-30">{lang.t('account:trial.subscribed')} {this.state.selected === 'price_1NV8IcAd6uNQtfqaM6TkUcLP' ? lang.t('account:sub.plan.pro') : lang.t('account:sub.plan.business')}</h2>
            <p className="mb-60">{lang.t('account:trial.firstcharge1')} {this.state.selected === 'price_1NV8IcAd6uNQtfqaM6TkUcLP' ? global.prices[4] : global.prices[6]} {lang.t('account:trial.firstcharge2')} {Moment(global.userSubEnd, 'X').add(1, 'months').locale(lang.language).format('D MMMM YYYY')}</p>
            <Link to={ROUTES.OVERVIEW} className="btn primary">{lang.t('account:button.gotooverview')}</Link>
          </div>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        {this.renderConfirm()}
        {this.renderSuccess()}
        <div className="header">
          <div className="content">
            <h2>{lang.t('account:trial.title')}</h2>
          </div>
        </div>
        <Navigation active=''/>
        <Loader show={this.state.loading}/>
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['account','common','messaging'])(withRouter(withAuthorization(condition)(TrialPage)));