import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import { CSVReader } from 'react-papaparse';
import Connector from '../../data/Connector';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperBusiness from '../../helper/Business';
import Navigation from '../../components/Navigation';
import InputToggle from '../../components/Form/toggle';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import HeaderForm from '../../components/Headers/form';
import HeaderFormTabs from '../../components/Headers/form-tabs';
import ListEmpty from '../../components/List/empty';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';


const buttonRef = React.createRef()

class ClientsNewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invite: true,
      data: null,
      name: 9999,
      fname: 9999,
      lname: 9999,
      email: 9999,
      phone: 9999,
      goal: 9999,
      dob: 9999,
      height: 9999,
      session1: 9999,
      session2: 9999,
      mode: 'form',
      error: false,
      start: 1,
      preview: 1,
      active: true,
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Import Client List - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    if(global.adminClients.length === 0) {
      Connector.loadAdminClients()
    }
  }



  // File parsing stuff ------------------------------------------------------------



  handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
  }


  handleOnFileLoad = (data) => {
    var num = 0
    var pdata = data
    
    for(var j=pdata.length-1; j>-1; j--) {
      if(pdata[j].data.length === 1 && pdata[j].data[0] === '') {
        pdata.splice(j, 1)
      }
    }
    if(pdata[0].data.length === 1 || (pdata[0].data.length > 1 && pdata[0].data[1] === '')) {
      num = 1
    }
    var name = 9999
    var fname = 9999
    var lname = 9999
    var email = 9999
    var phone = 9999
    var goal = 9999
    var dob = 9999
    var height = 9999
    var session1 = 9999
    var session2 = 9999
    if(pdata[0].data.length > 0) {
      for(var i=0; i<pdata[num].data.length; i++) {
        if((pdata[num].data[i]).toLowerCase().indexOf('name') !== -1 && (pdata[num].data[i]).toLowerCase().indexOf('membership') === -1) {
          name = i
        }
        if((pdata[num].data[i]).toLowerCase().indexOf('firstname') !== -1 || (pdata[num].data[i]).toLowerCase().indexOf('first name') !== -1) {
          fname = i
        }
        if((pdata[num].data[i]).toLowerCase().indexOf('lastname') !== -1 || (pdata[num].data[i]).toLowerCase().indexOf('last name') !== -1) {
          lname = i
        }
        if((pdata[num].data[i]).toLowerCase().indexOf('email') !== -1 || (data[num].data[i]).toLowerCase().indexOf('e-mail') !== -1) {
          email = i
        }
        if((pdata[num].data[i]).toLowerCase().indexOf('phone') !== -1 || (data[num].data[i]).toLowerCase().indexOf('mobile') !== -1) {
          phone = i
        }
        if((pdata[num].data[i]).toLowerCase().indexOf('birth') !== -1 || (data[num].data[i]).toLowerCase().indexOf('dob') !== -1 || (data[num].data[i]).toLowerCase().indexOf('d.o.b.') !== -1) {
          dob = i
        }
        if((pdata[num].data[i]).toLowerCase().indexOf('goal') !== -1) {
          goal = i
        }
        if((pdata[num].data[i]).toLowerCase().indexOf('height') !== -1) {
          height = i
        }
        if((pdata[num].data[i]).toLowerCase().indexOf('1:1') !== -1) {
          session1 = i
        }
        if((pdata[num].data[i]).toLowerCase().indexOf('group') !== -1) {
          session2 = i
        }
      }
    }
    this.setState({
      data: data,
      name: name,
      fname: fname,
      lname: lname,
      email: email,
      phone: phone,
      goal: goal,
      dob: dob,
      height: height,
      session1: session1,
      session2: session2,
      error: false,
      start: num,
      preview: num+1,
    })
  }


  handleOnError = (err, file, inputElem, reason) => {
    //console.log(err)
    this.setState({
      error: true
    })
  }


  handleOnRemoveFile = (data) => {
    /*console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')*/
  }


  handleRemoveFile = (e) => {
    if (buttonRef.current) {
      buttonRef.current.removeFile(e)
    }
    this.setState({
      data: null,
      name: 9999,
      fname: 9999,
      lname: 9999,
      email: 9999,
      phone: 9999,
      goal: 9999,
      dob: 9999,
      height: 9999,
      session1: 9999,
      session2: 9999,
      error: false
    })
  }


  updatePreview(type) {
    var num = this.state.preview
    if(type === 'prev' && this.state.preview > this.state.start+1) {
      num--
    }
    if(type === 'next' && this.state.preview < this.state.data.length-1) {
      num++
    }
    this.setState({
      preview: num
    })
  }



  // Client update stuff ------------------------------------------------------------



  checkCreate() {
    global.badgeLocked = true
    for(var i=this.state.start+1; i<this.state.data.length; i++) {
      if(this.state.data[i].data.length === this.state.data[this.state.start].data.length) {
        this.createClient(this.state.data[i], i)
      }
    }
  }


  async createClient(data, num) {
    setTimeout(() => {
      EventEmitter.dispatch('updateBadges', 'import');
    }, 1000);
    var name = lang.t('clients:label.client')
    var email = ''
    var phne = ''
    var birth = '01/01/1900'
    var height = 0
    var goal = ''
    var session1 = 0
    var session2 = 0
    if(this.state.name !== 9999) {
      name = data.data[this.state.name]
    }
    if(this.state.fname !== 9999 && this.state.lname !== 9999) {
      name = data.data[this.state.fname]+" "+data.data[this.state.lname]
    }
    if(this.state.email !== 9999) {
      email = data.data[this.state.email]
    }
    if(this.state.phone !== 9999) {
      phne = data.data[this.state.phone]
      phne = phne.replace('.' ,'').replace('-' ,'').replace(' ' ,'').replace('e' ,'')
    }
    if(this.state.goal !== 9999) {
      goal = data.data[this.state.goal]
    }
    if(this.state.height !== 9999) {
      var h = data.data[this.state.height]
      height = h.replace(/[^0-9]/g, '')
      if(height === '') {
        height = 0
      }
    }
    if(this.state.session1 !== 9999) {
      session1 = data.data[this.state.session1]
    }
    if(this.state.session2 !== 9999) {
      session2 = data.data[this.state.session2]
    }
    if(this.state.dob !== 9999) {
      var date = Moment(data.data[this.state.dob], ['DD/MM/YYYY', 'DD/MM/YY', 'DD.MM.YYYY', 'DD MMMM YYYY', 'DD MMM YYYY', 'DD MMM YY', 'D MMM YY', 'YYYY-MM-DDTHH:mm:ss'])
      if(date.isValid) {
        birth = Moment(data.data[this.state.dob], ['DD/MM/YYYY', 'DD/MM/YY', 'DD.MM.YYYY', 'DD MMMM YYYY', 'DD MMM YYYY', 'DD MMM YY', 'D MMM YY', 'YYYY-MM-DDTHH:mm:ss']).format('DD/MM/YYYY')
      }
      if(birth === 'Invalid date') {
        birth = '01/01/1900'
      }
    }
    var business = global.userBusiness
    if(global.userBusiness === "") {
      business = global.userName
    }
    var obj = Firebase.database().ref('/clients/'+global.uid).push()
    await obj.set({
      active: this.state.active,
      name: name,
      phone: phne,
      email: email,
      birth: birth,
      height: parseInt(height),
      goal: goal,
      uid: '',
      image: '',
      imageDate: Moment().format('DD/MM/YYYY HH:mm'),
      prevSessions: session1,
      prevSessionsGroup: session2,
    }).then(() => {
      if(email !== '' && email.indexOf('@') !== -1 && email.indexOf('.') !== -1 && this.state.invite && this.state.active) {
        var callFunction1 = Firebase.functions().httpsCallable('sendClientInviteV2');
        callFunction1({email: email, name: global.userName, user: global.uidUser, business: business, phone: global.spacePin, link: global.uid}).then(function(result) {})

        if(phne !== "") {
          Firebase.database().ref('/connect/'+obj.key).update({
            phone: phne,
            name: global.userName,
            client: obj.key,
            space: global.uid,
            email: email,
          })
        }
      }
      if(num > this.state.data.length-3) {
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.clientsimported'));
        this.setState({
          mode: 'success'
        })
      }
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  // Display stuff ------------------------------------------------------------



  renderButton() {
    if(this.state.data !== null) {
      return (
        <div className="clear">
          <button className="btn primary" onClick={() => this.checkCreate()}>{lang.t('clients:button.importclientlist')}</button>
        </div>
      )
    }
  }


  renderRemove() {
    if(this.state.data !== null) {
      return (
        <button className="btn tertiary rgt small close csv-button" onClick={this.handleRemoveFile}>{lang.t('common:button.remove')}</button>
      )
    }
  }


  renderError() {
    if(this.state.error) {
      return (
        <div>
          <div className="sv-10 clear"></div>
          <p className="error clear">{lang.t('clients:error.document')}</p>
        </div>
      )
    }
  }


  renderPrevDate(item) {
    var birth = ''
    var date = Moment(item, ['DD/MM/YYYY', 'DD/MM/YY', 'DD.MM.YYYY', 'DD MMMM YYYY', 'DD MMM YYYY', 'DD MMM YY', 'D MMM YY', 'YYYY-MM-DDTHH:mm:ss'])
    if(date.isValid) {
      birth = Moment(item, ['DD/MM/YYYY', 'DD/MM/YY', 'DD.MM.YYYY', 'DD MMMM YYYY', 'DD MMM YYYY', 'DD MMM YY', 'D MMM YY', 'YYYY-MM-DDTHH:mm:ss']).format('DD/MM/YYYY')
    }
    if(birth === 'Invalid date') {
      birth = ''
    }
    return birth
  }


  renderPreview() {
    if(this.state.data !== null) {
      return (
        <div>
          <div className="sc-30"></div>
          <div className="infobox preview">
            <p><strong>Preview ({this.state.preview-this.state.start} of {this.state.data.length-this.state.start-1})</strong><button className="btn tertiary small basic rgt" onClick={() => this.updatePreview('next')}>Next</button> <button className="btn tertiary small basic rgt" onClick={() => this.updatePreview('prev')}>Prev</button><br/>&nbsp;<br/>
            {lang.t('clients:label.name')}: {this.state.fname !== 9999 && this.state.lname !== 9999 ? this.state.data[this.state.preview].data[this.state.fname]+' '+this.state.data[this.state.preview].data[this.state.lname] : this.state.data[this.state.preview].data[this.state.name]}<br/>
            {lang.t('clients:label.email')}: {this.state.data[this.state.preview].data[this.state.email]}<br/>
            {lang.t('clients:label.phone')}: {this.state.data[this.state.preview].data[this.state.phone]}<br/>
            {lang.t('clients:label.dob')}: {this.renderPrevDate(this.state.data[this.state.preview].data[this.state.dob])}<br/>
            {lang.t('clients:label.height')}: {this.state.data[this.state.preview].data[this.state.height]}<br/>
            {lang.t('clients:label.goal')}: {this.state.data[this.state.preview].data[this.state.goal]}<br/></p>
            <p></p>
          </div>
        </div>
        
      )
    }
  }


  renderContent() {
    if(this.state.mode !== 'success') {
      return (
        <div className="content form-sidebar withtabs">
          <div className="col-6 mt-20">
            <div className="box">
              <label>{lang.t('clients:form.chooseacsvfile')}</label>
              <CSVReader
                ref={buttonRef}
                onFileLoad={this.handleOnFileLoad}
                onError={this.handleOnError}
                noClick
                noDrag
                onRemoveFile={this.handleOnRemoveFile}
              >
              {({ file }) => (
                <div>
                  <div className="file lft">{this.state.data === null ? lang.t('clients:form.nofileselected') : file && file.name}</div>
                  {this.renderRemove()}
                  <button className="btn tertiary rgt small csv-button" onClick={this.handleOpenDialog}>{this.state.data === null ? lang.t('clients:button.choosefile') : lang.t('clients:button.change')}</button>
                </div>
              )}
              </CSVReader>
              {this.renderError()}
              <div className="sv-40"></div>
              <InputToggle label={lang.t('clients:form.activeclients')} value={this.state.active} onChange={(event) => this.setState({active: event})} text={lang.t('clients:form.activeclients.text')}/>
              {this.state.active ? <InputToggle label={lang.t('clients:form.sendinvite')} value={this.state.invite} onChange={(event) => this.setState({invite: event})} text={lang.t('clients:form.sendinvite.text')}/> : ''}

              {this.renderPreview()}
              <div className="sv-10"></div>
              {this.renderButton()}
            </div>
          </div>
          <div className="col-6 mt-20">
            <div className="sidebar">
              <WidgetFormInfo id='clientimport'/>
              <div className="infobox">
                <h5>{lang.t('clients:label.needhelp')}</h5>
                <p>{lang.t('clients:label.needhelp.text')} <a href="/csv/clients.zip" target="_blank" rel="noopener noreferrer">{lang.t('clients:label.needhelp.button')}</a>.</p>
              </div>
            </div>
          </div>
          <div className="clear sv-40"></div>
        </div>
      )
    } else {
      return (
        <div className="content form-container withtabs">
          <div className="box">
            <div className="invite-info">
              <img src="/img/status-ok.svg" alt="File uploaded" className="small"/>
              <div className="space-20"></div>
              <h3 className="clear" style={{float: 'none', textAlign: 'center'}}>{lang.t('clients:label.listuploaded')}</h3>
              <p>{lang.t('clients:label.listuploaded.text')}</p>
            </div>
            <div className="form">
              <button className="btn tertiary width-12" onClick={() => this.props.history.push(global.currentPage)}>{lang.t('clients:button.goback')}</button>
            </div>
          </div>
          
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          {this.renderContent()}
          <div className="btn-news" onClick={() => window.Intercom('startTour', '320211')}>Show me around</div>
          <HeaderFormTabs title={lang.t('clients:title.newclient')} link={global.currentPage} type='clients' active='import'/>
          <Navigation active='clients' />
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='newimport-locked' type='simple'/>
          </div>
          <HeaderForm title={lang.t('clients:title.newclient')} link={global.currentPage}/>
          <Navigation active='clients' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['clients','common','header','modals','messaging'])(withRouter(withAuthorization(condition)(ClientsNewPage)));