import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class CalendarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: global.sessions,
      sessionsAll: global.sessions,
      archive: global.archive,
      events: global.events,
      clients: global.clients,
      staff: global.userStaff,
      currentClient: '',
      dateRange: global.dateRange,
      hidden: 'hidden',
      months: ['', '', '', ''],
      current: global.currentMonth,
      currentDay: global.currentDay,
      days: [],
      empty: [],
      times: [],
      selected: Moment().format('DD/MM/YYYY'),
      today: [],
      trainer: global.uidUser,
      view: global.currentCalendarView,
      all: [],
      showOverlayNextStep: false,
      hiddenNextStep: 'hidden',
      margin: 0,
      week: global.currentWeek,
      newLink: ROUTES.NEWSESSION,
      loading: true
    };
  }


  componentDidMount() {
    HelperPage.initialize('Calendar - PT Mate', ROUTES.CALENDAR)
    this._isMounted = true

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      if(global.message === lang.t('messaging:message.sessioncreated') && global.userSubStatus === 'trialing' && global.sessions.length === 1) {
        setTimeout(() => {
          this.showOverlayNextStep()
          global.message = ''
        }, 1100);
      }
    }
    if(global.currentDay === '') {
      this.setState({
        currentDay: Moment().format('D')
      })
    }

    var tmp = []
    for(var i=0; i<24; i++) {
      var label = i
      if(i<10) {
        label = '0'+i
      }
      tmp.push(label+':00')
      tmp.push(label+':30')
    }
    this.setState({
      times: tmp,
      view: global.currentCalendarView
    })

    if(HelperBusiness.getRoleIncl('admin,finance,cs')) {
      this.setState({
        trainer: ''
      })
    }
    
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('userLoaded', (event) => this.configureUser())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('eventsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.setValidSessions())
    this.configureData()
    if(!global.updatingRec) {
      Connector.checkRecurring()
    }

    setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 300);
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureUser() {
    if(this._isMounted) {
      if(HelperBusiness.getRoleIncl('')) {
        this.setState({
          trainer: '',
        })
      }
    }
  }


  configureData() {
    if(this._isMounted) {
      var m1 = Moment().add(-1, 'month').locale(lang.language).format('MMM YYYY')
      var m2 = Moment().locale(lang.language).format('MMM YYYY')
      var m3 = Moment().add(1, 'month').locale(lang.language).format('MMM YYYY')
      var m4 = Moment().add(2, 'month').locale(lang.language).format('MMM YYYY')
      if(this.state.trainer === '' && HelperBusiness.getRole('assistant,assistant2,trainer')) {
        this.setState({
          trainer: global.uidUser
        })
      }
      var s1 = 0
      var s2 = 0
      var link = ROUTES.NEWSESSION
      for(var item of global.sessions) {
        if(item.data.group) {
          s2++
        } else {
          s1++
        }
      }
      if(s2 > s1) {
        link = ROUTES.NEWGROUP
      }
      this.setState({
        months: [m1, m2, m3, m4],
        current: global.currentMonth,
        week: global.currentWeek,
        newLink: link,
      }, () => {
        var wd = Moment(this.state.week, 'w').startOf('week').format('DD/MM/YYYY')
        this.configureMonth(this.state.current)
        this.selectWeek(wd)
      })
    }
  }


  configureMonth(num) {
    var d = Moment().add((num-1), 'months').format('DD/MM')
    var start = parseInt(Moment().startOf('month').add((num-1), 'month').format('d'))
    var end = parseInt(Moment(d, 'DD/MM').endOf('month').format('D'))
    var tmp = []
    var tmp2 = []
    var count = 0

    if(start > 0) {
      for(var i1=0; i1<start; i1++) {
        tmp2.push({
          day: '',
        })
        count++
      }
    }
    
    for(var i=0; i<end; i++) {
      var clear = ''

      if(count === 7 || count === 14 || count === 28 || count === 35) {
        clear = ' clear'
        count = 0
      }
      tmp.push({
        clear: clear,
        day: (i+1),
        active: true
      })
      count++
    }

    this.setClientSessions(this.state.currentClient)

    var tmp1 = []
    var tmp22 = []
    var tmp3 = []
    var tmp4 = []
    for (var item1 of global.clients) {
      tmp1.push(item1)
    }
    for (var item2 of global.events) {
      tmp22.push(item2)
    }
    for (var item3 of global.archive) {
      tmp3.push(item3)
    }
    for (var item4 of global.userStaff) {
      if(item4.data.role === 'assistant' || item4.data.role === 'assistant2' || item4.data.role === 'trainer') {
        tmp4.push(item4)
      }
    }

    this.setState({
      empty: tmp2,
      days: tmp,
      clients: tmp1,
      events: tmp22,
      archive: tmp3,
      staff: tmp4
    })
  }


  setMonth(num) {
    global.currentMonth = num
    var week = parseInt(Moment('1 '+this.state.months[num], 'D MMM YYYY').format('w'))
    var date = Moment('1 '+this.state.months[num], 'D MMM YYYY').format('DD/MM/YYYY')
    this.setState({
      current: num,
      selected: date,
      currentDay: 1,
      week: week
    }, () => {
      this.configureMonth(num)
    })
  }



  // Overview Calculations ------------------------------------------------------------



  getActiveClients() {
    var minDate = Moment().add(-31, 'days')
    if(this.state.dateRange === '7days') {
      minDate = Moment().add(-8, 'days')
    }
    var clients = []
    for(var item of this.state.sessions) {
      var add = false
      if(this.state.dateRange === '7days' || this.state.dateRange === '30days') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > minDate && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add = true
        }
      } else if(this.state.dateRange === 'month') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add = true
        }
      } else {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') < Moment()) {
          add = true
        }
        
      }
      if(add && clients.indexOf(item.data.client) === -1) {
        if(!item.data.group) {
          clients.push(item.data.client)
        } else {
          if(item.data.clients !== undefined) {
            for(var client of item.data.clients) {
              if(clients.indexOf(client) === -1) {
                clients.push(client)
              } 
            }
          }
        }
        
      }
    }
    for(var item2 of this.state.archive) {
      var add2 = false
      if(this.state.dateRange === 'all') {
        add2 = true
      }
      if(add2 && clients.indexOf(item2.data.client === -1)) {
        if(!item2.data.group) {
          clients.push(item2.data.client)
        } else {
          if(item2.data.clients !== undefined) {
            for(var client2 of item2.data.clients) {
              if(clients.indexOf(client2) === -1) {
                clients.push(client2)
              } 
            }
          }
          
        }
      }
    }
    return clients.length
  }


  getActiveDifference(type) {
    var arr = ['', '+0']
    var today = Moment().format('X')
    var minDate = Moment().add(-31, 'days').format('X')
    var minDate2 = Moment().add(-30, 'days').format('X')
    var prevDate = Moment().add(-61, 'days').format('X')
    if(this.state.dateRange === '7days') {
      minDate = Moment().add(-8, 'days').format('X')
      minDate = Moment().add(-7, 'days').format('X')
      prevDate = Moment().add(-15, 'days').format('X')
    }

    var clients1 = []
    var clients2 = []

    for(var item of this.state.sessions) {
      var add1 = false
      var add2 = false
      var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
      if(this.state.dateRange === '7days' || this.state.dateRange === '30days') {
        if(idate > minDate && idate < today) {
          add1 = true
        }
        if(idate > prevDate && idate < minDate2) {
          add2 = true
        }
      } else if(this.state.dateRange === 'month') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add1 = true
        }
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().add(-1, 'month').format('MM/YYYY')) {
          add2 = true
        }
      }

      if(add1 && clients1.indexOf(item.data.client) === -1) {
        if(!item.data.group) {
          clients1.push(item.data.client)
        } else {
          if(item.data.clients !== undefined) {
            for(var client of item.data.clients) {
              if(clients1.indexOf(client) === -1) {
                clients1.push(client)
              } 
            }
          }
        }
      }
      if(add2 && clients2.indexOf(item.data.client) === -1) {
        if(!item.data.group) {
          clients2.push(item.data.client)
        } else {
          if(item.data.clients !== undefined) {
            for(var client2 of item.data.clients) {
              if(clients2.indexOf(client2) === -1) {
                clients2.push(client2)
              } 
            }
          }
        }
      }
    }

    arr[1] = String(clients1.length-clients2.length)
    if(clients2.length < clients1.length) {
      arr[0] = 'green'
      arr[1] = '+'+arr[1]
    } else if(clients2.length > clients1.length) {
      arr[0] = 'red'
    }

    if(type === 'badge') {
      return (
        <div className={'badge '+arr[0]} title={lang.t('overview:label.changecompared')}>{arr[1]}</div>
      )
    } else {
      return clients2.length
    }
  }


  setDateRange(event) {
    this.setState({dateRange: global.dateRange})
  }


  setView(type) {
    global.currentCalendarView = type
    this.setState({
      view: type
    })
  }


  getClientName(id) {
    var label = lang.t('common:label.deletedclient')
    for(var item of this.state.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    for(var item2 of this.state.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name
      }
    }
    return label
  }


  getClientNameAvail(session) {
    var label = lang.t('common:label.deletedclient')
    for(var item of this.state.clients) {
      if(item.id === session.data.clients[0]) {
        label = item.data.name
      }
    }
    return label
  }


  getAssistant() {
    var id = ''
    var name = ''
    for(var item of global.userAssistant) {
      if(item.id === global.currentAssistant) {
        id = item.id
        name = item.data.name
      }
    }
    for(var item2 of global.userSpaces) {
      if(item2.id === global.uidUser) {
        id = item2.id
        name = item2.data.name
      }
    }
    return [id, name]
  }


  selectTrainer(event) {
    this.setState({
      trainer: event.target.value
    }, () => {
      this.setValidSessions()
    })
  }



  // Overlay next step ------------------------------------------------------------



  showOverlayNextStep() {
    this.setState({
      showOverlayNextStep: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenNextStep: '',
        margin: -height/2
      });
    }, 100);
  }


  hideOverlayNextStep() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenNextStep: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayNextStep: false});
    }, 500);
  }


  renderOverlayNextStep() {
    if(this.state.showOverlayNextStep) {
      return (
        <div className={'overlay '+this.state.hiddenNextStep}   >
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modals:nextstep.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayNextStep()}>{lang.t('common:button.close')}</button>
            <p className="clear mb-30">{lang.t('modals:nextstep.text')}</p>
            <a href="https://help.ptmate.net" target="_blank" rel="noopener noreferrer" className="btn primary">{lang.t('modals:nextstep.button')}</a>
          </div>
        </div>
      )
    }
  }



  // Month stuff ------------------------------------------------------------



  getSessions(day) {
    var list = []
    var month = Moment().add((this.state.current-1), 'months').format('MM/YYYY')
    var date2 = day+'/'+month
    for(var item of this.state.sessions) {
      var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('D/MM/YYYY')
      if(date === date2) {
        list.push(item)
      }
    }
    var list2 = []
    for(var item2 of this.state.events) {
      var datee = Moment(item2.data.date, 'DD/MM/YYYY HH:mm').format('D/MM/YYYY')
      if(datee === date2) {
        if(HelperBusiness.getRoleIncl('') || (HelperBusiness.getRole('assistant,assistant2,trainer,admin,finance,cs') && !item2.data.private && item2.data.private !== undefined)) {
          list2.push(item2)
        }
      }
    }
    list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
    list2.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));

    var farr = []
    for(var i1 of list) {
      farr.push(i1)
    }
    for(var i2 of list2) {
      farr.push(i2)
    }
    farr.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));

    return (
      <div>
        {farr.map(item => (
          <div className="cal-session" key={item.id} onClick={() => this.clickSession(item)}>
            {this.renderStatus(item)}
            <div className={'icon clear '+this.getSessionDayClass(item)[0]}>
              <div className={'inner '+this.getSessionDayClass(item)[1]}></div>
            </div>
            <p className="lft"><strong>{this.getSessionDayName(item)}{this.getSessionVirtual(item)}</strong><br/>{this.getSessionCustomName(item)}{Moment(item.data.timestamp, 'X').locale(lang.language).format('LT')} - {Cal.getDuration(item.data.duration)}{this.getSessionLocation(item)}</p>
            <div className="clear"></div>
          </div>
        ))}
      </div>
    )
  }


  getSessionName(item) {
    var label = lang.t('calendar:session.group')
    if(item.data.group && item.data.client !== '') {
      label = item.data.client
    }
    if(!item.data.group) {
      for(var client of this.state.clients) {
        if(client.id === item.data.client) {
          label = client.data.name
        }
      }
      for(var client2 of global.clientsInactive) {
        if(client2.id === item.data.client) {
          label = client2.data.name
        }
      }
    }
    if(item.data.link !== undefined && item.data.link !== '') {
      //label += " (Virtual)"
    }
    return label
  }


  getSessionVirtual(item) {
    if(item.data.link !== undefined && item.data.link !== '') {
      return (
        <span>
          <img src='/img/cam.svg' alt='Virtual' className='show-light'/>
          <img src='/img/cam-dark.svg' alt='Virtual' className='show-dark'/>
        </span>
      )
    }
  }


  setClientSessions(client) {
    this.setState({
      currentClient: client,
    }, () => {
      this.setValidSessions()
    })
  }


  setValidSessions() {
    var tmp = []
    var tmp2 = []
    var tmp3 = []
    for(var item of global.sessions) {
      if(this.state.trainer === '') {
        if(this.state.currentClient === '') {
          tmp.push(item)
        } else {
          if(item.data.client === this.state.currentClient) {
            tmp.push(item)
          }
          if(item.data.clients !== undefined) {
            if(item.data.clients.indexOf(this.state.currentClient) !== -1) {
              tmp.push(item)
            }
          }
        }
      } else {
        if(item.data.trainerId === this.state.trainer || (item.data.trainerId === undefined && this.state.trainer === global.uid)) {
          if(this.state.currentClient === '') {
            tmp.push(item)
          } else {
            if(item.data.client === this.state.currentClient) {
              tmp.push(item)
            }
            if(item.data.clients !== undefined) {
              if(item.data.clients.indexOf(this.state.currentClient) !== -1) {
                tmp.push(item)
              }
            }
          }
        }
      }
      
      if(this.state.currentClient === '') {
        tmp2.push(item)
      } else {
        if(item.data.client === this.state.currentClient) {
          tmp2.push(item)
        }
        if(item.data.clients !== undefined) {
          if(item.data.clients.indexOf(this.state.currentClient) !== -1) {
            tmp2.push(item)
          }
        }
      }
    }

    for(var ses of tmp) {
      if(global.loc === '') {
        tmp3.push(ses)
      } else {
        if(ses.data.location !== undefined) {
          if(ses.data.location === global.loc) {
            tmp3.push(ses)
          }
        }
      }
    }

    this.setState({
      sessions: tmp3,
      sessionsAll: tmp3
    })
  }


  clickSession(item) {
    if(item.data.title !== undefined) {
      this.clickEvent(item)
    } else {
      if(item.data.group) { 
        if(item.data.availability) {
          this.props.history.push('/calendar/availability/'+item.id)
        } else {
          this.props.history.push('/calendar/group-session/'+item.id)
        }
      } else {
        this.props.history.push('/calendar/session/'+item.id)
      }
    }
    
  }


  clickEvent(item) {
    this.props.history.push('/calendar/event/'+item.id)
  }



  // Week stuff ------------------------------------------------------------



  renderRadioWeeks() {
    var array = []
    var d1 = Moment(this.state.months[0], 'MMM YYYY').startOf('week')
    var d2 = Moment(this.state.months[3], 'MMM YYYY').endOf('month').startOf('week')
    var diff = d2.diff(d1, 'weeks')
    for(var i=0; i<=diff; i++) {
      var d = Moment(this.state.months[0], 'MMM YYYY').startOf('week').add(i*7, 'days')
      array.push({
        week: parseInt(d.format('w')),
        label: d.locale(lang.language).format('D MMM')+'⤍',
        date: d.format('DD/MM/YYYY')
      })
    }
    var divider = (100/(diff+1))+'%'

    return (
      <div className="radios">
        {array.map(item => (
          <button className={this.state.week === item.week ? 'radio active' : 'radio'} onClick={() => this.selectWeek(item.date)} key={item.week} style={{width: divider}}>{item.label}</button>
        ))}
      </div>
    )
  }


  getSessionDayClass(item) {
    var color = 'primary'
    var icon = 'group'
    if(item.data.group && item.data.invitees !== undefined) {
      if(item.data.invitees.length > 0) {
        icon = 'lock'
      }
    }
    if(item.data.group && item.data.memberships !== undefined) {
      if(item.data.memberships.length > 0) {
        icon = 'lock'
      }
    }
    if(item.data.group && item.data.groups !== undefined) {
      if(item.data.groups.length > 0) {
        icon = 'lock'
      }
    }
    if(item.data.availability !== undefined) {
      if(item.data.availability && item.data.clients === undefined) {
        color = 'grey'
        icon = 'session'
      }
      if(item.data.availability && item.data.clients !== undefined) {
        color = 'secondary'
        icon = 'session'
      }
    }
    if(!item.data.group) {
      color = 'secondary'
      icon = 'session'
    }
    if(item.data.title !== undefined) {
      color = 'quarternary'
      icon = 'event'
    }
    return [color, icon]
  }


  getSessionDayName(item) {
    var label = this.getSessionName(item)
    if(item.data.availability !== undefined) {
      if(item.data.availability) {
        label = lang.t('calendar:session.available')
        if(item.data.clients !== undefined) {
          label = this.getClientNameAvail(item)
        }
      }
    }
    if(item.data.title !== undefined) {
      label = item.data.title
    }
    return label
  }


  getSessionCustomName(item) {
    if(item.data.name !== undefined && item.data.name !== '') {
      return <span>{item.data.name}<br/></span>
    }
  }


  getSessionCustomName2(item) {
    if(item.data.name !== undefined && item.data.name !== '') {
      return item.data.name+' - '
    }
  }


  getSessionsDay(day, starttime, endtime) {
    if(!this.state.loading) {
    var week = Moment(this.state.selected, 'DD/MM/YYYY').startOf('week').format('DD/MM/YYYY')
    var array = []
    var date = Moment(week, 'DD/MM/YYYY').add(day, 'days').format('DD/MM/YYYY')
    var start = Moment(date+' '+starttime, 'DD/MM/YYYY HH:mm')
    var end = Moment(date+' '+endtime, 'DD/MM/YYYY HH:mm')
    for(var item of this.state.sessions) {
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') >= start && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < end) {
        array.push(item)
      }
    }
    var array2 = []
    for(var item2 of this.state.events) {
      if(Moment(item2.data.date, 'DD/MM/YYYY HH:mm') >= start && Moment(item2.data.date, 'DD/MM/YYYY HH:mm') < end) {
        if(HelperBusiness.getRoleIncl('') || (HelperBusiness.getRole('assistant,assistant2,trainer') && !item2.data.private && item2.data.private === undefined)) {
          array2.push(item2)
        }
        
      }
    }

    var farr = []
    for(var i1 of array) {
      farr.push(i1)
    }
    for(var i2 of array2) {
      farr.push(i2)
    }
    farr.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));

    return (
      <div>
        {farr.map(item => (
          <div className="cal-session" key={item.id} onClick={(e) => this.clickSession(item)}>
            {this.renderStatus(item)}
            <div className={'icon clear '+this.getSessionDayClass(item)[0]}>
              <div className={'inner '+this.getSessionDayClass(item)[1]}></div>
            </div>
            <p className="lft"><strong>{this.getSessionDayName(item)}{this.getSessionVirtual(item)}</strong><br/>{this.getSessionCustomName(item)}{Moment(item.data.timestamp, 'X').locale(lang.language).format('LT')} - {Cal.getDuration(item.data.duration)}{this.getSessionLocation(item)}</p>
            <div className="clear"></div>
          </div>
        ))}
      </div>
    )}
  }


  renderStatus(item) {
    if(Moment(item.data.timestamp, 'X').add(item.data.duration, 'minutes') < Moment() && item.data.attendance === 2) {
      return <div className="status yellow">{lang.t('calendar:label.confirmattendance')}</div>
    } else if(Moment(item.data.timestamp, 'X').add(item.data.duration, 'minutes') < Moment() && item.data.attendance === 3) {
      if(!item.data.group) {
        return <div className="status green">{lang.t('calendar:label.attended')}</div>
      } else {
        var num = 0
        if(item.data.clients !== undefined) {
          num = item.data.clients.length
        }
        return <div className="status green">{num} {lang.t('calendar:label.attended-s')}</div>
      }
      
    } else if(Moment(item.data.timestamp, 'X').add(item.data.duration, 'minutes') < Moment() && item.data.attendance === 4) {
      return <div className="status red">{lang.t('calendar:label.notattended')}</div>
    }
  }


  selectWeek(date) {
    var wk = Moment(date, 'DD/MM/YYYY').format('w')
    var month = 0
    var m = Moment(date, 'DD/MM/YYYY').format('MM')
    if(m === Moment().format('MM')) {
      month = 1
    }
    if(m === Moment().add(1, 'months').format('MM')) {
      month = 2
    }
    if(m === Moment().add(2, 'months').format('MM')) {
      month = 3
    }
    global.currentMonth = month
    global.currentWeek = wk
    this.setState({
      week: parseInt(wk),
      selected: date,
      current: month
    })
  }



  // Day stuff ------------------------------------------------------------



  renderRadioMonthDays() {
    var max = parseInt(Moment(this.state.months[this.state.current], 'MMM YYYY').endOf('month').format('D'))
    var current = parseInt(Moment(this.state.selected, 'DD/MM/YYYY').format('D'))
    var list = []
    for(var i=0; i<max; i++) {
      var label = 'monthday'
      if(i === current-1) {
        label = 'monthday active'
      }
      list.push({
        value: i+1,
        class: label
      })
    }
    return (
      <div className="radios">
        {list.map(item => (
          <button className={'radio '+item.class} key={item.value} onClick={() => this.selectDate(item.value)}>{item.value}</button>
        ))}
      </div>
    )
  }


  selectDate(day) {
    var date = Moment(day+' '+this.state.months[this.state.current], 'D MMM YYYY').format('DD/MM/YYYY');
    this.setState({
      selected: date
    })
  }


  getDayPosition(time) {
    var tme1 = Moment(time, 'X').format('H')
    var tme2 = Moment(time, 'X').format('m')
    return (tme1*60+tme2*1)
  }


  getDayPosition2(time) {
    var tme1 = Moment(time, 'DD/MM/YYYY HH:mm').format('H')
    var tme2 = Moment(time, 'DD/MM/YYYY HH:mm').format('m')
    return (tme1*60+tme2*1)
  }


  getSessionLocation(item) {
    if(item.data.locationName !== undefined && item.data.locationName !== '') {
      return <span><br/>{item.data.locationName}</span>
    }
  }


  getSessionsDay2() {
    var width = 600
    if(document.getElementsByClassName("day2")[0] !== undefined) {
      width = document.getElementsByClassName("day2")[0].clientWidth
    }
    var date = Moment(this.state.selected, 'DD/MM/YYYY').format('DD/MM/YYYY')
    var array = []
    var start = Moment(date+' 00:00', 'DD/MM/YYYY HH:mm')
    var end = Moment(date+' 23:59', 'DD/MM/YYYY HH:mm')
    for(var item of this.state.sessionsAll) {
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') >= start && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < end && !item.data.availability) {
        array.push(item)
      }
    }

    var array2 = []
    for(var item2 of this.state.events) {
      if(Moment(item2.data.date, 'DD/MM/YYYY HH:mm') >= start && Moment(item2.data.date, 'DD/MM/YYYY HH:mm') < end) {
        if(HelperBusiness.getRoleIncl('') || (HelperBusiness.getRole('assistant,assistant2,trainer') && !item2.data.private && item2.data.private === undefined)) {
          array2.push(item2)
        }
        
      }
    }

    var array3 = []
    for(var item3 of this.state.sessions) {
      if(Moment(item3.data.date, 'DD/MM/YYYY HH:mm') >= start && Moment(item3.data.date, 'DD/MM/YYYY HH:mm') < end && item3.data.availability) {
        array3.push(item3)
      }
    }

    return (
      <div>
        {array.map(item => (
          <div className="cal-session" key={item.id} onClick={() => this.clickSession(item)} style={{position: 'absolute', marginTop: this.getDayPosition(item.data.timestamp), height: item.data.duration, width: width-10}}>   
            <p><strong>{this.getSessionName(item)}{this.getSessionVirtual(item)}</strong> {this.getSessionCustomName2(item)} {Moment(item.data.timestamp, 'X').format('LT')} - {Cal.getDuration(item.data.duration)}{this.renderStatus(item)}</p>
            <div className="clear"></div>
          </div>
        ))}
        {array2.map(item => (
          <div className="cal-session" key={item.id} onClick={() => this.clickEvent(item)} style={{position: 'absolute', marginTop: this.getDayPosition2(item.data.date), height: item.data.duration, width: width-10}}>
            <p><strong>{item.data.title}{this.getSessionVirtual(item)}</strong> {this.getSessionCustomName2(item)} {Moment(item.data.timestamp, 'X').format('LT')} - {Cal.getDuration(item.data.duration)}{this.getSessionLocation(item)}</p>
            <div className="clear"></div>
          </div>
        ))}
        {array3.map(item => (
          <div className="cal-session" key={item.id} onClick={() => this.clickSession(item)} style={{position: 'absolute', marginTop: this.getDayPosition(item.data.timestamp), height: item.data.duration, width: width-10}}> 
            <p><strong>{lang.t('calendar:session.available')}{this.getSessionVirtual(item)}</strong> {this.getSessionCustomName2(item)} {Moment(item.data.timestamp, 'X').format('LT')} - {Cal.getDuration(item.data.duration)}{this.renderStatus(item)}</p>
            <div className="clear"></div>
          </div>
        ))}
      </div>
    )
  }


  getSessionsTrainer(trainer, starttime, endtime) {
    var date = Moment(this.state.selected, 'DD/MM/YYYY').format('DD/MM/YYYY')
    var array = []
    var start = Moment(date+' '+starttime, 'DD/MM/YYYY HH:mm')
    var end = Moment(date+' '+endtime, 'DD/MM/YYYY HH:mm')
    for(var item of this.state.sessionsAll) {
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') >= start && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < end) {
        if((item.data.trainerId === trainer || (trainer === global.uidUser && HelperBusiness.getRoleIncl('') && (item.data.trainerId === undefined || item.data.trainerId === ''))) || this.state.staff.length === 0) {
          array.push(item)
        }
      }
    }
    array.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));

    return (
      <div>
        {array.map(item => (
          <div className="cal-session" key={item.id} onClick={() => this.clickSession(item)}>
            {this.renderStatus(item)}
            <div className={'icon '+this.getSessionDayClass(item)[0]}>
              <div className={'inner '+this.getSessionDayClass(item)[1]}></div>
            </div>
            <p className="lft"><strong>{this.getSessionDayName(item)}{this.getSessionVirtual(item)}</strong><br/>{this.getSessionCustomName(item)}{Moment(item.data.timestamp, 'X').format('LT')} - {Cal.getDuration(item.data.duration)}{this.getSessionLocation(item)}</p>
            <div className="clear"></div>
          </div>
        ))}
      </div>
    )
  }


  tapNewSession(type, day, time) {
    global.currentDate = Moment().format('DD/MM/YYYY')
    global.currentTime = '05'
    if(type === 'week') {
      var dy = Moment(this.state.week, 'w').add(day, 'days').format('DD/MM/YYYY')
      if(Moment(dy, 'DD/MM/YYYY') < Moment()) {
        //dy = Moment(this.state.week, 'w').add(1, 'year').add(day, 'days').format('DD/MM/YYYY')
        dy = Moment(this.state.week, 'w').add(1, 'year').startOf('week').add(day, 'days').format('DD/MM/YYYY')
      }
      var dt = Moment(dy+' '+time+':00', 'DD/MM/YYYY HH:mm')
      if(dt > Moment()) {
        global.currentDate = dy
        global.currentTime = time
      }
    } else if(type === 'month') {
      var second = Moment().startOf('month').add(this.state.current-1, 'months').format('MM/YYYY')
      var dy2 = Moment(day+'/'+second, 'D/MM/YYYY').format('DD/MM/YYYY')
      var dt2 = Moment(dy2+' 05:00', 'DD/MM/YYYY HH:mm')
      if(dt2 > Moment()) {
        global.currentDate = dy2
      }
    } else if(type === 'day') {
      var dt3 = Moment(this.state.selected+' '+time+':00', 'DD/MM/YYYY HH:mm')
      if(dt3 > Moment()) {
        global.currentDate = this.state.selected
        global.currentTime = time
      }
    }
    this.props.history.push(this.state.newLink)
  }


  renderNewClass(type, day) {
    var label = 'cal-add'
    if(type === 'week') {
      var dy = Moment(this.state.week, 'w').add(day, 'days')
      if(dy < Moment().startOf('day') && dy > Moment().add(-6, 'months')) {
        label = 'none'
      }
    } else if(type === 'month') {
      var second = Moment().startOf('month').add(this.state.current-1, 'months').format('MM/YYYY')
      var dy2 = Moment(day+'/'+second, 'D/MM/YYYY')
      if(dy2 < Moment().startOf('day') && dy2 > Moment().add(-6, 'months')) {
        label = 'none'
      }
    } else if(type === 'day') {
      var dt3 = Moment(this.state.selected, 'DD/MM/YYYY')
      if(dt3 < Moment().startOf('day') && dt3 > Moment().add(-6, 'months')) {
        label = 'none'
      }
    }
    return label
  }



  // Display stuff ------------------------------------------------------------



  renderYourself() {
    if(HelperBusiness.getRole('assistant,assistant2,trainer')) {
      return (
        <option value={global.uidUser}>{lang.t('common:label.yourself')}</option>
      )
    }
  }


  renderSelectTrainers() {
    this.getAssistant()
    if(HelperBusiness.getRole('trainer,admin')) {
      return (
        <select className="light rgt ml-10" value={this.state.trainer} onChange={event => this.selectTrainer(event)}>
          {this.renderYourself()}
          <option value={global.uid}>{global.spaceOwner}</option>
          <option value=''>{lang.t('calendar:label.alltrainers')}</option>
        </select>
      )
    } else if(HelperBusiness.getRoleIncl('') && this.state.staff.length > 0) {
      return (
        <select className="light rgt ml-10" value={this.state.trainer} onChange={event => this.selectTrainer(event)}>
          <option value=''>{lang.t('calendar:label.alltrainers')}</option>
          <option value={global.uidUser}>{lang.t('common:label.yourself')}</option>
          {this.state.staff.map(item => (
            <option value={item.id} key={item.id}>{item.data.name}</option>
          ))}
        </select>
      )
    }
  }


  renderSelectClients() {
    var list = this.state.clients
    list.sort((a, b) => a.data.name.localeCompare(b.data.name))
    return (
      <select className="light rgt" onChange={event => this.setClientSessions(event.target.value)} value={this.state.currentClient}>
        <option value=''>{lang.t('calendar:label.allclients')}</option>
        {list.map(item => (
          <option key={item.id} value={item.id}>{item.data.name}</option>
        ))}
      </select>
    )
  }


  renderDay2Content() {
    return (
      <div className="calendar">
        <div className="weekday time">
          <div><h4>00:00</h4></div>
          <div><h4>01:00</h4></div>
          <div><h4>02:00</h4></div>
          <div><h4>03:00</h4></div>
          <div><h4>04:00</h4></div>
          <div><h4>05:00</h4></div>
          <div><h4>06:00</h4></div>
          <div><h4>07:00</h4></div>
          <div><h4>08:00</h4></div>
          <div><h4>09:00</h4></div>
          <div><h4>10:00</h4></div>
          <div><h4>11:00</h4></div>
          <div><h4>12:00</h4></div>
          <div><h4>13:00</h4></div>
          <div><h4>14:00</h4></div>
          <div><h4>15:00</h4></div>
          <div><h4>16:00</h4></div>
          <div><h4>17:00</h4></div>
          <div><h4>18:00</h4></div>
          <div><h4>19:00</h4></div>
          <div><h4>20:00</h4></div>
          <div><h4>21:00</h4></div>
          <div><h4>22:00</h4></div>
          <div><h4>23:00</h4></div>
        </div>
        <div className="weekday day2">
          {this.getSessionsDay2()}
        </div>
        <div className="clear"></div>
      </div>
    )
  }


  renderDay2() {
    if(this.state.view === 'day2') {
      return (
        <div>
          <div className="calmain">
            <div style={{minWidth: '1200px'}}>
              {this.renderRadioMonthDays()}
              <div className="sv-30"></div>
              {this.renderDay2Content()}
            </div>
          </div>
        </div>
      )
    }
  }


  renderDayContent() {
    if(this.state.staff.length === 0) {
      return (
        <div className="calendar">
          <div className="weekday full weekday cal-data" style={{width: '100%'}}>
            <h4><span>{lang.t('calendar:label.morning')}</span></h4>{this.getSessionsTrainer('', '00:00', '12:00')}
            <div className={this.renderNewClass('day', 0)} onClick={() => this.tapNewSession('day', 0, '05')}>{lang.t('calendar:button.newsession')} +</div>
          </div>
          <div className="clear"></div>

          <div className="column full weekday cal-data" style={{width: '100%'}}>
            <h4><span>{lang.t('calendar:label.midday')}</span></h4>{this.getSessionsTrainer('', '12:00', '14:00')}
            <div className={this.renderNewClass('day', 0)} onClick={() => this.tapNewSession('day', 0, '12')}>{lang.t('calendar:button.newsession')} +</div>
          </div>
          <div className="clear"></div>
          <div className="column full weekday cal-data" style={{width: '100%'}}>
            <h4><span>{lang.t('calendar:label.afternoon')}</span></h4>{this.getSessionsTrainer('', '14:00', '18:00')}
            <div className={this.renderNewClass('day', 0)} onClick={() => this.tapNewSession('day', 0, '14')}>{lang.t('calendar:button.newsession')} +</div>
          </div>
          <div className="clear"></div>
          <div className="column full weekday cal-data" style={{width: '100%'}}>
            <h4><span>{lang.t('calendar:label.evening')}</span></h4>{this.getSessionsTrainer('', '18:00', '23:59')}
            <div className={this.renderNewClass('day', 0)} onClick={() => this.tapNewSession('day', 0, '18')}>{lang.t('calendar:button.newsession')} +</div>
          </div>
          <div className="clear"></div>
        </div>
      )
    } else {
      var width = (100/(this.state.staff.length+1))+'%'
      return (
        <div className="calendar">
          <div className="weekday lft cal-top" style={{width: width}}>
            <h3>{global.userName} <span>{lang.t('common:label.yourself')}</span></h3>
          </div>
          {this.state.staff.map(item => (
            <div className="weekday lft cal-top" style={{width: width}}>
              <h3>{item.data.name}</h3>
            </div>
          ))}
          <div className="clear"></div>

          <div className="weekday lft cal-data" style={{width: width}}>
            <h4>{lang.t('calendar:label.morning')}</h4>
            {this.getSessionsTrainer(global.uidUser, '00:00', '12:00')}
            <div className={this.renderNewClass('day', 0)} onClick={() => this.tapNewSession('day', 0, '05')}>{lang.t('calendar:button.newsession')} +</div>
          </div>
          {this.state.staff.map(item => (
            <div className="weekday lft cal-data" style={{width: width}}>
              <h4>{lang.t('calendar:label.morning')}</h4>
              {this.getSessionsTrainer(item.id, '00:00', '12:00')}
              <div className={this.renderNewClass('day', 0)} onClick={() => this.tapNewSession('day', 0, '05')}>{lang.t('calendar:button.newsession')} +</div>
            </div>
          ))}
          <div className="clear"></div>

          <div className="weekday lft cal-data" style={{width: width}}>
            <h4>{lang.t('calendar:label.midday')}</h4>
            {this.getSessionsTrainer(global.uidUser, '12:00', '14:00')}
            <div className={this.renderNewClass('day', 0)} onClick={() => this.tapNewSession('day', 0, '12')}>{lang.t('calendar:button.newsession')} +</div>
          </div>
          {this.state.staff.map(item => (
            <div className="weekday lft cal-data" style={{width: width}}>
              <h4>{lang.t('calendar:label.midday')}</h4>
              {this.getSessionsTrainer(item.id, '12:00', '14:00')}
              <div className={this.renderNewClass('day', 0)} onClick={() => this.tapNewSession('day', 0, '12')}>{lang.t('calendar:button.newsession')} +</div>
            </div>
          ))}
          <div className="clear"></div>

          <div className="weekday lft cal-data" style={{width: width}}>
            <h4>{lang.t('calendar:label.afternoon')}</h4>
            {this.getSessionsTrainer(global.uidUser, '14:00', '18:00')}
            <div className={this.renderNewClass('day', 0)} onClick={() => this.tapNewSession('day', 0, '14')}>{lang.t('calendar:button.newsession')} +</div>
          </div>
          {this.state.staff.map(item => (
            <div className="weekday lft cal-data" style={{width: width}}>
              <h4>{lang.t('calendar:label.afternoon')}</h4>{this.getSessionsTrainer(item.id, '14:00', '18:00')}
              <div className={this.renderNewClass('day', 0)} onClick={() => this.tapNewSession('day', 0, '14')}>{lang.t('calendar:button.newsession')} +</div>
            </div>
          ))}
          <div className="clear"></div>

          <div className="weekday lft cal-data" style={{width: width}}>
            <h4>{lang.t('calendar:label.evening')}</h4>
            {this.getSessionsTrainer(global.uidUser, '18:00', '23:59')}
            <div className={this.renderNewClass('day', 0)} onClick={() => this.tapNewSession('day', 0, '18')}>{lang.t('calendar:button.newsession')} +</div>
          </div>
          {this.state.staff.map(item => (
            <div className="weekday lft cal-data" style={{width: width}}>
              <h4>{lang.t('calendar:label.evening')}</h4>
              {this.getSessionsTrainer(item.id, '18:00', '23:59')}
              <div className={this.renderNewClass('day', 0)} onClick={() => this.tapNewSession('day', 0, '18')}>{lang.t('calendar:button.newsession')} +</div>
            </div>
          ))}
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderDayName() {
    var label = Moment(this.state.selected, 'DD/MM/YYYY').format('ddd, D MMM YYYY')
    if(label === Moment().format('ddd, D MMM YYYY')) {
      label = lang.t('calendar:label.today')
    } else if(label === Moment().add(1, 'days').format('ddd, D MMM YYYY')) {
      label = lang.t('calendar:label.tomorrow')
    } else if(label === Moment().add(-1, 'days').format('ddd, D MMM YYYY')) {
      label = lang.t('calendar:label.yesterday')
    }
    return label
  }


  renderDay() {
    if(this.state.view === 'day') {
      return (
        <div>
          <div className="calmain">
            <div style={{minWidth: '1200px'}}>
              {this.renderRadioMonthDays()}
              <div className="sv-30"></div>
              {this.renderDayContent()}
            </div>
          </div>
        </div>
      )
    }
  }


  getWeekDate(num) {
    var label = '1 Jan'
    var week = Moment(this.state.selected, 'DD/MM/YYYY').startOf('week')
    label = Moment(week).add(num, 'days').format('D MMM')
    if(Moment(week).add(num, 'days').format('D MM YYYY') === Moment().format('D MM YYYY')) {
      label = lang.t('calendar:label.today')
    }
    return label
  }


  getDayClass(type, day) {
    var label = ''
    if(type === 'week') {
      var week = Moment(this.state.selected, 'DD/MM/YYYY').startOf('week')
      if(Moment(week).add(day, 'days').format('D MM YYYY') === Moment().format('D MM YYYY')) {
        label = 'today'
      }
    } else if(type === 'month') {
      var second = Moment().startOf('month').add(this.state.current-1, 'months').format('MM/YYYY')
      var dy2 = Moment(day+'/'+second, 'D/MM/YYYY').format('D MM YYYY')
      if(dy2 === Moment().format('D MM YYYY')) {
        label = 'today'
      }
    }
    return label
  }


  renderHeaderWeek() {
    var label = Moment(this.state.week, 'w').startOf('week').locale(lang.language).format('D MMM')+" - "+Moment(this.state.week, 'w').startOf('week').add(6, 'days').locale(lang.language).format('D MMM')
    var w =  parseInt(Moment('1 '+this.state.months[0], 'D MMM YYYY').startOf('week').locale(lang.language).format('w'))
    if(this.state.week < w) {
      label = Moment(this.state.week, 'w').startOf('week').add(52, 'weeks').locale(lang.language).format('D MMM')+" - "+Moment(this.state.week, 'w').startOf('week').add(52, 'weeks').add(6, 'days').locale(lang.language).format('D MMM')
    }
    return label
  }


  renderWeek() {
    if(this.state.view === 'week') {
      return (
        <div>
          <div className="calmain">
            <div style={{minWidth: '1200px'}}>
              {this.renderRadioWeeks()}
              <div className="sv-30"></div>
              <div className="calendar">
                <div className={'weekday lft cal-top '+this.getDayClass('week', 0)}><h3>{lang.t('calendar:label.mon')} <span>{this.getWeekDate(0)}</span></h3></div>
                <div className={'weekday lft cal-top '+this.getDayClass('week', 1)}><h3>{lang.t('calendar:label.tue')} <span>{this.getWeekDate(1)}</span></h3></div>
                <div className={'weekday lft cal-top '+this.getDayClass('week', 2)}><h3>{lang.t('calendar:label.wed')} <span>{this.getWeekDate(2)}</span></h3></div>
                <div className={'weekday lft cal-top '+this.getDayClass('week', 3)}><h3>{lang.t('calendar:label.thu')} <span>{this.getWeekDate(3)}</span></h3></div>
                <div className={'weekday lft cal-top '+this.getDayClass('week', 4)}><h3>{lang.t('calendar:label.fri')} <span>{this.getWeekDate(4)}</span></h3></div>
                <div className={'weekday lft cal-top '+this.getDayClass('week', 5)}><h3>{lang.t('calendar:label.sat')} <span>{this.getWeekDate(5)}</span></h3></div>
                <div className={'weekday lft cal-top '+this.getDayClass('week', 6)}><h3>{lang.t('calendar:label.sun')} <span>{this.getWeekDate(6)}</span></h3></div>
                <div className="clear"></div>

                <div style={{display: 'flex'}}>
                  <div className={'weekday cal-data '+this.getDayClass('week', 0)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(0, '00:00', '12:00')}
                    <div className={this.renderNewClass('week', 0)} onClick={() => this.tapNewSession('week', 0, '05')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 1)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(1, '00:00', '12:00')}
                    <div className={this.renderNewClass('week', 1)} onClick={() => this.tapNewSession('week', 1, '05')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 2)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(2, '00:00', '12:00')}
                    <div className={this.renderNewClass('week', 2)} onClick={() => this.tapNewSession('week', 2, '05')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 3)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(3, '00:00', '12:00')}
                    <div className={this.renderNewClass('week', 3)} onClick={() => this.tapNewSession('week', 3, '05')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 4)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(4, '00:00', '12:00')}
                    <div className={this.renderNewClass('week', 4)} onClick={() => this.tapNewSession('week', 4, '05')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 5)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(5, '00:00', '12:00')}
                    <div className={this.renderNewClass('week', 5)} onClick={() => this.tapNewSession('week', 5, '05')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 6)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(6, '00:00', '12:00')}
                    <div className={this.renderNewClass('week', 6)} onClick={() => this.tapNewSession('week', 6, '05')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                </div>
                <div className="clear"></div>

                <div style={{display: 'flex'}}>
                  <div className={'weekday cal-data '+this.getDayClass('week', 0)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(0, '12:00', '14:00')}
                    <div className={this.renderNewClass('week', 0)} onClick={() => this.tapNewSession('week', 0, '12')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 1)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(1, '12:00', '14:00')}
                    <div className={this.renderNewClass('week', 1)} onClick={() => this.tapNewSession('week', 1, '12')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 2)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(2, '12:00', '14:00')}
                    <div className={this.renderNewClass('week', 2)} onClick={() => this.tapNewSession('week', 2, '12')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 3)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(3, '12:00', '14:00')}
                    <div className={this.renderNewClass('week', 3)} onClick={() => this.tapNewSession('week', 3, '12')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 4)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(4, '12:00', '14:00')}
                    <div className={this.renderNewClass('week', 4)} onClick={() => this.tapNewSession('week', 4, '12')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 5)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(5, '12:00', '14:00')}
                    <div className={this.renderNewClass('week', 5)} onClick={() => this.tapNewSession('week', 5, '12')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 6)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(6, '12:00', '14:00')}
                    <div className={this.renderNewClass('week', 6)} onClick={() => this.tapNewSession('week', 6, '12')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                </div>
                <div className="clear"></div>

                <div style={{display: 'flex'}}>
                  <div className={'weekday cal-data '+this.getDayClass('week', 0)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(0, '14:00', '18:00')}
                    <div className={this.renderNewClass('week', 0)} onClick={() => this.tapNewSession('week', 0, '14')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 1)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(1, '14:00', '18:00')}
                    <div className={this.renderNewClass('week', 1)} onClick={() => this.tapNewSession('week', 1, '14')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 2)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(2, '14:00', '18:00')}
                    <div className={this.renderNewClass('week', 2)} onClick={() => this.tapNewSession('week', 2, '14')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 3)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(3, '14:00', '18:00')}
                    <div className={this.renderNewClass('week', 3)} onClick={() => this.tapNewSession('week', 3, '14')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 4)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(4, '14:00', '18:00')}
                    <div className={this.renderNewClass('week', 4)} onClick={() => this.tapNewSession('week', 4, '14')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 5)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(5, '14:00', '18:00')}
                    <div className={this.renderNewClass('week', 5)} onClick={() => this.tapNewSession('week', 5, '14')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 6)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(6, '14:00', '18:00')}
                    <div className={this.renderNewClass('week', 6)} onClick={() => this.tapNewSession('week', 6, '14')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                </div>
                <div className="clear"></div>

                <div style={{display: 'flex'}}>
                  <div className={'weekday cal-data bottom '+this.getDayClass('week', 0)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(0, '18:00', '23:59')}
                    <div className={this.renderNewClass('week', 0)} onClick={() => this.tapNewSession('week', 0, '18')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data bottom '+this.getDayClass('week', 1)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(1, '18:00', '23:59')}
                    <div className={this.renderNewClass('week', 1)} onClick={() => this.tapNewSession('week', 1, '18')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data bottom '+this.getDayClass('week', 2)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(2, '18:00', '23:59')}
                    <div className={this.renderNewClass('week', 2)} onClick={() => this.tapNewSession('week', 2, '18')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data bottom '+this.getDayClass('week', 3)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(3, '18:00', '23:59')}
                    <div className={this.renderNewClass('week', 3)} onClick={() => this.tapNewSession('week', 3, '18')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data bottom '+this.getDayClass('week', 4)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(4, '18:00', '23:59')}
                    <div className={this.renderNewClass('week', 4)} onClick={() => this.tapNewSession('week', 4, '18')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data bottom '+this.getDayClass('week', 5)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(5, '18:00', '23:59')}
                    <div className={this.renderNewClass('week', 5)} onClick={() => this.tapNewSession('week', 5, '18')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                  <div className={'weekday cal-data bottom '+this.getDayClass('week', 6)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(6, '18:00', '23:59')}
                    <div className={this.renderNewClass('week', 6)} onClick={() => this.tapNewSession('week', 6, '18')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                </div>
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }


  getDayWeekday(index) {
    var month = Moment().add((this.state.current-1), 'months').format('MM/YYYY')
    var label = Moment(index+'/'+month, 'D/M/YYYY').locale(lang.language).format('ddd')
    return label
  }


  renderMonth() {
    if(this.state.view === 'month') {
      return (
        <div>
          <div className="calmain">
            <div style={{minWidth: '1200px'}}>
              <div className="radios">
                <button className={this.state.current === 0 ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setMonth(0)}>{this.state.months[0]}</button>
                <button className={this.state.current === 1 ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setMonth(1)}>{this.state.months[1]}</button>
                <button className={this.state.current === 2 ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setMonth(2)}>{this.state.months[2]}</button>
                <button className={this.state.current === 3 ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setMonth(3)}>{this.state.months[3]}</button>
              </div>
              <div className="sv-30"></div>
              <div className="calendar">
                {this.state.empty.map((item, index) => (
                  <div className="weekday lft" key={item}>&nbsp;</div>
                ))}
                {this.state.days.map((item, index) => (
                  <div className={'weekday lft cal-data '+this.getDayClass('month', item.day)+item.clear} key={item}>
                    <h3 className="mb-10">{item.day} <span>{this.getDayWeekday(item.day)}</span></h3>
                    {this.getSessions(item.day)}
                    <div className={this.renderNewClass('month', item.day)} onClick={() => this.tapNewSession('month', item.day, '05')}>{lang.t('calendar:button.newsession')} +</div>
                  </div>
                ))}
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }


  renderDayViewSelector() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <span>
          <div title={lang.t('calendar:view.day')} className={this.state.view === 'day' ? 'icontoggle list active lft' : 'icontoggle list lft'} onClick={() => this.setView('day')}></div>
          <div title={lang.t('calendar:view.day2')} className={this.state.view === 'day2' ? 'icontoggle day active lft' : 'icontoggle day lft'} onClick={() => this.setView('day2')}></div>
        </span>
      )
    }
  }


  renderListHeader() {
    if(this.state.view === 'week') {
      return <h3 className="lft">{lang.t('calendar:label.week')} {this.state.week}: {this.renderHeaderWeek()}</h3>
    } else if(this.state.view === 'month') {
      return <h3 className="lft">{Moment(this.state.selected, 'DD/MM/YYYY').locale(lang.language).format('MMMM YYYY')}</h3>
    } else if(this.state.view === 'day' || this.state.view === 'day2') {
      return <h3 className="lft">{this.renderDayName()}</h3>
    }
  }


  renderContent() {
    return (
      <div className="clear col-12">
        <div className="listheader">
          <div className="pt-10 lft">
          {this.renderListHeader()}
          </div>
          
          <div className="toggles ml-30">
            <div title={lang.t('calendar:view.month')} className={this.state.view === 'month' ? 'icontoggle month active lft' : 'icontoggle month lft'} onClick={() => this.setView('month')}></div>
            <div title={lang.t('calendar:view.week')} className={this.state.view === 'week' ? 'icontoggle week active lft' : 'icontoggle week lft'} onClick={() => this.setView('week')}></div>
            {this.renderDayViewSelector()}
          </div>
          {this.renderSelectTrainers()}
          {this.renderSelectClients()}
          
          <div className="clear"></div>
        </div>
        {this.renderMonth()}
        {this.renderWeek()}
        {this.renderDay()}
        {this.renderDay2()}
        <div className="box none">
          {this.renderMonth()}
          {this.renderWeek()}
          {this.renderDay()}
          {this.renderDay2()}
        </div>
        <div className="sv-20"></div>
      </div>
    )
  }


  renderActions() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      var item1 = {id: 'calgroup', link: ROUTES.NEWGROUP}
      var item2 = {id: 'cal11', link: ROUTES.NEWSESSION}
      var item3 = {id: 'calevent', link: ROUTES.NEWEVENT}
      if(HelperBusiness.getRole('trainer')) {
        item3 = {id: '', link: ''}
      } else if(HelperBusiness.getRole('admin')) {
        item1 = {id: 'report', link: ROUTES.CALREPORT}
        item2 = {id: '', link: ''}
        item3 = {id: '', link: ''}
      }
      return (
        <WidgetActions
          item1={item1} clickItem1={() => this.setState({showModalLocked: true})}
          item2={item2} clickItem2={() => this.setState({showModalLocked: true})}
          item3={item3} clickItem3={() => this.setState({showModalLocked: true})}
        />
      )
    }
  }


  renderOverview() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <div className="col-9">
          <WidgetOverview
            item1={{id: 'calgroup', value: Cal.getAmount(this.state.sessions, this.state.archive, 'group', this.state.dateRange), prev: Cal.getDifference(this.state.sessions, true, this.state.dateRange, 'num')}}
            item2={{id: 'cal11', value: Cal.getAmount(this.state.sessions, this.state.archive, 'sessions', this.state.dateRange), prev: Cal.getDifference(this.state.sessions, false, this.state.dateRange, 'num')}} 
            item3={{id: 'caltotal', value: this.getActiveClients(), prev: this.getActiveDifference('num')}}
            item4={{id: '', value: ''}}
          />
        </div>
      )
    }
  }


  renderHeader() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      return (
        <Header type='calendar' active='calendar' selector={true} updateSelect={() => this.setDateRange()}/>
      )
    } else {
      return (
        <Header type='simple' title={lang.t('nav:main.calendar')}/>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className={HelperBusiness.getRole('assistant,assistant2') ? 'content' : 'content withtabs'}>
          {this.renderOverview()}
          {this.renderActions()}
          {this.renderContent()}
          <Link to={ROUTES.CALARCHIVE} className="btn tertiary small width-12 clear">{lang.t('calendar:button.viewarchive')}</Link>
          <div className="sv-40"></div>
        </div>
        {this.renderHeader()}
        <Navigation type='calendar' active='calendar' selector={true} updateSelect={() => this.setDateRange()}/>
        {this.renderOverlayNextStep()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }

}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common','overview','nav','modals','messaging'])(withRouter(withAuthorization(condition)(CalendarPage)));