import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Resizer from "react-image-file-resizer";
import HelperClients from '../../helper/Clients';
import HelperBus from '../../helper/Business';
import HelperEmpty from '../../helper/Empty';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/clientdetails';
import InfoData from '../../components/Form/info';
import InputRadio from '../../components/Form/radio';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalMessageDouble from '../../components/Modals/message-double';
import ModalAvatar from '../../components/Modals/avatar';
import ModalItems from '../../components/Modals/items';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ClientsDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      name: 'Client',
      editing: false,
      values: ['', '', '', '', '', ''],
      valuesEC: ['', ''],
      typeEC: 99,
      file: null,
      fileDisplay: null,
      error: [false, false, false],
      showModalClient: false,
      hiddenClient: 'hidden',
      message: '',
      valid: true,
      paid: 'Unpaid',
      doc: '',
      dfile: null,
      dvalue: '',
      derror: [false, false],
      ddate: Moment().format('X'),
      dlink: '',
      margin: 0,
      showModalHistory: false,
      hiddenHistory: 'hidden',
      histMode: 'list',
      histItem: '',
      histValues: ['', ''],
      histImportant: false,
      showModalGoal: false,
      hiddenGoal: 'hidden',
      goalMode: '',
      goalValue: '',
      showModalForms: false,
      hiddenForms: 'hidden',
      showModalDocument: false,
      hiddenDocument: 'hidden',
      active: true,
      showOptions: false,
      more: '',
      showModalReset: false,
      hiddenReset: 'hidden',
      fieldReset: ['', ''],
      showModalMerge: false,
      hiddenMerge: 'hidden',
      clients: global.clients,
      selected: '',
      suid: '',
      merge: 0,
      showModal: false,
      hidden: 'hidden',
      showModalLink: false,
      hiddenLink: 'hidden',
      restricted: false,
      errorLinked: [false, false],
      errorReset: false,
      showAll: false,
      imageType: '',
      newimage: false,
      avatar: '',
      locations: [],
      showModalFamily: false,
      showModalActive: false,
      showModalConfirm: false,
      showModalMessage: false,
      showModalAvatar: false,
      showModalLocations: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Details - Client Details - PT Mate', global.currentPage)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('formsLoaded', (event) => { this.getClient() })
    EventEmitter.subscribe('clientsLoaded', (event) => {
      this.getEmpty()
      if(this.state.deletingBilling) {
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.billingdetailsdeleted'));
        global.message = ''
        this.setState({
          deletingBilling: false
        })
      }
    })
    EventEmitter.subscribe('imageLoaded', (event) => this.getClient())
    EventEmitter.subscribe('locationsLoaded', (event) => { this.getClient() })
    this.getEmpty()
    
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getEmpty() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      global.currentClient = arr[arr.length-2]
      this.getClient()
    }
  }


  getClient() {
    if(this._isMounted) {
      var id = ''
      var item = null
      var name = ''
      var birth = ''
      var ecn = ''
      var ecp = ''
      var ect = 99
      for(var client of global.clients) {
        if(client.id === global.currentClient) {
          if(client.data.birth !== '01/01/1900') {
            birth = client.data.birth
          }
          id = client.id
          item = client
          name = client.data.name
        }
      }

      if(item !== null) {
        if(item.data.ecName !== undefined) {
          ecn = item.data.ecName
        }
        if(item.data.ecPhone !== undefined) {
          ecp = item.data.ecPhone
        }
        if(item.data.ecType !== undefined) {
          ect = item.data.ecType
        }
        var height = item.data.height
        if(global.userLbs && item.data.height !== 0) {
          var h = item.data.height/2.54
          var h1 = parseInt(h/12)
          var h2 = (h-(h1*12)).toFixed(0)
          height = h1+"."+h2
        }
        var avatar = ''
        var itype = ''
        if(item.data.avatar !== undefined && item.data.avatar !== '') {
          avatar = item.data.avatar
          itype = 'avatar'
        }
        if(item.data.image !== '') {
          itype = 'image'
        }
        var locations = []
        for(var loc of global.locations) {
          if(loc.data.clients !== undefined) {
            if(loc.data.clients.indexOf(id) !== -1) {
              locations.push(loc)
            }
          }
        }

        global.linkCli = '/clients/'+id+'/details'
        this.setState({
          id: id,
          item: item,
          name: name,
          values: [item.data.name, item.data.phone, item.data.email, birth, height, item.data.goal],
          valuesEC: [ecn, ecp],
          typeEC: ect,
          active: true,
          avatar: avatar,
          imageType: itype,
          clients: global.clients,
          locations: locations,
        }, () => {
          this.configureData()
          global.linkCli = '/clients/'+id+'/details';
        });
      } else {
        this.getClientInactive()
      }
    }
  }


  getClientInactive() {
    var id = ''
    var item = null
    var name = ''
    var birth = ''
    for(var client of global.clientsInactive) {
      if(client.id === global.currentClient) {
        
        if(client.data.birth !== '01/01/1900') {
          birth = client.data.birth
        }
        id = client.id
        item = client
        name = client.data.name
      }
    }

    if(item !== null) {
      this.setState({
      id: id,
      item: item,
      name: name,
      values: [item.data.name, item.data.phone, item.data.email, birth, item.data.height, item.data.goal],
      active: false
    }, () => {
      this.configureData()
      global.linkCli = '/clients/'+id+'/details';
    });
    }
  }


  configureData() {
    
  }


  getClientLinked(id) {
    var client = null
    for(var item of global.clients) {
      if(item.id === id) {
        client = item
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        client = item2
      }
    }
    if(client === null) {
      client = {
        id: id,
        data: {
          name: 'Deleted client',
          restricted: false,
          avatar: '',
          image: ''
        }
      }
    }
    return client
  }



  clickEditing() {
    if(this.state.item.data.uid === '') {
      this.setState({editing: true})
    } else {
      this.showModalClient()
    }
  }


  getEmergency() {
    var label = '-'
    if(this.state.item.data.ecName !== undefined && this.state.item.data.ecName !== '') {
      label = this.state.item.data.ecName
    }
    return label
  }


  getEmergencyPhone() {
    var label = ''
    var types = [lang.t('clients:form.spouse'), lang.t('clients:form.family'), lang.t('clients:form.friend')]
    if(this.state.item.data.ecName !== undefined && this.state.item.data.ecName !== '') {
      if(this.state.item.data.ecType !== undefined && this.state.item.data.ecType !== 99) {
        label = types[this.state.item.data.ecType]+' - '
      }
      if(this.state.item.data.ecPhone !== undefined && this.state.item.data.ecPhone !== '') {
        label += this.state.item.data.ecPhone
      }
    }
    return label
  }


  getFormInfo(form, type) {
    var label = 'grey'
    if(type === 'label') {
      label = '- '+lang.t('clients:label.notcompletedyet2')+' -'
    }
    if(form.data.date !== undefined) {
      if(Moment(form.data.date, 'X') > Moment('01/01/2000', 'DD/MM/YYYY')) {
        label = ' green'
        if(type === 'label') {
          label = lang.t('clients:label.completed2')+' '+Moment(form.data.date, 'X').locale(lang.language).format('D MMM YYYY')
        }
      }
    }
    return label
  }


  getDocClass(item) {
    var label = 'icon primary'
    var icon = 'inner doc'
    if(item === 'pdf') {
      label = 'icon red'
      icon = 'inner pdf'
    } else if(item === 'doc' || item === 'docx') {
      label = 'icon secondary'
      icon = 'inner docx'
    } else if(item === 'jpg' || item === 'png' || item === 'jpeg' || item === 'gif') {
      label = 'icon quarternary'
      icon = 'inner img'
    }
    return [label, icon]
  }



  // Client & Payment modal stuff ------------------------------------------------------------



  showModalClient() {
    this.setState({showModalClient: true})
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenClient: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalClient() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenClient: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({
        showModalClient: false, 
      });
    }, 500);
  }


  renderModalContent() {
    if(this.state.item.data.uid === '') {
      return (
        <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
          <h2 className="mb-20 lft">{lang.t('modals:deleteclient.title')}</h2>
          <button className="btn tertiary small close rgt" onClick={() => this.hideModalClient()}>{lang.t('common:button.close')}</button>
          <p className="clear">{lang.t('modals:deleteclient.text1')} {this.state.item.data.name} {lang.t('modals:deleteclient.text2')}<br/><br/>{global.userNutritionId === '' ? '' : lang.t('modals:deleteclient.text3')}</p>
          <div className="sv-30"></div>
          <button className="btn primary" onClick={() => this.deleteClient()}>{lang.t('modals:deleteclient.button')}</button>
        </div>
      )
    } else {
      return (
        <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
          <h2 className="mb-20 lft">{lang.t('modals:deleteclient.title.disconnect')}</h2>
          <button className="btn tertiary small close rgt" onClick={() => this.hideModalClient()}>{lang.t('common:button.close')}</button>
          <p className="clear">{lang.t('modals:deleteclient.text1.disconnect')} {this.state.item.data.name}? {lang.t('modals:deleteclient.text2.disconnect')}<br/><br/>{global.userNutritionId === '' ? '' : lang.t('modals:deleteclient.text3')}</p>
          <div className="sv-30"></div>
          <button className="btn primary" onClick={() => this.deleteClient()}>{lang.t('modals:deleteclient.button.disconnect1')}</button>
          <div className="sv-20"></div>
          <button className="btn tertiary close width-12" style={{float: 'none'}} onClick={() => this.disconnectClient()}>{lang.t('modals:deleteclient.button.disconnect2')}</button>
        </div>
      )
    }
  }


  renderModalClient() {
    if(this.state.showModalClient) {
      return (
        <div className={'overlay '+this.state.hiddenClient}   >
          {this.renderModalContent()}
        </div>
      )
    }
  }



  // Forms modal ------------------------------------------------------------



  showModalForms(type) {
    this.setState({
      showModalForms: true,
    })
    setTimeout(() => {
      this.setState({
        hiddenForms: '',
        selected: [],
      });
    }, 100);
  }


  hideModalForms() {
    this.setState({
      hiddenForms: 'hidden',
    })
    setTimeout(() => {
      this.setState({
        showModalForms: false,
      });
    }, 500);
  }


  renderFormsContent() {
    if(global.forms.length === 0) {
      if(this.state.clients.length === 0) {
        return (
          <p className="empty">{lang.t('modals:forms.empty')}</p>
        )
      } else {
        return (
          <p className="empty">{lang.t('modals:forms.empty2')}</p>
        )
      }
      
    } else {
      var list = global.forms
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className="scroll">
          {list.map(item => (
            <div className="list bare" onClick={() => this.sendForm(item)} key={item.id}>
              <div className="icon quarternary">
                <div className="inner form"></div>
              </div>
              <div className="main">
                <h4>{item.data.name}</h4>
                <p>{this.getFormInfoModal(item, 'line1', '')}<br/>{this.getFormInfoModal(item, 'line2')}</p>
              </div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderFormsButton() {
    if(global.forms.length > 0) {
      return (
        <p className="center">{lang.t('modals:forms.text')}</p>
      )
    }
  }


  renderModalForms() {
    if(this.state.showModalForms) {
      return (
        <div className={'overlay '+this.state.hiddenForms}>
          <div className="box clients">
            <h2 className="mb-20 lft">{lang.t('modals:forms.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalForms()}>{lang.t('common:button.close')}</button>
            <div className="clear"></div>
            {this.renderFormsContent()}
            {this.renderFormsButton()}
          </div>
        </div>
      )
    }
  }


  getFormInfoModal(form, line) {
    var label = HelperClients.getForm(this.state.item, form.id, 'date', form.data.pre, '')
    if(line === 'line2') {
      label = HelperClients.getForm(this.state.item, form.id, 'version', form.data.pre, '')
    }
    return label
  }


  sendForm(form) {
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/forms/'+form.id).update(
      form.data
    ).then((data)=>{
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.formsent'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    this.hideModalForms()
  }



  // Goal modal ------------------------------------------------------------



  showModalGoal(type) {
    var tmp = this.state.values[5]
    if(type === 'name') {
      tmp = this.state.values[0]
    }
    this.setState({
      showModalGoal: true,
      goalMode: type,
      goalValue: tmp

    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenGoal: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalGoal() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenGoal: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({
        showModalGoal: false, 
      });
    }, 500);
  }


  renderModalGoal() {
    if(this.state.showModalGoal) {
      if(this.state.goalMode === 'ec') {
        return (
          <div className={'overlay '+this.state.hiddenGoal}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="lft mb-20">{lang.t('modals:ec.title')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hideModalGoal()}>{lang.t('common:button.close')}</button>
              <div className="clear form-item">
                <label>{lang.t('modals:ec.name')}</label>
                <input type="text" placeholder={lang.t('clients:form.ecname.placeholder')} value={this.state.valuesEC[0]} name="0" onChange={this.onChangeEC}/>
              </div>
              <div className="form-item">
                <label>{lang.t('modals:ec.phone')}</label>
                <input type="number" placeholder={lang.t('clients:form.ecphone.placeholder')} value={this.state.valuesEC[1]} name="1" onChange={this.onChangeEC}/>
              </div>
              <div className="sv-20"></div>
              <label>{lang.t('modals:ec.relationship')}</label>
              <div className="radios">
                <button className={this.state.typeEC === 0 ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({typeEC: 0})}>{lang.t('modals:ec.relationship.label1')}</button>
                <button className={this.state.typeEC === 1 ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({typeEC: 1})}>{lang.t('modals:ec.relationship.label2')}</button>
                <button className={this.state.typeEC === 2 ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({typeEC: 2})}>{lang.t('modals:ec.relationship.label3')}</button>
              </div>
              <div className="sv-40 clear"></div>
              <button className="btn primary" onClick={() => this.updateEC()}>{lang.t('modals:ec.button')}</button>
            </div>
          </div>
        )
      } else {
        return (
          <div className={'overlay '+this.state.hiddenGoal}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{this.state.goalMode === 'goal' ? lang.t('modals:ec.editfocus') : lang.t('modals:ec.editname')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hideModalGoal()}>{lang.t('common:button.close')}</button>
              <div className="clear form-item">
                <label>{this.state.goalMode === 'goal' ? lang.t('modals:ec.trainingfocus') : lang.t('modals:ec.name')}</label>
                <input type="text" placeholder={this.state.goalMode === 'goal' ? lang.t('modals:ec.trainingfocus.label') : lang.t('modals:ec.name.label')} value={this.state.goalValue} onChange={this.onChangeGoal}/>
              </div>
              <div className="sv-20"></div>
              <button className="btn primary" onClick={() => this.updateGoal()}>{this.state.goalMode === 'goal' ? lang.t('modals:ec.button.updatefocus') : lang.t('modals:ec.button.updatename')}</button>
            </div>
          </div>
        )
      }
      
    }
  }


  onChangeGoal = event => {
    this.setState({goalValue: event.target.value});
  };


  updateGoal() {
    if(this.state.goalMode === 'goal') {
      this.hideModalGoal()
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id).update({
        goal: this.state.goalValue,
      }).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('clients:message.trainingfocusupdated'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else if(this.state.goalMode === 'name' && this.state.goalValue !== '') {
      this.hideModalGoal()
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id).update({
        name: this.state.goalValue,
      }).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('clients:message.trainingfocusupdated'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  updateEC() {
    this.hideModalGoal()
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id).update({
      ecName: this.state.valuesEC[0],
      ecPhone: this.state.valuesEC[1],
      ecType: this.state.typeEC,
    }).then(() => {
      this.setState({editing: false})
      EventEmitter.dispatch('showMessage', lang.t('clients:message.ecupdated'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    if(this.state.item.data.uid !== '') {
      Firebase.database().ref('/usersClients/'+this.state.item.data.uid).update({
        ecName: this.state.valuesEC[0],
        ecPhone: this.state.valuesEC[1],
        ecType: this.state.typeEC,
      })
    }
  }



  // Client update stuff ------------------------------------------------------------



  onChangeClient = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({values: tmp});
  };


  onChangeEC = event => {
    var tmp = this.state.valuesEC
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({valuesEC: tmp});
  };


  onChangeDate = event => {
    var tmp = this.state.values
    var valid = false
    tmp[3] = Moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    valid = Moment(event.target.value, 'YYYY-MM-DD').isValid()
    this.setState({
      values: tmp,
      valid: valid
    });
  };


  setImage(file) {
    this.setState({
      file: file,
      fileDisplay:  URL.createObjectURL(file),
      newimage: true,
    })
  }


  setAvatar(item) {
    this.setState({
      avatar: item,
      newimage: true,
    })
    this.hideModals()
  }


  displayImage() {
    if(this.state.file === null) {
      if(this.state.imageType === 'avatar') {
        return (
          <div className={'avatar '+this.state.avatar}></div>
        )
      } else if(this.state.imageType === '') {
        return <div className="avatar nophoto"></div>
      } else {
        return (
          HelperClients.getImageEdit(this.state.item)
        )
      }
      
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.fileDisplay+')'}}> </div>
      )
    }
  }


  checkUpdate() {
    this.setState({error: [false, false]})
    var tmp = [false, false]
    if(this.state.values[0] === '') {
      tmp[0] = true
    }
    if(this.state.values[1] === '') {
      tmp[1] = true
    }
    if(this.state.values[2] === '') {
      tmp[2] = true
    }
    this.setState({error: tmp})
    if(!tmp[0] && !tmp[1] && !tmp[2]) {
      this.updateDetails()
    }
  }


  updateDetails() {
    var birth = '01/01/1900'
    var height = 0
    if(this.state.values[3] !== '' && this.state.valid) {
      birth = this.state.values[3]
    }
    if(this.state.values[4] !== '' && this.state.values[4] !== undefined) {
      height = parseInt(this.state.values[4])
      if(global.userLbs) {
        var ar = ["0"]
        if(this.state.values[4] !== "" && this.state.values[4] !== undefined) {
          ar = String(this.state.values[4]).split(".")
        }
        var h1 = parseInt((ar[0])*12)*2.54
        var h2 = 0
        if(ar.length > 1) {
          h2 = parseInt(ar[1])*2.54
        }
        height = parseInt(h1+h2)
      }
    }

    var phone = this.state.values[1].replace('.' ,'').replace('-' ,'').replace(' ' ,'').replace('e' ,'')

    var avatar = ''
    if(this.state.item.data.avatar !== undefined) {
      avatar = this.state.item.data.avatar
    }
    if(this.state.imageType === 'avatar' && this.state.newimage) {
      avatar = this.state.avatar
    }


    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id).update({
      name: this.state.values[0],
      phone: phone,
      email: this.state.values[2],
      birth: birth,
      height: height,
      avatar: avatar,
      goal: this.state.values[5],
      ecName: this.state.valuesEC[0],
      ecPhone: this.state.valuesEC[1],
      ecType: this.state.typeEC,
    }).then(() => {
      this.setState({editing: false})
      EventEmitter.dispatch('showMessage', lang.t('clients:message.clientupdated'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    if(this.state.file !== null && this.state.imageType === 'image' && this.state.newimage) {
      const sr = Firebase.storage().ref()
      const fr = sr.child('images/clients/'+global.uid+'/'+this.state.id+'.jpg')
      Resizer.imageFileResizer(
        this.state.file,
        800,
        800,
        "JPEG",
        70,
        0,
        (uri) => {
          var ar = uri.split(',')
          fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
            Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id).update({
              image: 'images/clients/'+global.uid+'/'+this.state.id+'.jpg',
            })
          }).catch((error)=>{
            EventEmitter.dispatch('showMessageError', error.message);
          })
        },
        "base64",
        200,
        200
      );
    }
    if(this.state.imageType === '') {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id).update({
        avatar: '',
        image: ''
      })
    }
  }


  cancelEdit() {
    var birth = ''
    if(this.state.item.data.birth !== '01/01/1900') {
      birth = this.state.item.data.birth
    }
    var height = this.state.item.data.height
    if(global.userLbs) {
      var h = this.state.item.data.height/2.54
      var h1 = parseInt(h/12)
      var h2 = (h-(h1*12)).toFixed(0)
      height = h1+"."+h2
    }
    this.setState({
      values: [this.state.item.data.name, this.state.item.data.phone, this.state.item.data.email, birth, height, this.state.item.data.goal],
      file: null,
      fileDisplay: null,
      editing: false,
    })
  }


  deleteClient() {
    this.hideModalClient()
    if(this.state.item.data.customer !== undefined && this.state.item.data.customer !== '') {
      // Remove customer
      var callFunction = Firebase.functions().httpsCallable('connectedDeleteCustomerV2');
      callFunction({type: "account", account: global.userStripeConnect, customer: this.state.item.data.customer, uid: global.uid}).then(function(result) {
        //var sanitizedMessage = result.data.text;
      });
    }
    if(this.state.item.data.uid !== '') {
      var duplicate = false
      var cid = ''
      for(var client of global.clients) {
        if(client.data.uid === this.state.item.data.uid && client.id !== this.state.item.id) {
          duplicate = true
          cid = client.id
        }
      }
      if(duplicate) {
        Firebase.database().ref('/usersClients/'+this.state.item.data.uid+'/trainers/'+global.uid).update({
          client: cid
        })
      } else {
        Firebase.database().ref('/usersClients/'+this.state.item.data.uid+'/trainers/'+global.uid).remove()
      }
      Firebase.database().ref('/messaging/'+global.uid+this.state.item.data.uid).remove()
    } else {
      // Check if family profile
      if(this.state.item.data.parent !== undefined && this.state.item.data.parent !== '') {
        for(var cl of global.clients) {
          if(cl.id === this.state.item.data.parent) {
            var tmp = cl.data.linked
            for(var i=0; i<tmp.length; i++) {
              if(tmp[i] === this.state.item.id) {
                tmp.splice(i, 1)
              }
            }
            Firebase.database().ref('/clients/'+global.uid+'/'+cl.id).update({
              linked: tmp
            })
          }
        } 
      }
    }
    for(var session of global.sessions) {
      if(session.data.client === this.state.item.id && Moment(session.data.date, 'DD/MM/YYYY HH:mm') > Moment()) {
        Firebase.database().ref('/sessions/'+global.uid+'/'+session.id).remove()
      }
    }
    for(var rec of global.recurring) {
      if(rec.data.client === this.state.item.id) {
        Firebase.database().ref('/recurring/'+global.uid+'/'+session.id).remove()
      }
    }
    if(this.state.item.data.uid !== '' && +this.state.item.data.uid !== undefined) {
      Firebase.database().ref('/messaging/'+global.uid+this.state.item.data.uid).remove()
      Firebase.database().ref('/usersClients/'+this.state.item.data.uid+'/trainers/'+global.uid).remove()
    }
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
      deleted: true,
      uid: "",
      cardBrand: "",
      cardLast4: "",
      cardId: "",
      customer: "",
    })
    if(this.state.item.data.linked !== undefined) {
      for(var link of this.state.item.data.linked) {
        Firebase.database().ref('/clients/'+global.uid+'/'+link+'/parent').remove()
      }
    }
    for(var pl of global.plans) {
      if(pl.data.sent !== undefined && this.state.item.data.uid !== undefined && this.state.item.data.uid !== '') {
        if(pl.data.sent.indexOf(this.state.item.id) !== -1) {
          var sent = pl.data.sent
          for(var i=sent.length-1; i>-1; i--) {
            if(sent[i] === this.state.item.id) {
              sent.splice(i, 1)
            }
          }
          Firebase.database().ref('/plans/'+this.state.item.data.uid+'/'+pl.id).update({
            sent: sent
          })
        }
      }
    }
    global.linkCli = '';
    global.message = lang.t('messaging:message.clientdeleted')
    this.props.history.push(global.currentPage)
  }


  disconnectClient() {
    this.hideModalClient()
    var duplicate = false
    var cid = ''
    for(var client of global.clients) {
      if(client.data.uid === this.state.item.data.uid && client.id !== this.state.item.id) {
        duplicate = true
        cid = client.id
      }
    }
    if(duplicate) {
      Firebase.database().ref('/usersClients/'+this.state.item.data.uid+'/trainers/'+global.uid).update({
        client: cid
      })
    } else {
      Firebase.database().ref('/usersClients/'+this.state.item.data.uid+'/trainers/'+global.uid).remove()
    }
    Firebase.database().ref('/messaging/'+global.uid+this.state.item.data.uid).remove()
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
      uid: ''
    })
    setTimeout(function(){
      EventEmitter.dispatch('showMessage', lang.t('clients:message.clientdisconnected'))
    }, 200);
  }


  onChangeMore = event => {
    if(event.target.value === 'disconnect') {
      this.showModalClient()
    }
    if(event.target.value === 'reset') {
      this.showModalReset()
    }
    if(event.target.value === 'merge') {
      this.showModalMerge()
    }
    this.setState({
      more: ''
    });
  }



  // Modal document ------------------------------------------------------------



  showModalDocument(id) {
    var dval = ''
    var ddat = Moment().format('X')
    var link = ''
    if(id !== '') {
      for(var item of this.state.item.documents) {
        if(item.id === id) {
          dval = item.data.name
          ddat = item.data.date
          link = 'documents/'+global.uid+'/'+this.state.item.id+'/'+item.id+'.'+item.data.ext
        }
      }
    }
    this.setState({
      showModalDocument: true,
      doc: id,
      ddate: ddat,
      dvalue: dval,
    })
    if(link !== "") {
      Firebase.storage().ref().child(link).getDownloadURL().then((url) => {
        this.setState({
          dlink: url
        })  
      })
    }
    
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenDocument: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideModalDocument() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenDocument: 'hidden',
      margin: -height/2+100,
      links: ['', '', '']
    })
    setTimeout(() => {
      this.setState({showModalDocument: false});
    }, 500);
  }


  renderDocumentButton() {
    if(this.state.doc === '') {
      return (
        <button className="btn primary" onClick={() => this.uploadDocument()}>{lang.t('modals:document.button.upload')}</button>
      )
    } else {
      return (
        <div>
          <a href={this.state.dlink} target="_blank" rel="noopener noreferrer" className="btn primary">{lang.t('modals:document.button.viewdocument')}</a>
          <div className="sv-20"></div>
          <button className="btn tertiary width-12" onClick={() => this.deleteDocument()}>{lang.t('modals:document.button.delete')}</button>
        </div>
      )
    }
  }


  renderDocumentError(num) {
    if(this.state.derror[num] && num === 0) {
      return (
        <p className="error">{lang.t('modals:document.name.error')}</p>
      )
    }
    if(this.state.derror[num] && num === 1) {
      return (
        <p className="error">{lang.t('modals:document.file.error')}</p>
      )
    }
  }


  renderDocumentForm() {
    if(this.state.doc === '') {
      return (
        <div className="clear">
          <p className="mb-20">{lang.t('modals:document.info1')}</p>
          <div className="form-item">
            <label>{lang.t('modals:document.name.label')}</label>
            <input type="text" value={this.state.dvalue} className={this.state.derror[0] ? 'error': ''} onChange={this.onChangeDoc}/>
            {this.renderDocumentError(0)}
          </div>
          <div className="form-item">
            <label>{lang.t('modals:document.file.label')}</label>
            <input type="file" onChange={(e: any) => {this.setDocument(e.target.files[0]);}}/>
            {this.renderDocumentError(1)}
          </div>
        </div>
      )
    } else {
      return (
        <div className="clear">
          <div className="form-item">
            <label>{lang.t('modals:document.name.label')}</label>
            <input type="text" value={this.state.dvalue} className={this.state.derror[0] ? 'error': ''} onChange={this.onChangeDoc}/>
            {this.renderDocumentError(0)}
          </div>
          <button className="btn tertiary small" onClick={() => this.updateDocument()} style={{marginTop: 5}}>{lang.t('modals:document.button.update')}</button>
          <div className="clear sv-10"></div>
        </div>
      )
    }
  }


  renderModalDocument() {
    if(this.state.showModalDocument) {
      return (
        <div className={'overlay '+this.state.hiddenDocument}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{this.state.doc === '' ? lang.t('modals:document.title.upload') : lang.t('modals:document.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalDocument()}>{lang.t('common:button.close')}</button>
            {this.renderDocumentForm()}
            <div className="sv-20"></div>
            {this.renderDocumentButton()}
          </div>
        </div>
      )
    }
  }


  onChangeDoc = event => {
    this.setState({dvalue: event.target.value});
  };


  setDocument(file) {
    var tmp = this.state.derror
    tmp[1] = false
    if(file.size > 1000000) {
      tmp[1] = true
    }
    this.setState({
      dfile: file,
      ddate: Moment().format('X'),
      derror: tmp
    })
  }


  uploadDocument() {
    var tmp = this.state.derror
    tmp[0] = false
    if(this.state.dvalue === '') {
      tmp[0] = true
    }
    this.setState({
      derror: tmp
    })
    if(!tmp[0] && !tmp[1]) {
      this.hideModalDocument()
      var obj = Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/documents').push()

      var ext = this.state.dfile.name.split('.').pop()
      const sr = Firebase.storage().ref()
      const fr = sr.child('documents/'+global.uid+'/'+this.state.item.id+'/'+obj.key+'.'+ext)
      fr.put(this.state.dfile).then(() => {
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/documents/'+obj.key).update({
          name: this.state.dvalue,
          date: parseInt(this.state.ddate),
          ext: ext,
        })
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.documentuploaded'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  updateDocument() {
    var tmp = this.state.derror
    tmp[0] = false
    if(this.state.dvalue === '') {
      tmp[0] = true
    }
    this.setState({
      derror: tmp
    })
    if(!tmp[0]) {
      this.hideModalDocument()
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/documents/'+this.state.doc).update({
        name: this.state.dvalue,
      }).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.documentnameupdated'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  deleteDocument() {
    this.hideModalDocument()
    var ext = ''
    for(var item of this.state.item.documents) {
      if(item.id === this.state.doc) {
        ext = item.data.ext
      }
    }
    Firebase.storage().ref('documents/'+global.uid+'/'+this.state.item.id+'/'+this.state.doc+'.'+ext).delete()
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/documents/'+this.state.doc).remove().then(() => {
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.documentdeleted'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // History modal ------------------------------------------------------------



  showModalHistory(item) {
    if(this.state.histMode !== 'form' && HelperBus.getRoleIncl('')) {
      this.updateMode('edit', item)
      this.setState({
        showModalHistory: true,
      })
      setTimeout(() => {
        const height = this.divElement.clientHeight
        this.setState({
          hiddenHistory: '',
          margin: -height/2,
        });
      }, 100);
    }
    
  }


  hideModalHistory() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenHistory: 'hidden',
      links: ['', '', ''],
      margin: -height/2+100,
    })
    setTimeout(() => {
      this.setState({showModalHistory: false});
    }, 500);
  }


  renderModalHistory() {
    if(this.state.showModalHistory) {
      return (
        <div className={'overlay '+this.state.hiddenHistory}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{lang.t('modals:history.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalHistory()}>{lang.t('common:button.close')}</button>
            <div className="clear"></div>
            <div className="form-item">
              <label>{lang.t('modals:history.titlelabel')}*</label>
              <input type="text" value={this.state.histValues[0]} name="0" onChange={this.onChangeHistory}/>
            </div>
            <div className="form-item">
              <label>{lang.t('modals:history.description')}</label>
              <textarea value={this.state.histValues[1]} name="1" onChange={this.onChangeHistory}></textarea>
            </div>
            <div className="form-item">
              <label>{lang.t('modals:history.important')}</label>
              <div className={this.state.histImportant ? 'toggle active' : 'toggle'} onClick={() => this.setState({histImportant: !this.state.histImportant})}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('modals:history.important.text')}</p>
              <div className="clear"></div>
            </div>
            <div className="sv-20"></div>
            <button className="btn primary mb-20" onClick={() => this.updateHistory()}>{lang.t('modals:history.button')}</button>
            <button className="btn tertiary width-12" onClick={() => this.deleteHistory()}>{lang.t('modals:history.button.delete')}</button>
          </div>
        </div>
      )
    }
  }


  updateMode(mode, item) {
    var title = ''
    var desc = ''
    var imp = false
    for(var hist of this.state.item.history) {
      if(hist.id === item) {
        title = hist.data.title
        desc = hist.data.desc
        if(hist.data.important) {
          imp = true
        }
      }
    }
    this.setState({
      histMode: mode,
      histItem: item,
      histValues: [title, desc],
      histImportant: imp,
    })
  }


  onChangeHistory = event => {
    var tmp = this.state.histValues
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({histValues: tmp});
  };


  createHistory() {
    if(this.state.histValues[0] !== '') {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/history').push({
        date: Moment().format('DD/MM/YYYY HH:mm'),
        title: this.state.histValues[0],
        desc: this.state.histValues[1],
        important: this.state.histImportant
      })
      EventEmitter.dispatch('showMessage', lang.t('clients:message.notecreated'));
      this.setState({
        histMode: 'list',
        histValues: ['', ''],
        important: this.state.histImportant
      }) 
    }
  }


  updateHistory() {
    if(this.state.histValues[0] !== '') {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/history/'+this.state.histItem).update({
        title: this.state.histValues[0],
        desc: this.state.histValues[1],
        important: this.state.histImportant
      })
      this.hideModalHistory()
      EventEmitter.dispatch('showMessage',lang.t('clients:message.noteupdated'));
    }
  }


  deleteHistory() {
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/history/'+this.state.histItem).remove()
    EventEmitter.dispatch('showMessage', lang.t('clients:message.notedeleted'));
    this.hideModalHistory()
    this.setState({
      histMode: 'list'
    })
  }
  

  gotoForm(form) {
    this.props.history.push('/clients/'+this.state.item.id+'/form/'+form.id)
  }



  // Reset modal ------------------------------------------------------------



  showModalReset() {  
    this.setState({
      showModalReset: true,
      valuesReset: [this.state.item.data.email, ''],
      errorReset: false,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenReset: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideModalReset() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenReset: 'hidden',
      margin: -height/2+100,
    })
    setTimeout(() => {
      this.setState({showModalReset: false});
    }, 500);
  }


  renderErrorReset() {
    if(this.state.errorReset) {
      return (
        <p className="error" style={{color: '#DE1067', fontSize: 12, fontWeight:'bold'}}>{lang.t('clients:error.password')}</p>
      )
    }
  }


  renderModalReset() {
    if(this.state.showModalReset) {
      return (
        <div className={'overlay '+this.state.hiddenReset}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modals:reset.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalReset()}>{lang.t('common:button.close')}</button>
            <p className="clear mb-20">{lang.t('modals:reset.text')}</p>
            <label>{lang.t('modals:reset.email')}</label>
            <input type="text" value={this.state.valuesReset[0]} name="0" onChange={this.onChangeReset}/>
            <div className="sv-20"></div>
            <label>{lang.t('modals:reset.password')}</label>
            <input type="password" value={this.state.valuesReset[1]} name="1" onChange={this.onChangeReset}/>
            {this.renderErrorReset()}
            <div className="sv-30"></div>
            <button className="btn primary" onClick={() => this.resetAccess()}>{lang.t('modals:reset.button')}</button>
          </div>
        </div>
      )
    }
  }


  onChangeReset = event => {
    var tmp = this.state.valuesReset
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({valuesReset: tmp});
  };


  resetAccess() {
    if(this.state.valuesReset[0] !== '' && this.state.valuesReset[1] !== '') {
      if(this.state.valuesReset[1].length > 5) {
        this.hideModalReset()
        var callFunction1 = Firebase.functions().httpsCallable('deleteUserV2');
        callFunction1({uid: this.state.item.data.uid}).then(function(result) {});
        setTimeout(() => {
          var callFunction2 = Firebase.functions().httpsCallable('createUserV2');
          callFunction2({email: this.state.valuesReset[0], password: this.state.valuesReset[1], uid: this.state.item.data.uid, displayName: "client"}).then(function(result) {});
        }, 500);

        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
          email: this.state.valuesReset[0]
        }).then((data)=>{
          EventEmitter.dispatch('showMessage', lang.t('clients:message.accountreset'));
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
        Firebase.database().ref('/usersClients/'+this.state.item.data.uid).update({
          email: this.state.valuesReset[0]
        })
      } else {
        this.setState({
          errorReset: true
        })
      }
      
    }
  }



  // Modal additional profile ------------------------------------------------------------



  toggleModal() {
    if(HelperBus.getRoleIncl('trainer,admin')) {
      if(global.clients.length < global.maxClients || global.maxClients === 0) {
        this.showModal('')
      } else {
        this.setState({showModalMessage: true})
      }
    }
  }


  showModal(item) {
    var tmp1 = ['', '', '', '']
    var tmp2 = false
    if(item !== '') {
      for(var cl of global.clients) {
        if(cl.id === item) {
          tmp1 = [cl.data.name, cl.data.email, cl.data.phone, cl.data.birth]
          tmp2 = cl.data.restricted
        }
      }
    }
    this.setState({
      showModal: true,
      values: tmp1,
      restricted: tmp2,
      selected: item
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModal() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({
        showModal: false,
      });
    }, 500);
  }


  renderUnlink() {
    if(this.state.selected !== '') {
      return (
        <div>
          <div className="sv-20"></div>
          <button className="btn tertiary width-12" onClick={() => this.confirmUnlink()}>{lang.t('modals:link.button.unlink')}</button>
        </div>
      )
    }
  }


  renderForm() {
    if(this.state.selected === '') {
      return (
        <div>
          <label>{lang.t('modals:link.name')}*</label>
          <input type="text" value={this.state.values[0]} className={this.state.errorLinked[0] ? 'error': ''} name="0" onChange={this.onChangeLinked}/>
          {this.renderError(0)}
          <div className="sv-20"></div>
          <label>{lang.t('modals:link.phone')}</label>
          <input type="number" value={this.state.values[2]} name="2" onChange={this.onChangeLinked}/>
          <div className="sv-20"></div>
          <label>{lang.t('modals:link.email')}</label>
          <input type="text" value={this.state.values[1]} name="1" onChange={this.onChangeLinked}/>
          <div className="sv-20"></div>
          <label>{lang.t('modals:link.dob')}</label>
          <input type="date" value={Moment(this.state.values[3], 'DD/MM/YYYY').format('YYYY-MM-DD')} name="3" onChange={this.onChangeDateLinked}/>
          <div className="sv-20"></div>
        </div>
      )
    }
  }


  renderModal() {
    if(this.state.showModal) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{this.state.selected === '' ? lang.t('modals:link.title.addprofile') : lang.t('modals:link.title.updateprofile')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModal()}>{lang.t('common:button.close')}</button>
            <div className="clear">
              {this.renderForm()}
              <label>{lang.t('modals:link.dependant')}</label>
              <div className={this.state.restricted ? 'toggle active' : 'toggle'} onClick={() => this.setState({restricted: !this.state.restricted})}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('modals:link.dependant.text2')}</p>
              <div className="clear"></div>
            </div>
            <div className="clear sv-30"></div>
            <button className="btn primary" onClick={() => this.checkCreateLinked()}>{this.state.selected === '' ? lang.t('modals:link.button.create') : lang.t('modals:link.button.update')}</button>
            {this.renderUnlink()}
          </div>
        </div>
      )
    }
  }


  confirmUnlink() {
    this.hideModal()
    setTimeout(() => {
      this.setState({showModalConfirm: true})
    }, 550);
  }


  unlinkClient() {
    for(var cr of this.state.item.credits) {
      if(cr.data.account === this.state.selected) {
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.selected+'/credits').push(
          cr.data
        )
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/credits/'+cr.id).remove()
      }
    }
    for(var cr2 of this.state.item.creditse) {
      if(cr2.data.account === this.state.selected) {
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.selected+'/credits').push(
          cr2.data
        )
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/credits/'+cr2.id).remove()
      }
    }
    for(var sub of this.state.item.subscriptions) {
      if(sub.data.account === this.state.selected) {
        var desc = lang.t('clients:message.youcanceledthemembership')+' '+sub.data.name+' ('+global.curSym+(sub.data.price/100).toFixed(2)+' per '+sub.data.billing+').'
        var callFunction = Firebase.functions().httpsCallable('connectedCancelSubscriptionV2');
        callFunction({account: global.userStripeConnect, client: this.state.item.id, subscription: sub.id, desc: desc, date: Moment().format('DD/MM/YYYY HH:mm'), uid: global.uid}).then(function(result) {});
      }
    }
    var tmp = this.state.item.data.linked
    for(var i=0; i<tmp.length; i++) {
      if(tmp[i] === this.state.selected) {
        tmp.splice(i, 1)
      }
    }
    this.hideModals()
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
      linked: tmp
    })
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.selected+'/restricted').remove()
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.selected+'/parent').remove().then(() => {
      EventEmitter.dispatch('showMessage', lang.t('clients:message.profileunlinked'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Linked update stuff ------------------------------------------------------------



  onChangeLinked = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({
      values: tmp,
    });
  };


  onChangeDateLinked = event => {
    var tmp = this.state.values
    var valid = false
    tmp[3] = Moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    valid = Moment(event.target.value, 'YYYY-MM-DD').isValid()
    this.setState({
      values: tmp,
      valid: valid
    });
  };


  checkCreateLinked() {
    this.setState({errorLinked: [false, false]})
    var tmp = [false, false]
    if(this.state.values[0] === '') {
      tmp[0] = true
    }
    if(this.state.values[4] === '' && !this.state.restricted) {
      tmp[1] = true
    }
    this.setState({errorLinked: tmp})
    if(!tmp[0] && !tmp[1]) {
      this.hideModal()
      if(this.state.selected === '') {
        this.createClientLinked()
      } else {
        this.updateClientLinked()
      }
    }
  }


  createClientLinked() {
    var obj = Firebase.database().ref('/clients/'+global.uid).push()
    var birth = '01/01/1900'

    var tmp = []
    if(this.state.item.data.linked !== undefined) {
      tmp = this.state.item.data.linked
    }
    tmp.push(obj.key)

    var phone = this.state.values[2].replace('.' ,'').replace('-' ,'').replace(' ' ,'').replace('e' ,'')
    var restricted = this.state.restricted
    if(this.state.values[3] !== '' && this.state.values[3] !== 'Invalid Date' && this.state.valid) {
      birth = this.state.values[3]
      if(Moment(birth, 'DD/MM/YYYY').isAfter(Moment().add(-18, 'years'))) {
        restricted = true
      }
    }

    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
      linked: tmp
    })
    this.sendEmail(tmp)

    if(!this.state.restricted) {
      if((phone !== '' && phone !== '0412345678') || (this.state.values[1] !== '')) {
        Firebase.database().ref('/connect/'+obj.key).update({
          phone: phone,
          name: global.userName,
          client: obj.key,
          space: global.uid,
          email: this.state.values[1],
        })
      }
    }

    obj.set({
      name: this.state.values[0],
      email: this.state.values[1],
      phone: phone,
      birth: birth,
      height: 0,
      goal: '',
      parent: this.state.item.id,
      uid: '',
      image: '',
      restricted: restricted,
      imageDate: Moment().format('DD/MM/YYYY HH:mm'),
      ecName: this.state.item.data.name,
      ecPhone: this.state.item.data.phone,
      ecType: 1,
    }).then(() => {
      EventEmitter.dispatch('showMessage', lang.t('clients:message.profilecreated'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  updateClientLinked() {
    if(this.state.selected !== this.state.item.id) {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.selected).update({
        parent: this.state.item.id,
        restricted: this.state.restricted,
      }).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('clients:message.profileupdated'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }



  // Modal link ------------------------------------------------------------



  showModalLink() {
    this.setState({
      showModalLink: true,
      selected: '',
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenLink: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalLink() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenLink: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({
        showModalLink: false,
      });
    }, 500);
  }


  renderLinkWarning() {
    var uid = ''
    for(var item of this.state.clients) {
      if(item.id === this.state.selected) {
        uid = item.data.uid
      }
    }
    if(this.state.restricted && uid !== '') {
      return (
        <div className="infobox yellow">
          <p>{lang.t('modals:link.text')}</p>
        </div>
      )
    }
  }


  renderModalLink() {
    if(this.state.showModalLink) {
      return (
        <div className={'overlay '+this.state.hiddenLink}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{lang.t('modals:link.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalLink()}>{lang.t('common:button.close')}</button>
            <div className="clear form-item">
              <label>{lang.t('modals:link.selectclient')}</label>
              <select onChange={event => this.setSelected(event)} value={this.state.selected}>
                <option value=''>- {lang.t('modals:link.selectclient')} -</option>
                {this.state.clients.map(item => (
                  <option value={item.id} key={item.id}>{item.data.name}</option>
                ))}
              </select>
            </div>
            <div className="form-item">
              <label>{lang.t('modals:link.dependant')}</label>
              <div className={this.state.restricted ? 'toggle active' : 'toggle'} onClick={() => this.setState({restricted: !this.state.restricted})}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('modals:link.dependant.text')}</p>
              <div className="clear"></div>
            </div>
            {this.renderLinkWarning()}
            <div className="clear sv-20"></div>
            <button className="btn primary" onClick={() => this.linkClient()}>{lang.t('modals:link.button')}</button>
          </div>
        </div>
      )
    }
  }


  setSelected(event) {
    this.setState({
      selected: event.target.value
    });
  }


  linkClient() {
    if(this.state.selected !== '') {
      this.hideModalLink()
      var tmp = []
      if(this.state.item.data.linked !== undefined) {
        tmp = this.state.item.data.linked
      }
      tmp.push(this.state.selected)
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
        linked: tmp
      })
      this.sendEmail(tmp)

      if(this.state.selected !== this.state.item.id) {
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.selected).update({
          parent: this.state.item.id,
          restricted: this.state.restricted,
        }).then(() => {
          EventEmitter.dispatch('showMessage', lang.t('clients:message.clientlinked'));
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
        if(this.state.restricted) {
          var uid = ''
          for(var item of this.state.clients) {
            if(item.id === this.state.selected) {
              uid = item.data.uid
            }
          }
          if(uid !== '') {
            Firebase.database().ref('/clients/'+global.uid+'/'+this.state.selected).update({
              uid: ''
            })
            Firebase.database().ref('/usersClients/'+uid+'/trainers/'+global.uid).remove()
          }
        }
        // Add existing credits
        for(var ic of this.state.clients) {
          if(ic.id === this.state.selected) {
            if(ic.credits.length > 0) {
              for(var c1 of ic.credits) {
                c1.data.account = this.state.selected
                Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/credits').push(c1.data)
              }
            }
            if(ic.creditse.length > 0) {
              c1.data.account = this.state.selected
              Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/credits').push(c1.data)
            }
          }
        }
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.selected+'/credits').remove()
      }
    }
  }


  sendEmail(list) {
    if(list.length === 1 && this.state.item.data.email !== '' && this.state.item.data.uid !== '') {
      var ar = this.state.item.data.name.split(' ')
      var client = ar[0]
      var ar2 = global.userName.split(' ')
      var name = ar2[0]+" from "+global.userBusiness
      var callFunction = Firebase.functions().httpsCallable('sendClientFamilyV2');
      callFunction({email: this.state.item.data.email, name: name, client: client, link: 'https://ptmate.me/'+global.uid+'/login', business: global.userBusiness}).then(function(result) {})
    }
  }


  getUnlinkName() {
    var label = lang.t('clients:label.thisprofile')
    for(var item of global.clients) {
      if(item.id === this.state.selected) {
        label = item.data.name
      }
    }
    return label
  }



  // Merge modal ------------------------------------------------------------



  showModalMerge() {
    var tmp = []
    for(var item of global.clients) {
      if(item.id !== this.state.item.id) {
        tmp.push(item)
      }
    }
    this.setState({
      showModalMerge: true,
      selected: '',
      suid: '',
      merge: 0,
      clients: tmp
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenMerge: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideModalMerge() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenMerge: 'hidden',
      margin: -height/2+100,
    })
    setTimeout(() => {
      this.setState({showModalMerge: false});
    }, 500);
  }


  renderMergeUID() {
    var uid = ''
    for(var item of this.state.clients) {
      if(item.id === this.state.selected) {
        uid = item.data.uid
      }
    }
    if(uid !== '' && this.state.item.data.uid !== '') {
      return (
        <p className="mb-20">{lang.t('modals:merge.login1')} {this.state.merge === 0 ? lang.t('modals:merge.login3') : this.getClientName()} {lang.t('modals:merge.login2')}</p>
      )
    }
  }


  renderMergeForm() {
    if(this.state.selected !== '') {
      return (
        <div className="clear">
          <div className="sv-20"></div>
          <div className="infobox mb-30">
            <p><strong>{lang.t('modals:merge.note1')}</strong> {lang.t('modals:merge.note2')}</p>
          </div>
          <label>{lang.t('modals:merge.use')}</label>
          <div className="radios">
            <button className={this.state.merge === 0 ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({merge: 0})}>{lang.t('modals:merge.use.label1')}</button>
            <button className={this.state.merge === 1 ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({merge: 1})}>{this.getClientName()}</button>
          </div>
          <div className="sv-20"></div>
          {this.renderMergeUID()}
          <div className="sv-30"></div>
          <button className="btn primary" onClick={() => this.mergeClients()}>{lang.t('modals:merge.button')}</button>
        </div>
      )
    }
  }


  renderModalMerge() {
    if(this.state.showModalMerge) {
      var list = this.state.clients
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className={'overlay '+this.state.hiddenMerge}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modals:merge.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalMerge()}>{lang.t('common:button.close')}</button>
            <p className="clear mb-20">{lang.t('modals:merge.text')}</p>
            <select value={this.state.selected} onChange={this.onChangeMerge}>
              <option value=''>- {lang.t('modals:merge.select')} -</option>
              {list.map(item => (
              <option key={item.id} value={item.id}>{item.data.name}</option>
            ))}
            </select>
            {this.renderMergeForm()}
          </div>
        </div>
      )
    }
  }


  getClientName() {
    var label = 'Other client'
    for(var item of this.state.clients) {
      if(item.id === this.state.selected) {
        label = item.data.name
      }
    }
    return label
  }


  onChangeMerge = event => {
    var suid = ''
    for(var item of this.state.clients) {
      if(item.id === event.target.value) {
        suid = item.data.uid
      }
    }
    this.setState({
      selected: event.target.value,
      suid: suid
    });
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({margin: -height/2,});
    }, 100);
  };


  mergeClients() {
    this.hideModalMerge()
    var keep = this.state.item
    var del = null
    for(var item of this.state.clients) {
      if(item.id === this.state.selected) {
        del = item
      }
    }
    if(this.state.merge === 1) {
      keep = del
      del = this.state.item
    }
    // Payments
    for(var item1 of global.payments) {
      if(item1.data.client === del.id) {
        Firebase.database().ref('/payments/'+global.uid+'/'+item1.id).update({
          client: keep.id
        })
      }
    }
    // Sessions
    for(var item2 of global.sessions) {
      if(item2.data.client === del.id) {
        Firebase.database().ref('/sessions/'+global.uid+'/'+item2.id).update({
          client: keep.id
        })
      } else {
        // Group
        if(item2.data.group) {
          var change = false
          var c1 = []
          var c2 = []
          var c3 = []
          if(item2.data.clients !== undefined) {
            c1 = item2.data.clients
            for(var i1=0; i1<c1.length; i1++) {
              if(c1[i1] === del.id) {
                change = true
                c1[i1] = keep.id
              }
            }
          }
          if(item2.data.confirmed !== undefined) {
            c2 = item2.data.confirmed
            for(var i2=0; i2<c2.length; i2++) {
              if(c2[i2] === del.id) {
                change = true
                c2[i2] = keep.id
              }
            }
          }
          if(item2.data.waiting !== undefined) {
            c3 = item2.data.waiting
            for(var i3=0; i3<c3.length; i3++) {
              if(c3[i3] === del.id) {
                c3[i3] = keep.id
                change = true
              }
            }
          }
          if(change) {
            Firebase.database().ref('/sessions/'+global.uid+'/'+item2.id).update({
              clients: c1,
              confirmed: c2,
              waiting: c3,
            })
          }
        }
      }
    }
    // Recurring sessions
    for(var itemr of global.recurring) {
      if(itemr.data.client === del.id) {
        Firebase.database().ref('/recurring/'+global.uid+'/'+itemr.id).update({
          client: keep.id
        })
      }
    }
    // Community
    for(var itemc of global.community) {
      if(itemc.data.author === del.id) {
        Firebase.database().ref('/community/'+global.uid+'/'+itemc.id).update({
          author: keep.id
        })
      }
    }
    // Credits
    var addp = 0
    var addg = 0
    var totp = 0
    var totg = 0
    var credits = keep.credits
    for(var cr of del.credits) {
      if(cr.data.expires === undefined || cr.data.expires === 0) {
        if(cr.data.group) {
          totg = cr.data.total
          addg = cr.data.paid
        } else {
          totp = cr.data.total
          addp = cr.data.paid
        }
      } else {
        credits.push({
          group: cr.data.group,
          sessionsPaid: cr.data.sessionsPaid,
          sessionsTotal: cr.data.sessionsTotal,
          expires: cr.data.expires,
          type: 'sessions'
        })
      }
    }
    
    if(credits.length === 0) {
      if(totp !== 0 || addp !== 0) {
        credits.push({
          group: false,
          sessionsPaid: parseInt(addp),
          sessionsTotal: parseInt(totp),
          type: 'sessions'
        })
      }
      if(totg !== 0 || addg !== 0) {
        credits.push({
          group: true,
          sessionsPaid: addg,
          sessionsTotal: totg,
          type: 'sessions'
        })
      }
    } else {
      for(var cr1 of credits) {
        if(cr1.data.group) {
          cr1.data.sessionsPaid += totg
          cr1.data.sessionsTotal += addg
        } else {
          cr1.data.sessionsPaid += totp
          cr1.data.sessionsTotal += addp
        }
      }
    }
    // Assessments, Forms & History
    var ass = keep.assessments
    for(var ass1 of del.assessments) {
      ass.push(ass1)
    }
    var frms = keep.forms
    for(var frms1 of del.forms) {
      frms.push(frms1)
    }
    var hist = keep.history
    for(var hist1 of del.history) {
      hist.push(hist1)
    }
    // Nutrition
    if(keep.data.nutritionId === undefined && del.data.nutritionId !== undefined && del.data.nutritionId !== undefined) {
      Firebase.database().ref('/clients/'+global.uid+'/'+keep.id).update({
        nutritionId: del.data.nutritionId,
        nutritionStart: del.data.nutritionStart,
        nutritionEnd: del.data.nutritionEnd,
        nutritionStatus: del.data.nutritionStatus,
      })
    }
    // Billing info & Stripe
    if(del.data.customer !== undefined && del.data.customer !== '') {
      var callFunction = Firebase.functions().httpsCallable('connectedDeleteCustomerV2');
      callFunction({type: "account", account: global.userStripeConnect, customer: del.data.customer, uid: global.uid}).then(function(result) {
        //var sanitizedMessage = result.data.text;
      });
    }
    // Family account
    if(del.data.parent !== undefined && del.data.parent !== '') {
      for(var cl1 of global.clients) {
        if(cl1.id === del.data.parent) {
          for(var icl=0; icl<cl1.data.linked.length; icl++) {
            if(cl1.data.linked[icl] === del.id) {
              cl1.data.linked.splice(icl, 1)
            }
          }
        }
      }
    }
    if(del.data.linked !== undefined) {
      for(var lk of del.data.linked) {
        Firebase.database().ref('/clients/'+global.uid+'/'+lk).update({
          parent: '',
          restricted: false,
        })
      }
    }
    // UID
    var uid = keep.data.uid
    if(uid === '' && del.data.uid !== '') {
      uid = del.data.uid
    }

    Firebase.database().ref('/clients/'+global.uid+'/'+del.id).remove()
    if(uid !== '') {
      Firebase.database().ref('/usersClients/'+uid+'/trainers/'+global.uid).update({
        client: keep.id
      })
    }
    for(var as1 of ass) {
      Firebase.database().ref('/clients/'+global.uid+'/'+keep.id+'/assessments/'+as1.id).update(as1.data)
    }
    for(var fr1 of frms) {
      Firebase.database().ref('/clients/'+global.uid+'/'+keep.id+'/forms/'+fr1.id).update(fr1.data)
    }
    for(var hi1 of hist) {
      Firebase.database().ref('/clients/'+global.uid+'/'+keep.id+'/history/'+hi1.id).update(hi1.data)
    }
    for(var cr2 of credits) {
      Firebase.database().ref('/clients/'+global.uid+'/'+keep.id+'/credits/'+cr2.id).update(cr2.data)
    }
    Firebase.database().ref('/clients/'+global.uid+'/'+keep.id).update({
      uid: uid,
    }).then(() => {
      global.message = lang.t('clients:message.clientsmerged')
      this.props.history.push(global.currentPage)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Modals ------------------------------------------------------------



  toggleConvert(type) {
    this.hideModals()
    setTimeout(() => {
      if(type === 'new') {
        this.showModal('')
      } else {
        this.showModalLink()
      }
      
    }, 550);
  }


  hideModals() {
    this.setState({
      showModalFamily: false,
      showModalActive: false,
      showModalConfirm: false,
      showModalMessage: false,
      showModalAvatar: false,
      showModalLocations: false,
    })
  }


  selectLocations(event) {
    this.hideModals()
    var list = []
    for(var ev of event) {
      list.push(ev.id)
    }
    for(var loc of global.locations) {
      if(list.indexOf(loc.id) !== -1) {
        var clist = []
        if(loc.data.clients !== undefined) {
          clist = loc.data.clients
        }
        if(clist.indexOf(this.state.id) === -1) {
          clist.push(this.state.id)
        }
        Firebase.database().ref('/locations/'+global.uid+'/'+loc.id).update({
          clients: clist
        })
      } else {
        if(loc.data.clients !== undefined) {
          if(loc.data.clients.indexOf(this.state.id) !== -1) {
            var clist2 = []
            for(var cl of loc.data.clients) {
              if(cl !== this.state.id) {
                clist2.push(cl)
              }
            }
            Firebase.database().ref('/locations/'+global.uid+'/'+loc.id).update({
              clients: clist2
            })
          }
        }
      }
    }
    EventEmitter.dispatch('showMessage', lang.t('clients:message.locationsupdated'));
  }


  toggleActive() {
    this.hideModals()
    var title = lang.t('clients:label.inactive')
    var desc = lang.t('clients:messge.inactive')
    if(this.state.item.data.active === undefined || this.state.item.data.active === true) {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
        active: false
      }).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('clients:message.inactive.text'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      title = lang.t('clients:label.active')
      desc = lang.t('clients:message.active')
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
        active: true
      }).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('clients:message.active.text'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/history').push({
      date: Moment().format('DD/MM/YYYY HH:mm'),
      title: title,
      desc: desc
    })
  }



  // Display stuff ------------------------------------------------------------



  renderError(num) {
    var texts = [lang.t('clients:error.name'), lang.t('clients:error.phone'), lang.t('clients:error.email')]
    if(this.state.error[num]) {
      return (
        <p className="error">{texts[num]}</p>
      )
    }
  }


  renderButtonEdit() {
    if(HelperBus.getRoleIncl('trainer,admin') && this.state.active && this.state.item.data.uid === '') {
      return (
        <div>
          <div className="sv-20"></div>
          <button className="btn secondary edit width-12" onClick={() => this.clickEditing()}>{lang.t('clients:button.editdetails')}</button>
          <div className="sv-20"></div>
        </div>
      )
    }
  }


  renderNoteForm() {
    if(this.state.histMode === 'form') {
      return (
        <div className="form">
          <h4 className="mb-20">{lang.t('clients:button.addanote')}</h4>
          <div className="clear"></div>
          <div className="form-item">
            <label>{lang.t('clients:form.title')}*</label>
            <input type="text" value={this.state.histValues[0]} name="0" onChange={this.onChangeHistory}/>
          </div>
          <div className="form-item">
            <label>{lang.t('clients:form.description')}</label>
            <textarea value={this.state.histValues[1]} name="1" onChange={this.onChangeHistory}></textarea>
          </div>
          <div className="form-item">
            <label>{lang.t('clients:form.important')}</label>
            <div className={this.state.histImportant ? 'toggle active' : 'toggle'} onClick={() => this.setState({histImportant: !this.state.histImportant})}>
              <div className="mover"></div>
            </div>
            <p className="toggle-label">{lang.t('clients:form.important.text')}</p>
          </div>
          <div className="clear sv-20"></div>
          <button className="btn primary mb-20" onClick={() => this.createHistory()}>{lang.t('clients:button.createnote')}</button>
          <button className="btn tertiary close width-12" onClick={() => this.updateMode('list', '')}>{lang.t('common:button.cancel')}</button>
          <div className="sv-40"></div>
        </div>
      )
    }
  }


  renderNotePill(item) {
    if(item.data.important) {
      return (
        <span className="pill primary" style={{marginRight: 5, position: 'relative', top: '-1px'}}>{lang.t('clients:form.important')}</span>
      )
    }
  }


  renderNotes() {
    if(this.state.item !== null) {
      if(this.state.item.history.length === 0) {
        return (
          <div className="empty">
            {HelperEmpty.renderImage('notes')}
            <h4>{lang.t('clients:empty.nonotesyet')}</h4>
          </div>
        )
      } else {
        var list = this.state.item.history.slice(0, 5)
        list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
        if(this.state.showAll || this.state.item.history.length < 5) {
          list = this.state.item.history
          list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
          return (
            <div>
              {list.map(item => (
                <div className="list bare" key={item.id} onClick={() => this.showModalHistory(item.id)}>
                  <div className="main">
                    <h4>{this.renderNotePill(item)}{item.data.title}</h4>
                    <p>{item.data.desc}</p>
                    <p>{Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('llll')}</p>
                  </div>
                  <div className="clear"></div>
                </div>
              ))}
            </div>
          )
        } else {
          return (
            <div>
              {list.map(item => (
                <div className="list bare" key={item.id} onClick={() => this.showModalHistory(item.id)}>
                  <div className="main">
                    <h4>{this.renderNotePill(item)}{item.data.title}</h4>
                    <p>{item.data.desc}</p>
                    <p>{Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('llll')}</p>
                  </div>
                  <div className="clear"></div>
                </div>
              ))}
              <button className="btn tertiary small add width-12 mt-10" onClick={() => this.setState({showAll: true})}>{lang.t('clients:button.showall')}</button>
            </div>
          )
        }
        
      }
    }
  }


  renderHistoryLink() {
    if(HelperBus.getRoleIncl('trainer,admin') && this.state.active && this.state.histMode !== 'form') {
      return (
        <button className="btn tertiary small add rgt" onClick={() => this.updateMode('form', '')}>{lang.t('clients:button.addanote')}</button>
      )
    }
  }


  renderGoal(type) {
    if(this.state.item.data.uid !== '' && this.state.active && HelperBus.getRoleIncl('trainer')) {
      return (
        <button className="btn tertiary small rgt edit" onClick={() => this.showModalGoal(type)}>{lang.t('common:button.edit')}</button>
      )
    }
  }


  renderActive() {
    if(HelperBus.getRoleIncl('trainer,admin')) {
      if(this.state.item.data.active || this.state.item.data.active === undefined) {
        return (
          <div className="form-item">
            <label>{lang.t('clients:label.status')}</label>
            <div className="toggle active" onClick={() => this.setState({showModalActive: true})}>
              <div className="mover"></div>
            </div>
            <p className="toggle-label">{lang.t('clients:label.active')}</p>
          </div>
        )
      } else {
        return (
          <div className="form-item">
            <label>{lang.t('clients:label.status')}</label>
            <div className="toggle" onClick={() => this.setState({showModalActive: true})}>
              <div className="mover"></div>
            </div>
            <p className="toggle-label">{lang.t('clients:label.inactive')}</p>
          </div>
        )
      }
    }
  }


  renderMore() {
    if(!this.state.showOptions && HelperBus.getRoleIncl('admin')) {
      return (
        <div>
          <button className="btn tertiary width-12 add" onClick={() => this.setState({showOptions: true})}>{this.state.item.data.uid === '' ? lang.t('clients:button.moreoptions') : lang.t('clients:button.showoptions')}</button>
        </div>
      )
    } else if(this.state.showOptions && HelperBus.getRoleIncl('admin')) {
      if(this.state.item.data.uid === '') {
        return (
          <div>
            <button className="btn tertiary width-12 sub" onClick={() => this.setState({showOptions: false})}>{lang.t('clients:button.hideoptions')}</button>
            <div className="sv-20"></div>
            <select value={this.state.more} onChange={this.onChangeMore}>
              <option value=''>- {lang.t('clients:form.select')} -</option>
              <option value='disconnect'>{lang.t('clients:form.deleteclient')}</option>
              <option value='merge'>{lang.t('clients:form.mergewithanotherclient')}</option>
            </select>
          </div>
        )
      } else {
        return (
          <div>
            <button className="btn tertiary width-12 sub" onClick={() => this.setState({showOptions: false})}>{lang.t('clients:button.hideoptions')}</button>
            <div className="sv-20"></div>
            <select value={this.state.more} onChange={this.onChangeMore}>
              <option value=''>- {lang.t('clients:form.select')} -</option>
              <option value='disconnect'>{lang.t('clients:form.disconnectclient')}</option>
              <option value='merge'>{lang.t('clients:form.mergewithanotherclient')}</option>
              <option value='reset'>{lang.t('clients:form.fixmemberapploginissue')}</option>
            </select>
          </div>
        )
      }
      
    }
    
  }


  renderHeightStatic() {
    var label = (this.state.item.data.height === 0 ? '-' : this.state.item.data.height+' cm')
    if(global.userLbs && this.state.item.data.height !== 0) {
      var h = this.state.item.data.height/2.54
      var h1 = parseInt(h/12)
      var h2 = (h-(h1*12)).toFixed(0)
      label = h1+"'"+h2+' ft'
    }
    return label
  }


  renderImageSelect() {
    if(this.state.imageType === 'avatar') {
      return <button className="btn tertiary small mb-20" onClick={() => this.setState({showModalAvatar: true})}>{lang.t('clients:button.selectgalleryimage')}</button>
    } else if(this.state.imageType === 'image') {
      return (
        <div className="form-item">
          <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0]);}}/>
        </div>
      )
    }
  }


  renderInfo() {
    if(this.state.item !== null && this.state.editing) {
      return (
        <div className="col-4 withsidebar">
          <div className="box">
            <div className="avatar-container">
              {this.displayImage()}
            </div>
            <div className="sv-10"></div>
            <InputRadio label='Image' value={this.state.imageType} values={[{name: lang.t('clients:form.gallery'), value: 'avatar'}, {name: lang.t('clients:form.photo'), value: 'image'}, {name: lang.t('clients:form.noimage'), value: ''}]} clickElement={(event) => this.setState({imageType: event})}/>
            {this.renderImageSelect()}
            <div className="form-item">
              <label>{lang.t('clients:label.name')}*</label>
              <input type="text" value={this.state.values[0]} className={this.state.error[0] ? 'error': ''} name="0" onChange={this.onChangeClient}/>
              {this.renderError(0)}
            </div>
            <div className="form-item">
              <label>{lang.t('clients:label.mobile')}*</label>
              <input type="number" value={this.state.values[1]} className={this.state.error[1] ? 'error': ''} name="1" onChange={this.onChangeClient}/>
              {this.renderError(1)}
            </div>
            <div className="form-item">
              <label>{lang.t('clients:label.email')}*</label>
              <input type="text" value={this.state.values[2]} className={this.state.error[2] ? 'error': ''} name="2" onChange={this.onChangeClient}/>
              {this.renderError(2)}
            </div>
            <div className="form-item">
              <label>{lang.t('clients:label.dob')}</label>
              <input type="date" value={Moment(this.state.values[3], 'DD/MM/YYYY').format('YYYY-MM-DD')} name="3" onChange={this.onChangeDate}/>
            </div>
            <div className="form-item">
              <label>{lang.t('clients:label.height')} ({global.userLbs ? 'ft' : 'cm'})</label>
              <input type="number" value={this.state.values[4]} name="4" onChange={this.onChangeClient}/>
            </div>
            <div className="form-item">
              <label>{lang.t('clients:form.clientstrainingfocus')}</label>
              <input type="text" value={this.state.values[5]} name="5" onChange={this.onChangeClient}/>
            </div>
            <div className="clear sv-10"></div>
            <h4 className="mb-20">{lang.t('clients:label.ec')}</h4>
            <div className="form-item">
              <label>{lang.t('clients:label.name')}</label>
              <input type="text" value={this.state.valuesEC[0]} name="0" onChange={this.onChangeEC}/>
            </div>
            <div className="form-item">
              <label>{lang.t('clients:label.phone')}</label>
              <input type="text" value={this.state.valuesEC[1]} name="1" onChange={this.onChangeEC}/>
            </div>
            <div className="form-item">
              <label>{lang.t('clients:form.relationship')}</label>
              <div className="radios">
                <button className={this.state.typeEC === 0 ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({typeEC: 0})}>{lang.t('clients:form.spouse')}</button>
                <button className={this.state.typeEC === 1 ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({typeEC: 1})}>{lang.t('clients:form.family')}</button>
                <button className={this.state.typeEC === 2 ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({typeEC: 2})}>{lang.t('clients:form.friend')}</button>
              </div>
            </div>
            <div className="sv-30"></div>
            <button className="btn primary mb-20" onClick={() => this.checkUpdate()}>{lang.t('clients:button.updatedetails')}</button>
            <button className="btn tertiary width-12 close" onClick={() => this.cancelEdit()}>{lang.t('common:button.cancel')}</button>
          </div>
          <div className="sv-20"></div>
        </div>
      )
    } else if(this.state.item !== null && !this.state.editing) {
      return (
        <div className="col-4 withsidebar">
          <div className="box">
            <div className="avatar-container">
              {HelperClients.getImage(this.state.item)}
            </div>
            <div className="sv-10"></div>
            <div className="info">
              <label className="lft">{lang.t('clients:label.name')}</label>
              {this.renderGoal('name')}
              <p className="clear">{this.state.item.data.name}</p>
            </div>
            <InfoData label={lang.t('clients:label.mobile')} value={HelperClients.getPhone(this.state.item.data.phone)}/>
            <InfoData label={lang.t('clients:label.email')} value={this.state.item.data.email === '' ? '-' : this.state.item.data.email}/>
            <InfoData label={lang.t('clients:label.dob')} value={this.state.item.data.birth === '01/01/1900' ? '-' : Moment(this.state.item.data.birth, 'DD/MM/YYYY').format('D MMMM YYYY')}/>
            <InfoData label={lang.t('clients:label.height')} value={this.renderHeightStatic()}/>
            <div className="info">
            <label className="lft">{lang.t('clients:label.trainingfocus')}</label>
              {this.renderGoal('goal')}
              <p className="clear">{this.state.item.data.goal === '' ? '-' : this.state.item.data.goal}</p>
            </div>
            <div className="info">
              <label className="lft">{lang.t('clients:label.ec')}</label>
              {this.renderGoal('ec')}
              <p className="clear">{this.getEmergency()}</p>
              <p className="small">{this.getEmergencyPhone()}</p>
            </div>
            {this.renderActive()}
            <div className="sv-30"></div>
            {this.renderButtonEdit()}
            {this.renderMore()}
          </div>
          <div className="sv-20"></div>
        </div>
      )
    } else {
      return (
        <div className="col-4 withsidebar">
          <div className="box">
            <div className="avatar-container">
              <div className="avatar"><p>C</p></div>
            </div>
            <div className="sv-10"></div>
            <InfoData label={lang.t('clients:label.name')} value='-'/>
            <InfoData label={lang.t('clients:label.mobile')} value='-'/>
            <InfoData label={lang.t('clients:label.email')} value='-'/>
            <InfoData label={lang.t('clients:label.dob')} value='-'/>
            <InfoData label={lang.t('clients:label.height')} value='-'/>
            <InfoData label={lang.t('clients:label.trainingfocus')} value='-'/>
            <InfoData label={lang.t('clients:label.ec')} value='-'/>
            <InfoData label={lang.t('clients:label.status')} value='-'/>
            <div className="sv-30"></div>
            <button className="btn tertiary edit width-12">{lang.t('clients:button.editdetails')}</button>
          </div>
          <div className="sv-20"></div>
        </div>
      )
    }
  }


  renderFamily() {
    if(this.state.item !== null && HelperBus.getRoleIncl('trainer,admin,finance,cs')) {
      if(this.state.item.data.linked !== undefined) {
        return (
          <div className="mb-50">
            <h3 className="mb-10">{lang.t('header:title.familyprofiles')}</h3>
            <div className="clear"></div>
            {this.state.item.data.linked.map((item) => (
              <div className="list bare" key={item} onClick={() => this.showModal(item)}>
                {HelperClients.getImage(this.getClientLinked(item))}
                <div className="main">
                  <h4>{this.getClientLinked(item).data.name}</h4>
                  <p>{this.getClientLinked(item).data.restricted ? lang.t('clients:label.dependantprofile') : lang.t('clients:label.fullaccount')}</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
            <div className="sv-20"></div>
            <button className="btn tertiary small add width-12 mb-20" onClick={() => this.toggleModal()}>{lang.t('clients:button.addanewprofile')}</button>
            <button className="btn tertiary small width-12" onClick={() => this.showModalLink()}>{lang.t('clients:button.linkanexistingclient')}</button>
          </div>
        )
      } else {
        if(this.state.item.data.parent !== undefined && this.state.item.data.parent !== '') {
          return (
            <div className="mb-50">
              <h3 className="mb-20">{lang.t('clients:label.family2.title')}</h3>
              <div className="infobox primary mb-20">
                <p>{lang.t('clients:label.family2.text')} {this.getClientLinked(this.state.item.data.parent).data.name}</p>
              </div>
              <Link to={'/clients/'+this.state.item.data.parent+'/overview'} className="btn tertiary small width-12">{lang.t('clients:button.gotomainaccount')}</Link>
            </div>
          )
        } else {
          return (
            <div className="mb-50">
              <h3 className="mb-20">{lang.t('clients:label.family.title')}</h3>
              <div className="infobox mb-20">
                <p>{lang.t('clients:label.family.text')}</p>
              </div>
              <button className="btn tertiary small add width-12" onClick={() => this.setState({showModalFamily: true})}>{lang.t('clients:button.converttofamilyaccount')}</button>
            </div>
          )
        }
      }
    }
  }


  renderLocations() {
    if(HelperBus.getRoleIncl('admin')) {
      if(global.locations.length > 0) {
        var list = this.state.locations
        if(list.length === 0) {
          return (
            <div className="mb-50">
              <h3 className="mb-10">{lang.t('header:title.locations')}</h3>
              <div className="clear empty">
                {HelperEmpty.renderImage('locations')}
                <h4 className="mb-20">{lang.t('clients:empty.nolocationsassigned')}</h4>
                <button className="btn tertiary small width-12" onClick={() => this.setState({showModalLocations: true})}>{lang.t('common:button.manage')}</button>
              </div>
            </div>
          )
        } else {
          list.sort((a, b) => a.data.name - b.data.name)
          return (
            <div className="mb-50">
              <h3 className="mb-10">{lang.t('header:title.locations')}</h3>
              {list.map((item) => (
                <div className="list bare" key={item.id} onClick={() => this.props.history.push('/admin/locations/location/'+item.id)}>
                  <div className="icon quarternary">
                    <div className="inner location"></div>
                  </div>
                  <div className="main">
                    <h4>{item.data.name}</h4>
                    <p>{lang.t('clients:label.clicktoviewlocation')}</p>
                  </div>
                  <div className="clear"></div>
                </div>
              ))}
              <div className="clear sv-20"></div>
              <button className="btn tertiary small width-12" onClick={() => this.setState({showModalLocations: true})}>{lang.t('common:button.manage')}</button>
            </div>
          )
        }
      }
    }
  }


  renderDocButton() {
    if(this.state.active && HelperBus.getRoleIncl('trainer,admin')) {
      return (
        <button className="btn tertiary width-12 small edit" onClick={() => this.showModalFiles()}>{lang.t('common:button.manage')}</button>
      )
    }
  }


  renderDocUpload() {
    if(HelperBus.getRoleIncl('trainer,admin')) {
      return (
        <div>
          <div className="sv-20 clear"></div>
          <button className="btn tertiary add small width-12 mt-10" onClick={() => this.showModalDocument('')}>{lang.t('clients:button.uploadadocument')}</button>
        </div>
      )
    }
  }


  renderDocuments() {
    if(global.maxPlans !== 0 && HelperBus.getRoleIncl('trainer,admin,finance,cs') && this.state.item !== null) {
      var list = this.state.item.documents
      if(list.length > 0) {
        list.sort((a, b) => a.data.name - b.data.name)
        return (
          <div className="mb-50">
            <h3 className="mb-10">{lang.t('clients:label.documents')}</h3>
            {list.map((doc) => (
              <div className="list bare" key={doc.id} onClick={() => this.showModalDocument(doc.id)}>
                <div className={this.getDocClass(doc.data.ext)[0]}>
                  <div className={this.getDocClass(doc.data.ext)[1]}></div>
                </div>
                <div className="main">
                  <h4>{doc.data.name}</h4>
                  <p>{lang.t('clients:label.uploaded')} {Moment(doc.data.date, 'X').locale(lang.language).format('D MMM YYYY')} ({doc.data.ext})</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
            {this.renderDocUpload()}
          </div>
        )
      } else {
        return (
          <div className="mb-50">
            <h3 className="mb-10">{lang.t('clients:label.documents')}</h3>
            <div className="clear empty">
              {HelperEmpty.renderImage('documents')}
              <h4>{lang.t('clients:empty.nodocumentsyet')}</h4>
              {this.renderDocUpload()}
            </div>
          </div>
        )
      }
    }
  }


  renderSend() {
    if(HelperBus.getRoleIncl('trainer,admin')) {
      return (
        <div>
          <div className="clear sv-20"></div>
          <button className="btn tertiary add small width-12" onClick={() => this.showModalForms()}>{lang.t('clients:button.sendaform')}</button>
        </div>
      )
    }
  }


  renderForms() {
    if(((global.maxPlans !== 0 && HelperBus.getRoleIncl('')) || HelperBus.getRoleIncl('assistant,assistant2,trainer,admin,finance,cs')) && this.state.item !== null) {
      var list = this.state.item.forms
      var pre = false
      var ids = []
      for(var fm of list) {
        ids.push(fm.id)
        if(fm.data.pre) {
          pre = true
        }
      }
      if(!pre) {
        for(var fm2 of global.forms) {
          if(fm2.data.pre && ids.indexOf(fm2.id) === -1) {
            list.push(fm2)
          }
        }
      }
      if(list.length > 0) {
        list.sort((a, b) => a.data.name - b.data.name)
        return (
          <div className="mb-50">
            <h3 className="mb-10">{lang.t('header:title.forms')}</h3>
            <ul className="linklist clear">
            {list.map((form) => (
              <div className="list bare" key={form.id} onClick={() => this.gotoForm(form)}>
                <div className={'icon '+this.getFormInfo(form, 'class')}>
                  <div className="inner form"></div>
                </div>
                <div className="main">
                  <h4>{form.data.name}</h4>
                  <p>{this.getFormInfo(form, 'label')}</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
            </ul>
            {this.renderSend()}
            <div className="clear"></div>
          </div>
        )
      } else {
        if(HelperBus.getRoleIncl('trainer,admin')) {
          return (
            <div className="mb-50">
              <h3 className="lft" style={{marginBottom: 20}}>{lang.t('header:title.forms')}</h3>
              <div className="clear empty mb-10">
                {HelperEmpty.renderImage('forms')}
                <h4>{lang.t('clients:empty.noformsyet')}</h4>
                <div className="clear sv-20"></div>
                <button className="btn tertiary add small width-12" onClick={() => this.showModalForms()}>Send a form</button>
              </div>
            </div>
          )
        }
      }
    }
  }


  renderNotesMain()  {
    if(HelperBus.getRoleIncl('trainer,admin')) {
      return (
        <div className="col-5 withsidebar">
          <h3 className="lft mb-20">{lang.t('clients:label.notes')}</h3>
          {this.renderHistoryLink()}
          <div className="clear"></div>
          {this.renderNoteForm()}
          {this.renderNotes()}
          <div className="sv-50 clear"></div>
        </div>
      )
    }
  }


  renderInactiveMessage() {
    if(!this.state.active) {
      return (
        <div className="infobox primary">
          {lang.t('clients:label.clientinactive')}
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          <div className="mt-20">
            {this.renderInactiveMessage()}
            {this.renderInfo()}
            {this.renderNotesMain()}
            <div className="col-3">
              {this.renderForms()}
              {this.renderLocations()}
              {this.renderDocuments()}
              {this.renderFamily()}
            </div>
            <div className="sv-80 clear"></div>
          </div>
        </div>
        <Header client={this.state.item} link={global.currentPage} type={this.state.active ? '' : 'inactive'} active='details'/>
        <Navigation active='clients' />
        {this.renderModalClient()}
        {this.renderModalDocument()}
        {this.renderModalGoal()}
        {this.renderModalHistory()}
        {this.renderModalForms()}
        {this.renderModalReset()}
        {this.renderModalMerge()}
        {this.renderModal()}
        {this.renderModalLink()}
        <ModalMessageDouble type='family' show={this.state.showModalFamily} var={this.state.item === null ? '' : this.state.item.data.name} onHide={() => this.hideModals()} clickMainButton={() => this.toggleConvert('new')} clickSecondaryButton={() => this.toggleConvert('link')}/>
        <ModalMessageSimple type={this.state.active ? 'makeinactive' : 'makeactive'} show={this.state.showModalActive} onHide={() => this.hideModals()} clickMainButton={() => this.toggleActive()}/>
        <ModalMessageSimple type='clientlimit' show={this.state.showModalMessage} onHide={() => this.hideModals()} clickMainButton={() => this.props.history.push(ROUTES.SUBSCRIPTION)}/>
        <ModalMessageSimple type='confirmlinked' show={this.state.showModalConfirm} var={this.getUnlinkName()} onHide={() => this.hideModals()} clickMainButton={() => this.unlinkClient()}/>
        <ModalAvatar show={this.state.showModalAvatar} onHide={() => this.hideModals()} clickElement={(event) => this.setAvatar(event)}/>
        <ModalItems show={this.state.showModalLocations} type={'locations'} title={lang.t('modals:items.title.selectlocations')} button={lang.t('modals:clients.button.confirmselection')} items={global.locations} selected={this.state.locations} multiple={true} empty={lang.t('modals:items.empty.nolocations')} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectLocations(event)}/>
      </div>
      
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['clients','common','header','modals'])(withRouter(withAuthorization(condition)(ClientsDetailsPage)));