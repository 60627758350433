import React, { Component } from 'react';
import Moment from 'moment';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class ModalTrial extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      date: props.date ?? Moment(),
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      date: props.date ?? Moment(),
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  clickMainButton() {
    this.hide()
    this.props.clickMainButton()
  }


  clickSecondaryButton() {
    this.hide()
    this.props.clickSecondaryButton()
  }


  clickTertiaryButton() {
    this.hide()
    this.props.clickTertiaryButton()
  }


  onChange = event => {
    this.props.onChange(Moment(event.target.value, 'YYYY-MM-DD').format('X'))
  };


  render() {
    if(this.state.show) {
      if(this.state.id === 'list') {
        return (
          <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{lang.t('modals:trial.title.manage')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
              <p className="clear mb-20">{lang.t('modals:trial.info3')}</p>
              <label>{lang.t('modals:trial.updateenddate')}</label>
              <input type="date" value={Moment(this.state.date, 'X').format('YYYY-MM-DD')} onChange={this.onChange} min={Moment().add(1, 'days').format('YYYY-MM-DD')}/>
              <div className="clear sv-30"></div>
              <button className="btn primary" onClick={() => this.clickMainButton()}>{lang.t('modals:trial.button.extend')}</button>
              <div className="sv-20"></div>
              <button className="btn tertiary width-12" onClick={() => this.clickSecondaryButton()}>{lang.t('modals:trial.button.convert')}</button>
              <div className="sv-20"></div>
              <button className="btn tertiary small width-12" onClick={() => this.clickTertiaryButton()}>{lang.t('modals:trial.button.delete')}</button>
            </div>
          </div>
        )
      } else if(this.state.id === 'new') {
        return (
          <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{lang.t('modals:trial.title.setup')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
              <p className="clear mb-20">{lang.t('modals:trial.info2')}</p>
              <label>{lang.t('modals:trial.automaticallyends')}</label>
              <input type="date" value={Moment(this.state.date, 'X').format('YYYY-MM-DD')} onChange={this.onChange} min={Moment().add(1, 'days').format('YYYY-MM-DD')}/>
              <div className="clear sv-30"></div>
              <button className="btn primary" onClick={() => this.clickMainButton()}>{lang.t('modals:trial.button.setup')}</button>
            </div>
          </div>
        )
      } else if(this.state.id === 'update') {
        return (
          <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{lang.t('modals:trial.title')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
              <p className="clear mb-20">{lang.t('modals:trial.info1')}</p>
              <label>{lang.t('modals:trial.automaticallyends')}</label>
              <input type="date" value={Moment(this.state.date, 'X').format('YYYY-MM-DD')} onChange={this.onChange} min={Moment().add(1, 'days').format('YYYY-MM-DD')}/>
              <div className="clear sv-30"></div>
              <button className="btn primary" onClick={() => this.clickMainButton()}>{lang.t('modals:trial.button.update')}</button>
              <div className="sv-20"></div>
              <button className="btn tertiary width-12" onClick={() => this.clickSecondaryButton()}>{lang.t('modals:trial.button')}</button>
            </div>
          </div>
        )
      }
    } else {
      return null
    }
  }
}


export default withTranslation(['modals','common'])(ModalTrial);